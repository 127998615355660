import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../components/Main.vue'
import Login from '../components/Login.vue'
import InvalidDomain from '../components/InvalidDomain.vue'

Vue.use(VueRouter)

const routes = [
{
	path: '/',
	name: 'Main',
	component: Main
},
{
	path: '/Login',
	name: 'Login',
	component: Login
},
{
	path: '/InvalidDomain',
	name: 'InvalidDomain',
	component: InvalidDomain
}
]

const router = new VueRouter({
	routes
})

export default router
