<!-- eslint-disable no-template-key -->
<template>
	<div class="tree-view">
		
		<vue-context-menu id="medialibrary-context-menu" ref="ctxMenuMediaLibrary">
			<li @click="$emit('menuItemSelected', item)" class="ctx-item" v-for="item in contextMenuItems" :key="item.code">{{ item.label }}</li>
		</vue-context-menu>
		
		<div v-for="(node, index) in tree" :key="node.uuid">
			<tree-node :selectedNode="selectedNode" :treetype="treetype" :imageCache="imageCache" :index="index" :node="node"></tree-node>
		</div>
	</div>
</template>

<script>
import EventBus from '../EventBus.js'
import TreeNode from './TreeNode.vue'
import VueContextMenu from 'vue-context-menu'

export default {
	name: 'TreeView',
	data: () => ({
		contextMenuItems: [{ code: 'DELETE', label: 'Ordner löschen' }, { code: 'RENAME', label: 'Ordner umbenennen' }, { code: 'NEW_SUBFOLDER', label: 'Neuer Unterordner' }],
	}),
	props: [
		'tree',
		'imageCache',
		'selectedNode',
		'treetype'
	],
	components: {
		'tree-node' : TreeNode,
		VueContextMenu
	},
	created () {
		EventBus.$on('openNodeContextMenu', (node) => {
			this.openContextMenu(node)
		})
	},
	methods: {
		openContextMenu(node) {
			console.log('Entering openContextMenu for node ' + node.name)
			this.$refs.ctxMenuMediaLibrary.open()
		},
		/*selectMenuItem (menuItem) {
			console.log('Entering event menuItemSelected for menuItem ' + JSON.stringify(menuItem))
			if (menuItem.code === 'DELETE') {
				// this.confirmDelete(this.selectedNode,'Ordner löschen','Soll der Ordner "' + this.selectedNode.name + '" wirklich gelöscht werden? Der Vorgang kann nicht rückgängig gemacht werden und alle Inhalte werden ebenfalls unwiederruflich gelöscht!!')
			} else if (menuItem.code === 'NEW_SUBFOLDER') {
				// this.addSubfolder()
			} else if (menuItem.code === 'RENAME') {
				// this.$bvModal.show('renameFolderModal')
			} 
		}*/
	}
}
</script>

<style>
</style>
