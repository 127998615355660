<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
<!-- Bootstrap Icon List: https://icons.getbootstrap.com/ -->
<div>
	<top-level-navigation :version="editorConfig.version" :connected="connected" :connectionStatus="connectionStatus" :project="project" v-if="mode !== 'preview'" :mode="mode"></top-level-navigation>
	<!-- Settings Explorer -->
	<b-sidebar v-if="project && project.initialized" width="430px" v-model="showSettingsExplorer" id="SettingsExplorer" :title="'Einstellungen (' + (settingsObj.id + ' / ' + settingsObj.identifier) + ')'" :right="settingsExplorerAlign == 'right'" shadow @hidden="hideSettings">
		<b-card no-body>
			<b-tabs v-model="settingsTabIndex" card>
				<b-tab disabled :set="generalConfigObj = showSettingsExplorer ? getConfigObj('general',settingsObj) : undefined" :title="generalConfigObj && generalConfigObj.displayText ? generalConfigObj.displayText : 'Allgemein'">
					<b-card-text>
						<template v-if="generalConfigObj && generalConfigObj.properties">
							<b-form-group class="font-weight-bold" v-for="(value, key) in generalConfigObj.properties" :key="key"
								:label-cols="generalConfigObj.colsize"
								label-size="sm"
								:label="generalConfigObj.properties[key].displayText"
								:label-for="key">
								<b-input-group v-if="generalConfigObj.properties[key].type === 'text' || generalConfigObj.properties[key].type === 'number' || generalConfigObj.properties[key].type === 'date'">
									<template v-if="generalConfigObj.properties[key].notifyOnChange">
										<template v-if="generalConfigObj.properties[key].useSpinner">
											<b-form-spinbutton size="sm" :id="key" v-model="settingsObj[key]" :min="generalConfigObj.properties[key].minValue" :max="typeof value.maxValue === 'string' ? (value.maxValue.startsWith('fnc') ? resolveValue(settingsObj, value.maxValue) : value.maxValue) : value.maxValue"></b-form-spinbutton>
										</template>
										<template v-else>
											<b-form-input :plaintext="generalConfigObj.properties[key].readonly" :type="generalConfigObj.properties[key].type" @input="function(inputValue) { inputChanged(inputValue, settingsObj, key) }" size="sm" :id="key" v-model="settingsObj[key]" trim></b-form-input>
										</template>
									</template>
									<template v-else>
										<template v-if="generalConfigObj.properties[key].useSpinner">
											<b-form-spinbutton size="sm" :id="key" v-model="settingsObj[key]" :min="generalConfigObj.properties[key].minValue" :max="typeof value.maxValue === 'string' ? (value.maxValue.startsWith('fnc') ? resolveValue(settingsObj, value.maxValue) : value.maxValue) : value.maxValue"></b-form-spinbutton>
										</template>
										<template v-else>
											<b-form-input :plaintext="generalConfigObj.properties[key].readonly" :type="generalConfigObj.properties[key].type" size="sm" :id="key" v-model="settingsObj[key]" trim></b-form-input>
										</template>
									</template>
								</b-input-group>
								<b-form-checkbox v-if="generalConfigObj.properties[key].type === 'boolean'"
									switch
									size="sm"
									:id="key"
									v-model="settingsObj[key]"
									:name="key"
									:value="true"
									:unchecked-value="false">
									{{ settingsObj[key] ? 'Ja' : 'Nein' }}
								</b-form-checkbox>
								<b-form-select @change="function(inputValue) { inputChanged(inputValue, settingsObj, key) }" size="sm" v-if="generalConfigObj.properties[key].type === 'list'" :value="settingsObj[key]" class="mb-3">
									<b-form-select-option value="null">{{ generalConfigObj.properties[key].selectListText || generalConfigObj.properties[key].displayText + ' auswählen' }}</b-form-select-option>
										<b-form-select-option :disabled="settingsObj.type ==='page' && settingsObj.modules.length > 0 && allowedValue.key === 'overviewpage'" v-for="allowedValue in getAllowedValues(generalConfigObj.properties[key].allowedValues)" :key="allowedValue.key" :value="allowedValue.key">{{ allowedValue.value }}</b-form-select-option>
								</b-form-select>
								<b-input-group v-if="generalConfigObj.properties[key].type === 'icon'">
									<template #append>
										<b-icon class="h4" :icon="settingsObj.icon" aria-hidden="true"></b-icon>
									</template>
									<b-form-input plaintext size="sm" id="icon" v-model="settingsObj.icon" trim></b-form-input>
								</b-input-group>
							</b-form-group>
						</template>
						<!-- Specific properties -->
						<template v-if="generalConfigObj && generalConfigObj.specificProperties && generalConfigObj.specificProperties.properties">
							<div v-for="(value, key) in generalConfigObj.specificProperties.properties[settingsObj[generalConfigObj.specificProperties.specifier]]" :key="key">
								<!-- If we already have this property defined in the none specific configuration we will not add an additional project group to set/change its value but the specific part can be used to overwrite the none specific configured default value -->
								<b-form-group v-if="typeof generalConfigObj.properties[key] === 'undefined'" class="font-weight-bold" 
									:label-cols="generalConfigObj.specificProperties.colsize"
									label-size="sm"	:label="value.displayText"
									:label-for="key">
									<b-input-group v-if="value.type === 'text' || value.type === 'number' || value.type === 'date'">
										<template v-if="value.notifyOnChange">
											<template v-if="value.useSpinner">
												<b-form-spinbutton size="sm" :id="key" v-model="settingsObj[key]" :min="value.minValue" :max="typeof value.maxValue === 'string' ? (value.maxValue.startsWith('fnc') ? resolveValue(settingsObj, value.maxValue) : value.maxValue) : value.maxValue"></b-form-spinbutton>
											</template>
											<template v-else>
												<b-form-input :plaintext="value.readonly" :type="value.type" @input="function(inputValue) { inputChanged(inputValue, settingsObj, key) }" size="sm" :id="key" v-model="settingsObj[key]" trim></b-form-input>
											</template>
										</template>
										<template v-else>
											<template v-if="value.useSpinner">
												<b-form-spinbutton size="sm" :id="key" v-model="settingsObj[key]" :min="value.minValue" :max="typeof value.maxValue === 'string' ? (value.maxValue.startsWith('fnc') ? resolveValue(settingsObj, value.maxValue) : value.maxValue) : value.maxValue"></b-form-spinbutton>
											</template>
											<template v-else>
												<b-form-input :plaintext="value.readonly" :type="value.type" size="sm" :id="key" v-model="settingsObj[key]" trim></b-form-input>
											</template>
										</template>
									</b-input-group>
									<b-form-checkbox v-if="value.type === 'boolean'"
										switch
										size="sm"
										:id="key"
										v-model="settingsObj[key]"
										:name="key"
										:value="true"
										:unchecked-value="false">
										{{ settingsObj[key] ? 'Ja' : 'Nein' }}
									</b-form-checkbox>
									<b-form-select size="sm" v-if="value.type === 'list'" v-model="settingsObj[key]" class="mb-3">
										<b-form-select-option value="null">{{ value.selectListText || value.displayText + ' auswählen'}}</b-form-select-option>
											<b-form-select-option v-for="allowedValue in getAllowedValues(value.allowedValues)" :key="allowedValue.key" :value="allowedValue.key">{{ allowedValue.value }}</b-form-select-option>
									</b-form-select>
									<b-input-group v-if="value.type === 'icon'">
										<template #append>
											<b-icon class="h4" :icon="settingsObj.icon" aria-hidden="true"></b-icon>
										</template>
										<b-form-input plaintext size="sm" id="icon" v-model="settingsObj.icon" trim></b-form-input>
									</b-input-group>
								</b-form-group>
							</div>
						</template>
						<!-- End of specific properties -->
					</b-card-text>
				</b-tab>
				<!-- Display Settings -->
				<b-tab :set="configObj = showSettingsExplorer ? getConfigObj(name,settingsObj) : undefined" v-for="(settings, name) in settingsObj.settings" :key="name" :title="configObj && configObj.displayText ? configObj.displayText : 'Anzeige'">
					<b-card-text>
						<b-form-group v-if="configObj && configObj.showId" class="font-weight-bold"
							:label-cols="configObj.colsize"
							label-size="sm"
							label="ID"
							label-for="id">
							<b-input-group>
								<b-form-input :plaintext="true" size="sm" id="id" v-model="settingsObj.id" trim></b-form-input>
							</b-input-group>
						</b-form-group>
						<b-form-group v-if="configObj && configObj.showIdentifier" class="font-weight-bold"
							:label-cols="configObj.colsize"
							label-size="sm"
							label="Identifier"
							label-for="identifier">
							<b-input-group>
								<b-form-input :plaintext="true" size="sm" id="identifier" v-model="settingsObj.identifier" trim></b-form-input>
							</b-input-group>
						</b-form-group>
						<b-form-group v-for="(value, key) in settings" :key="key" class="font-weight-bold"
							:label-cols="configObj.colsize"
							label-size="sm"
							:label="configObj.settings[key].displayText"
							:label-for="key">
							<b-input-group v-if="configObj.settings[key].type === 'icon'">
								<b-form-input type="text" @input="function(inputValue) { inputChanged(inputValue, settingsObj, key) }" size="sm" :id="key" v-model="settingsObj.settings[name][key]" trim></b-form-input>
								<b-input-group-append>
									<!--<b-button size="sm" variant="outline-secondary">Auswahl</b-button>-->
										<b-button :id="`select-icon-${key}`" variant="secondary" size="sm">
											<font-awesome-icon :icon="settingsObj.settings[name][key]" aria-hidden="true" />
										</b-button>
										<b-popover
											bg-variant="dark"
											:target="`select-icon-${key}`"
											placement="right"
											title="Icon-Auswahl"
											triggers="click">
											<div style="max-width: 240px" class="mr-4">
												<b-row class="justify-content-center">
													<b-col cols="2" v-for="(icon,index) in settingsConfig.icons" :key="index" >
														<b-button variant="secondary" size="sm" class="ml-1 mb-1" @click.prevent="function() { $root.$emit('bv::hide::popover'); settingsObj.settings[name][key] = icon }">
															<font-awesome-icon :icon="icon" aria-hidden="true" />
														</b-button>
													</b-col>
												</b-row>
											</div>
										</b-popover>
									
								</b-input-group-append>
							</b-input-group>
							<b-input-group v-if="configObj.settings[key].type === 'text' || configObj.settings[key].type === 'number'">
								<b-form-input :plaintext="configObj.settings[key].readonly" :type="configObj.settings[key].type" @input="function(inputValue) { inputChanged(inputValue, settingsObj, key) }" size="sm" :id="key" v-model="settingsObj.settings[name][key]" trim></b-form-input>
							</b-input-group>
							<b-form-checkbox v-if="configObj.settings[key].type === 'boolean'"
								switch
								size="sm"
								:id="key"
								:disabled="configObj.settings[key].readonly"
								v-model="settingsObj.settings[name][key]"
								:name="key"
								:value="true"
								:unchecked-value="false">
								{{ settingsObj.settings[name][key] ? 'Ja' : 'Nein' }}
							</b-form-checkbox>
							<b-form-select :disabled="configObj && configObj.settings[key] && configObj.settings[key].readonly == true ? true : false" size="sm" v-if="configObj.settings[key].type === 'list'" v-model="settingsObj.settings[name][key]" class="mb-3">
								<b-form-select-option value="null">{{ configObj.settings[key].selectListText || configObj.settings[key].displayText + ' auswählen' }}</b-form-select-option>
								<template v-if="key === 'element'">
									<b-form-select-option v-for="allowedValue in getDatasetElements(settingsObj)" :key="allowedValue.uuid" :value="allowedValue.uuid">{{ allowedValue.id }}</b-form-select-option>
								</template>
								<template v-else>
									<b-form-select-option v-for="allowedValue in getAllowedValues(configObj.settings[key].allowedValues)" :key="allowedValue.key" :value="allowedValue.key">{{ allowedValue.value }}</b-form-select-option>
								</template>
							</b-form-select>
						</b-form-group>
					</b-card-text>
				</b-tab>
				<!-- End of Display Settings -->
				<b-tab v-if="enableAttributes" :set="attributesConfigObj = showSettingsExplorer ? getConfigObj('attributes',settingsObj) : undefined" :title="attributesConfigObj && attributesConfigObj.displayText ? attributesConfigObj.displayText : 'Attribute'">
					<b-table
						striped
						ref="refObjAttributes"
						selectable
						:items="settingsObj.attributes"
						:fields="[{ key: 'id', label: 'ID' },{ key: 'value', label: 'Wert' },{ key: 'actions', label: 'Aktion' }]"
						caption-top>
						<template #table-caption>
							<b-button v-b-tooltip.hover title="Attribut hinzufügen" class="mr-2" size="sm" variant="success" @click="addAttribute(settingsObj)"><b-icon style="height: 14px;" icon="plus-square" aria-hidden="true"></b-icon> Attribut hinzufügen</b-button>
						</template>
						<template #cell(id)="data">
							<template v-if="refAttributeEditItem !== null && refAttributeEditItem.rowindex == data.index">
							  <b-form-input v-model="settingsObj.attributes[data.index].id" placeholder="ID"></b-form-input>
							</template>
							<template v-else>
								{{ data.value }}
							</template>
						</template>
						<template #cell(value)="data">
							<template v-if="refAttributeEditItem !== null && refAttributeEditItem.rowindex == data.index">
							  <b-form-input v-model="settingsObj.attributes[data.index].value" placeholder="Wert"></b-form-input>
							</template>
							<template v-else>
							 {{ data.value }}
							</template>
						</template>
						<template #cell(actions)="row">
							<b-button v-if="refAttributeEditItem === null" v-b-tooltip.hover title="Bearbeiten" variant="novolytics" size="sm" @click="editAttribute(row.item, row.index, $event.target)" class="mr-1"><b-icon style="height: 14px;" icon="pencil" aria-hidden="true"></b-icon></b-button>
							<b-button v-if="refAttributeEditItem !== null && refAttributeEditItem.rowindex == row.index" v-b-tooltip.hover title="Bearbeitung beenden" variant="success" size="sm" @click="refAttributeEditItem = null" class="mr-1"><b-icon style="height: 14px;" icon="check-square" aria-hidden="true"></b-icon></b-button>
							<b-button v-if="refAttributeEditItem === null" v-b-tooltip.hover title="Attribut löschen" variant="danger" size="sm" @click="confirmDelete(row.item, settingsObj)"><b-icon style="height: 14px;" icon="trash-fill" aria-hidden="true"></b-icon></b-button>
						</template>
					</b-table>
				</b-tab>
			</b-tabs>
			<b-card-footer align="right" class="d-none d-lg-block d-xl-block">
				<b-button :variant="settingsExplorerAlign === 'left' ? 'novolytics' : 'light'" size="sm" class="mr-2" @click.prevent="alignSettingsExplorer('left')" v-b-tooltip.hover title="Einstellungen links ausrichten">
					<b-icon icon="layout-sidebar" aria-hidden="true"></b-icon>
				</b-button>
				<b-button :variant="settingsExplorerAlign === 'right' ? 'novolytics' : 'light'" size="sm" class="mr-2" @click.prevent="alignSettingsExplorer('right')" v-b-tooltip.hover title="Einstellungen rechts ausrichten">
					<b-icon icon="layout-sidebar-reverse" aria-hidden="true"></b-icon>
				</b-button>
			</b-card-footer>
		</b-card>
	</b-sidebar>
	<!-- End of Settings Explorer -->

<b-container fluid>
  <b-row v-if="embeddings.length > 0" class="mt-2">
    <b-col>
      <view-embeddings :embeddings="embeddings" :type="'top'"></view-embeddings>
    </b-col>
  </b-row>
  <b-row class="mt-1">
    <b-col v-if="showModuleExplorer || mode === 'media'" cols="3" align="left">
      <template v-if="showModuleExplorer">
        <h4>Modul-Explorer</h4>
        <i>{{ project.id }} (<span v-if="unsavedChanges" class="text-danger font-weight-bold">{{ project && project.name == null ? 'noch nicht gespeichert' : project.name }}</span><span v-else class="text-success font-weight-bold">{{ project.name }}</span>)</i>
      </template>
      <template v-else-if="mode === 'media'">
        <h4>Media-Explorer</h4>
      </template>
    </b-col>
    <b-col :cols="colSize" align="right">
      <quick-actions :showHelpTexts="showHelpTexts" :showRefLines="showRefLines" :showModuleExplorer="showModuleExplorer" :showObjectIDs="showObjectIDs" :mode="mode" :connected="connected"></quick-actions>
    </b-col>
  </b-row>
  <template v-if="mode === 'media'">
	<media-library class="mt-2" :mediaLibrary="mediaLibrary" :imageCache="imageCache" :mediaExplorerConfig="editorConfig.mediaExplorerConfig"></media-library>
  </template>
  <template v-if="(mode == 'editor' || mode == 'preview' || mode == 'apiexplorer') && project && project.initialized">
	<b-row>
		<b-col v-if="showModuleExplorer" cols="3">
			<module-explorer v-if="showModuleExplorer" :moduleexplorerConfig="editorConfig.moduleexplorerConfig"></module-explorer>
		</b-col>
		<b-col :cols="colSize">
			<!-- Page Building starts here -->
			<div :style="'background-color: ' + project.settings.layout.colors.bgColor + ' !important; color: ' + project.settings.layout.colors.secondaryColor + ' !important; --primaryColor: ' + project.settings.layout.colors.primaryColor + ';'">
			<!--
			<template v-if="mode == 'preview'>
				<project-preview :project="project" :currentPage="currentPage" :currentLayout="currentLayout" :pageIndex="pageIndex"></project-preview>
			</template>
			-->
			<template v-if="mode == 'apiexplorer'">
				<api-explorer></api-explorer>
			</template>
			<template v-else>
				<b-card no-body>
					<b-tabs card v-model="pageIndex" @input="pageSelected">
					<b-tab :disabled="page.id == 'confirmation' && !project.enableConfirmationPage" @click.prevent="clicked(page,'title').then((uuid) => { })" v-for="(page) in currentLayout.pages" :key="page.uuid" :title="page.title" :active="page.uuid == currentPage.uuid" v-b-hover="function(hovered) { handleHover(hovered, page) }">
					  <b-card-text>
						<drop @drop="function(data) { handleDropOnPage(data, page) }" class="drop" :class="{ 'over' : (over === page.uuid && dragObj === 'module') }" @dragover="handleDragOver(page, ...arguments)" @dragleave="over = null" @dragend="over = null">
						  <b-card type="page" class="">
							<!--<drop @drop="function(data) { handleDropOnModule(data, module) }" v-for="module in page.modules" :key="module.uuid" class="drop" :class="{ 'over' : (over === module.uuid && (dragObj === 'module')) }" @dragover="handleDragOver(module, ...arguments)" @dragleave="over = null" @dragend="over = null">
							  <drag @dragstart="handleDragstart" class="drag" :transfer-data="{source: page, obj: module}"> -->
							  <b-card no-body type="module" class="mb-2 p-2" v-for="module in page.modules" :key="module.uuid" v-b-hover="function(hovered) { if(enableHovering) { handleHover(hovered, module) } }">
								<div class="text-sm-right mr-1">
									<!--
								  <b-badge @click.prevent="clicked(module,'id').then((uuid) => { })" v-if="showObjectIDs" :variant="module.settings.display.moduleType === 'header' || module.settings.display.moduleType === 'footer' ? 'danger' : 'secondary'" class="objectId mb-3 float-left">
									<template v-if="editing && editing.property === 'id' && editing.uuid === module.uuid">
									  <b-input-group size="sm">
										<b-form-input size="sm" v-model="module.id" placeholder="ID des Moduls"></b-form-input>
										<b-input-group-append>
										  <b-button :disabled="module.id == null || module.id === ''" size="sm" variant="novolytics" v-b-tooltip.hover title="Bearbeitung beenden" @click.prevent="editing = null"><b-icon icon="check-square" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
										</b-input-group-append>
										<b-input-group-append>
										  <b-button size="sm" variant="danger" v-b-tooltip.hover title="Wert löschen" @click.prevent="module.id = null"><b-icon icon="backspace-fill" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
										</b-input-group-append>
									  </b-input-group>
									</template>
									<template v-else>
									  Modul {{ module.id }}
									</template>
								  </b-badge>-->
								  
								  <template v-if="editing == null && (editMode || hovering == module.uuid)">
									<b-button v-if="module.supportsItems" variant="light" class="mr-1" size="sm" @click="editAction(page, module, null, null, 'addItem')" v-b-tooltip.hover :title="'Element hinzufügen'">
										<b-icon icon="node-plus" aria-hidden="true" style="height: 14px;"></b-icon>
									</b-button>  
									  <b-button v-if="module.enableTextEditor" variant="light" class="mr-1" size="sm" @click="module.editor.editText = !module.editor.editText" v-b-tooltip.hover :title="module.editor.editText ? 'Textbearbeitung beenden' : 'Text bearbeiten'">
										<b-icon :icon="module.editor.editText ? 'pencil-fill' : 'pencil'" aria-hidden="true" style="height: 14px;"></b-icon>
									  </b-button>
									  <b-button size="sm" variant="light" v-b-tooltip.hover title="Modul löschen" @click.prevent="confirmDelete(module)"><b-icon icon="trash-fill" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
									  <b-button v-if="canMoveLeftOrUp(page, module, 'modules')" size="sm" variant="light" v-b-tooltip.hover title="Modul nach oben verschieben" @click="editAction(page, module, null, null, 'moveModuleUp')" ><b-icon icon="box-arrow-up" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
									  <b-button v-if="canMoveRightOrDown(page, module, 'modules')" size="sm" variant="light" v-b-tooltip.hover title="Modul nach unten verschieben" @click="editAction(page, module, null, null, 'moveModuleDown')"><b-icon icon="box-arrow-down" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
									  <b-button size="sm" variant="light" v-b-tooltip.hover title="Einstellungen (Modul)" @click="openObjectSettings(module)"><b-icon icon="gear-fill" aria-hidden="true"  style="height: 14px;"></b-icon></b-button>
								  </template>
								</div>
								
								<template v-if="module.identifier == 'header_module'">
									<nav class="navbar" :style="'background-color: ' + module.settings.display.bgColor + ';'">
										<div class="container-fluid" :class="{ 'justify-content-center' : module.settings.display.imageOrientation == 'center', 'justify-content-left' : module.settings.display.imageOrientation == 'left', 'justify-content-end' : module.settings.display.imageOrientation == 'right' }">
											<img class="img-fluid" :src="module.settings.display.imageSource" alt="" :width="module.settings.display.imageWidth"/>
										</div>
									</nav>
								</template>
								
								<template v-if="module.identifier == 'card_module'">
									<div class="container">
										<template v-if="module.settings.display.showHeading"><caption :is="module.settings.display.headingSize" :class="{ 'text-uppercase': module.settings.display.upperCase === true }">{{ module.settings.display.heading }}</caption></template>
										<div class="card mt-3 mb-3" :class="{'shadow': module.settings.display.showShadow, 'borderNone' : !module.settings.display.showBorder }" :style="'background-color: ' + module.settings.display.bgColor + ' !important;'">
											<div class="card-body">
												<p class="card-text fs-5 lh-sm">
													<html-editor :editable="module.editor.editText === true" :value="module.settings.display.text" :targetObj="module"/>
												</p>
											</div>
										</div>
										<p v-if="module.settings.display.showActionButton === true" align="center">
											<button type="button" class="btn btn-custom btn-lg">{{module.settings.display.actionButtonText}}</button>
										</p>
										<p v-if="module.settings.display.isLimited === true" align="center" class="text-muted">
											{{module.settings.display.limitedText}}
										</p>
									</div>
								</template>
								
								<template v-if="module.identifier == 'cardimage_module'">
									<div class="container">
										<!-- <h2 class="text-uppercase">Warum Novolytics?</h2> -->
										<template v-if="module.settings.display.showHeading"><caption :is="module.settings.display.headingSize" :class="{ 'text-uppercase': module.settings.display.upperCase === true }">{{ module.settings.display.heading }}</caption></template>
										<div class="card mt-3 mb-3" :class="{'shadow': module.settings.display.showShadow, 'borderNone' : !module.settings.display.showBorder }" :style="'background-color: ' + module.settings.display.bgColor + ' !important;'">
											<div class="row g-0">
												<div v-if="module.settings.display.imageOrientation == 'left'" class="col-md-6">
													<img :src="module.settings.display.imageSource" class="img-fluid" alt="...">
												</div>
												<div class="col-md-6">
													<div class="card-body">
														<p class="card-text fs-5 lh-sm">
															<html-editor :editable="module.editor.editText === true" :value="module.settings.display.text" :targetObj="module"/>
														</p>
													</div>
												</div>
												<div v-if="module.settings.display.imageOrientation == 'right'" class="col-md-6">
													<img :src="module.settings.display.imageSource" class="img-fluid" alt="...">
												</div>
											</div>
										</div>
										<p v-if="module.settings.display.showActionButton === true" align="center">
											<button type="button" class="btn btn-custom btn-lg">{{module.settings.display.actionButtonText}}</button>
										</p>
										<p v-if="module.settings.display.isLimited === true" align="center" class="text-muted">
											{{module.settings.display.limitedText}}
										</p>
									</div>
								</template>
								
								<template v-if="module.identifier == 'banner_module'">
									<div class="carousel mt-5 mb-5 bg-image" :style="'--bg-image: url(\'' + module.settings.display.imageSource + '\');'" data-bs-touch="false" data-bs-interval="false" data-bs-ride="false">
										<div class="carousel-inner h-100">
											<div class="carousel-item h-100 active">
												<div class="row align-items-center h-100">
													<div class="col-md-12">
														<div align="center">
															<template v-if="module.settings.display.showHeading"><caption :is="module.settings.display.headingSize" :class="{ 'text-uppercase': module.settings.display.upperCase === true }">{{ module.settings.display.heading }}</caption></template>
															<p  v-if="module.settings.display.showText" class="lh-sm" :class="module.settings.display.textSize">
																{{module.settings.display.text}}
															</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</template>
								
								<template v-if="module.identifier == 'question_module'">
									<div id="carouselQuestionnaire" class="carousel mt-5 mb-5 bg-image" :style="'--bg-image: url(\'' + module.settings.display.imageSource + '\');'" data-bs-touch="false" data-bs-interval="false" data-bs-ride="false">
										<div class="carousel-inner h-100">
											<div v-for="(item,index) in module.items" :key="item.uuid" class="carousel-item h-100" :class="{ 'active': index == module.transient.currentItemIndex }">
												<template v-if="editMode">
													{{(index+1)}}/{{module.items.length}} ({{getDisplayType(item.settings.display.displayType)}} / {{item.settings.display.displayType}}), Antworten: {{answers}}
												</template>
												<div class="row align-items-center h-100">
													<div class="col-md-4">
													</div>
													<div class="col-md-4">
														<div align="center">
															<template v-if="item.settings.display.showHeading"><caption :is="item.settings.display.headingSize" :class="{ 'text-uppercase': item.settings.display.upperCase === true }">{{ item.settings.display.heading }}</caption></template>
															<p v-if="item.settings.display.showText" class="lh-sm" :class="item.settings.display.textSize">
																{{item.settings.display.text}}
															</p>
															<div v-if="item.settings.display.displayType == 'freetext'" class="mb-3">
																<b-form-textarea rows="6" id="htmlcode" :placeholder="item.settings.display.placeholder" type="text" size="sm" v-model="item.settings.display.buttonanswer"></b-form-textarea>
															</div>
															<div v-if="item.settings.display.displayType == 'budget'">
																<veeno  
																	tooltips
																	:step = parseInt(item.settings.display.valueStep)
																	v-model="item.settings.display.buttonanswer"
																	:handles="[item.settings.display.minValue]" 
																	:range = "{ 
																		'min': item.settings.display.minValue, 
																		'max': item.settings.display.maxValue 
																	}"
																/>
																
																<div class="range_op">
																	<span class="first">{{item.settings.display.minValue}} {{item.settings.display.unitSymbol}}</span>
																	<span class="last">{{item.settings.display.maxValue}} {{item.settings.display.unitSymbol}}</span>
																</div>
															</div>
															
															<div v-if="item.settings.display.displayType == 'check'" class="pro_boxes">
																{{waitForCheck(module,item,6000,index)}}
																<div class="pro1">
																	<div class="probox-wrapper" data-anim="base1 wrapper1">
																	  <div class="probox-circle" data-anim="base1 left1"></div>
																	  <div class="probox-circle" data-anim="base1 right1"></div>
																	</div>
																	<font-awesome-icon :icon="item.settings.display.checkStep1Icon" class="pro-icon" />
																	<p v-if="item.settings.display.showSubTitles">{{item.settings.display.checkStep1SubTitle}}</p>
																</div>
																<div class="pro2">
																	<div class="probox-wrapper" data-anim="base2 wrapper2">
																	  <div class="probox-circle" data-anim="base2 left2"></div>
																	  <div class="probox-circle" data-anim="base2 right2"></div>
																	</div>
																	<font-awesome-icon :icon="item.settings.display.checkStep2Icon" class="pro-icon" />
																	<p v-if="item.settings.display.showSubTitles">{{item.settings.display.checkStep2SubTitle}}</p>
																</div>

																<div class="pro3">
																	<div class="probox-wrapper" data-anim="base3 wrapper3">
																	  <div class="probox-circle" data-anim="base3 left3"></div>
																	  <div class="probox-circle" data-anim="base3 right3"></div>
																	</div>
																	<font-awesome-icon :icon="item.settings.display.checkStep3Icon" class="pro-icon" />
																	<p v-if="item.settings.display.showSubTitles">{{item.settings.display.checkStep3SubTitle}}</p>
																</div>
															</div>		
															
															<div v-for="subitem in item.subitems" :key="subitem.uuid" align="center" class="d-grid gap-2">
																<button v-if="subitem.identifier == 'button_subitem'" @click.prevent="processAnswer(module, module.transient.currentItemIndex+1, item.settings.display.text, subitem.settings.display.answer)" type="button" class="btn btn-custom btn-lg btn-block mb-2" :class="subitem.settings.display.buttonsize">{{subitem.settings.display.buttontext}}<span class="font-italic" v-if="editMode"> / ({{subitem.settings.display.answer}})</span></button>
																
																<template v-if="subitem.identifier == 'text_subitem' || subitem.identifier == 'checkbox_subitem'">
																	<div class="mb-3">
																		<b-input-group class="mb-3">
																			<template v-if="subitem.identifier == 'text_subitem'">
																				<!-- :state="false" to add error style -->
																				<b-form-input :class="{ 'mandatory': subitem.settings.display.mandatory === true}" :plaintext="(subitem.settings.display.fieldid && subitem.settings.display.fieldid != null && subitem.settings.display.fieldid != '') ? false : true" type="text" :placeholder="subitem.settings.display.placeholder" @input="function(inputValue) { item.transient.formdata[subitem.settings.display.fieldid] = inputValue; module.items.splice(module.transient.currentItemIndex, 1, item) }" :id="subitem.settings.display.fieldid" trim></b-form-input>
																			</template>
																			<template v-if="subitem.identifier == 'checkbox_subitem'">
																				<b-form-checkbox
																					:id="subitem.settings.display.fieldid"
																					@input="function(checked) { item.transient.formdata[subitem.settings.display.fieldid] = checked; module.items.splice(module.transient.currentItemIndex, 1, item) }"
																					:name="subitem.settings.display.fieldid"
																					value="akzeptiert"
																					unchecked-value="nicht akzeptiert">
																					{{subitem.settings.display.text}}
																				</b-form-checkbox>
																			</template>
																			<b-input-group-append v-if="editMode">
																				<b-button class="ml-1 mr-1" size="sm" variant="light" v-b-tooltip.hover title="Einstellungen (Unterelement)" @click="openObjectSettings(subitem)"><b-icon icon="gear-fill" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
																				<b-button class="mr-1" size="sm" variant="light" v-b-tooltip.hover title="Unterelement löschen" @click.prevent="confirmDelete(subitem)"><b-icon icon="trash-fill" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
																				<b-button class="mr-1" v-if="canMoveLeftOrUp(item, subitem, 'subitems')" size="sm" variant="light" v-b-tooltip.hover title="Unterelement nach oben verschieben" @click="editAction(page, module, item, subitem, 'moveSubItemUp')" ><b-icon icon="box-arrow-up" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
																				<b-button class="mr-1" v-if="canMoveRightOrDown(item, subitem, 'subitems')" size="sm" variant="light" v-b-tooltip.hover title="Unterelement nach unten verschieben" @click="editAction(page, module, item, subitem,'moveSubItemDown')"><b-icon icon="box-arrow-down" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
																			</b-input-group-append>
																		</b-input-group>
																	</div>	
																</template>
																
																<div v-if="editMode && subitem.identifier == 'button_subitem'" class="mb-2">
																	<b-button class="ml-1 mr-1" size="sm" variant="light" v-b-tooltip.hover title="Einstellungen (Unterelement)" @click="openObjectSettings(subitem)"><b-icon icon="gear-fill" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
																	<b-button class="mr-1" size="sm" variant="light" v-b-tooltip.hover title="Unterelement löschen" @click.prevent="confirmDelete(subitem)"><b-icon icon="trash-fill" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
																	<b-button class="mr-1" v-if="canMoveLeftOrUp(item, subitem, 'subitems')" size="sm" variant="light" v-b-tooltip.hover title="Unterelement nach oben verschieben" @click="editAction(page, module, item, subitem, 'moveSubItemUp')" ><b-icon icon="box-arrow-up" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
																	<b-button class="mr-1" v-if="canMoveRightOrDown(item, subitem, 'subitems')" size="sm" variant="light" v-b-tooltip.hover title="Unterelement nach unten verschieben" @click="editAction(page, module, item, subitem,'moveSubItemDown')"><b-icon icon="box-arrow-down" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
																</div>
															</div>
															
															<p v-if="item.settings.display.displayType == 'introduction' || item.settings.display.displayType == 'budget' || item.settings.display.displayType == 'freetext'">
																<button @click.prevent="processAnswer(module, module.transient.currentItemIndex+1, item.settings.display.text, item.settings.display.buttonanswer)" type="button" class="btn btn-custom btn-lg btn-block mb-2" :class="item.settings.display.buttonsize">{{item.settings.display.buttontext}}<span class="font-italic" v-if="editMode"> / ({{item.settings.display.buttonanswer}})</span></button>
															</p>
															
															<p v-if="item.settings.display.displayType == 'contactForm'">
																<button @click.prevent="processAnswer(module, module.transient.currentItemIndex+1, item.settings.display.text, item.transient.formdata, true)" type="button" class="btn btn-custom btn-lg btn-block mb-2" :class="item.settings.display.buttonsize">{{item.settings.display.buttontext}}</button>
															</p>
															
														</div>
														<div v-if="module.settings.display.showProgress && item.settings.display.showProgress" class="progress mt-5" style="height: 20px;">
															<div class="progress-bar bg-secondary" role="progressbar" :style="'width: ' + parseInt((100/module.items.length)*(index+1)) + '%'" :aria-valuenow="parseInt((100/module.items.length)*(index+1))" aria-valuemin="0" aria-valuemax="100">{{parseInt((100/module.items.length)*(index+1)) + '%'}}</div>
														</div>
													</div>
													<div class="col-md-4" align="center">
														<div v-if="editMode" class="editIcons" style="width: 50px">
															<b-button v-if="itemConfig.items[item.identifier].supportsSubItems && itemConfig.items[item.identifier].typeSpecificSubItems[item.settings.display.displayType] && itemConfig.items[item.identifier].typeSpecificSubItems[item.settings.display.displayType].length > 0" variant="novolytics" class="mb-1" size="sm" @click="editAction(page, module, item, null, 'addSubItem')" v-b-tooltip.hover :title="'Unterelement hinzufügen'">
																<b-icon icon="node-plus" aria-hidden="true" style="height: 14px;"></b-icon>
															</b-button>
															<b-button class="mb-1" v-if="canMoveLeftOrUp(module, item, 'items')" size="sm" variant="novolytics" v-b-tooltip.hover title="Element an vorherige Position verschieben" @click="editAction(page, module, item, null, 'moveItemUp')" ><b-icon icon="box-arrow-up" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
															<b-button class="mb-1" v-if="canMoveRightOrDown(module, item, 'items')" size="sm" variant="novolytics" v-b-tooltip.hover title="Element an nächste Position verschieben" @click="editAction(page, module, item, null, 'moveItemDown')"><b-icon icon="box-arrow-down" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
															<b-button class="mb-1" size="sm" variant="novolytics" v-b-tooltip.hover title="Einstellungen (Frage-Element)" @click="openObjectSettings(item)"><b-icon icon="gear-fill" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
															<b-button class="mb-1" size="sm" variant="novolytics" v-b-tooltip.hover title="Frage-Element löschen" @click.prevent="confirmDelete(item)"><b-icon icon="trash-fill" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
															<template v-if="module.items.length > 0">
																<b-button v-if="module.transient.currentItemIndex > 0" class="mb-1" size="sm" variant="novolytics" v-b-tooltip.hover :title="'Vorheriges Element (' + (index) + '/' + module.items.length + ')'" @click.prevent="function(item) { if(showSettingsExplorer) { settingsObj = module.items[module.transient.currentItemIndex-1]; } module.transient.currentItemIndex--  }"><b-icon icon="arrow-left-square-fill" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
																<b-button v-if="module.items.length > 0 && module.transient.currentItemIndex < module.items.length-1"  class="mb-1" size="sm" variant="novolytics" v-b-tooltip.hover :title="'Nächstes Element (' + (index+2) + '/' + module.items.length + ')'" @click.prevent="function(item) { if(showSettingsExplorer) { settingsObj = module.items[module.transient.currentItemIndex+1]; } module.transient.currentItemIndex++ }"><b-icon icon="arrow-right-square-fill" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
															</template>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</template>
								
								<template v-if="module.identifier == 'advantage_module'">
									<div class="container">
										<b-card-group deck>
											<b-card v-for="item in module.items" :key="item.uuid" class="text-center" :class="{'shadow': module.settings.display.showShadow, 'borderNone' : !module.settings.display.showBorder }" :style="'background-color: ' + module.settings.display.bgColor + ' !important;'">
												<b-card-text v-if="item.settings.display.showIcon">
													<caption :is="item.settings.display.iconSize"><font-awesome-icon :icon="item.settings.display.icon" aria-hidden="true" :style="{'font-size': '1.5em', 'color': getColor(module.settings.display.iconColor) }" /></caption>
												</b-card-text>
												<b-card-text v-if="item.settings.display.showHeading"><caption :is="item.settings.display.headingSize" :class="{ 'text-uppercase': item.settings.display.upperCase === true }">{{ item.settings.display.heading }}</caption></b-card-text>
												<b-card-text v-if="item.settings.display.showText" :class="item.settings.display.textSize">{{item.settings.display.text}}</b-card-text>
												 <b-card-footer v-if="editMode">
													<b-button class="mr-1 mb-1" v-if="canMoveLeftOrUp(module, item, 'items')" size="sm" variant="novolytics" v-b-tooltip.hover title="Element nach links verschieben" @click="editAction(page, module, item, null, 'moveItemUp')" ><b-icon icon="box-arrow-left" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
													<b-button class="mr-1 mb-1" v-if="canMoveRightOrDown(module, item, 'items')" size="sm" variant="novolytics" v-b-tooltip.hover title="Element nach rechts verschieben" @click="editAction(page, module, item, null, 'moveItemDown')"><b-icon icon="box-arrow-right" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
													<b-button class="mr-1 mb-1" size="sm" variant="novolytics" v-b-tooltip.hover title="Einstellungen" @click="openObjectSettings(item)"><b-icon icon="gear-fill" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
													<b-button class="mr-1 mb-1" size="sm" variant="novolytics" v-b-tooltip.hover title="Frage-Element löschen" @click.prevent="confirmDelete(item)"><b-icon icon="trash-fill" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
												</b-card-footer>
											</b-card>
										</b-card-group>
									</div>
								</template>
								
								<template v-if="module.identifier == 'images_module'">
									<div class="container">
										<b-card-group deck>
											<!--<b-card v-for="item in module.items" :key="item.uuid" no-body :img-src="item.settings.display.imageSource" img-alt="" img-topclass="text-center shadow borderNone">-->
											<b-card v-for="item in module.items" :key="item.uuid" no-body :class="{'shadow': module.settings.display.showShadow, 'borderNone' : !module.settings.display.showBorder }">
												<b-card-img :height="item.settings.display.imageHeight != '' ? item.settings.display.imageHeight : ''" :src="item.settings.display.imageSource" alt="" top class="rounded-0"></b-card-img>
												<b-card-footer v-if="editMode" class="text-center">
													<b-button class="mr-1 mb-1" v-if="canMoveLeftOrUp(module, item, 'items')" size="sm" variant="novolytics" v-b-tooltip.hover title="Element nach links verschieben" @click="editAction(page, module, item, null, 'moveItemUp')" ><b-icon icon="box-arrow-left" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
													<b-button class="mr-1 mb-1" v-if="canMoveRightOrDown(module, item, 'items')" size="sm" variant="novolytics" v-b-tooltip.hover title="Element nach rechts verschieben" @click="editAction(page, module, item, null, 'moveItemDown')"><b-icon icon="box-arrow-right" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
													<b-button class="mr-1 mb-1" size="sm" variant="novolytics" v-b-tooltip.hover title="Einstellungen" @click="openObjectSettings(item)"><b-icon icon="gear-fill" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
													<b-button class="mr-1 mb-1" size="sm" variant="novolytics" v-b-tooltip.hover title="Frage-Element löschen" @click.prevent="confirmDelete(item)"><b-icon icon="trash-fill" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
												</b-card-footer>
											</b-card>
										</b-card-group>
								</div>
								</template>
								
								<template v-if="module.identifier == 'timeline_module'">
									<div class="container">
										<!--<h2 class="text-uppercase">Ablauf der Bewerbung:</h2>-->
										<template v-if="module.settings.display.showHeading"><caption :is="module.settings.display.headingSize" :class="{ 'text-uppercase': module.settings.display.upperCase === true }">{{ module.settings.display.heading }}</caption></template>
										<!-- For vertical timeline see: https://www.w3schools.com/howto/howto_css_timeline.asp -->
										<div class="timeline" :style="'--timelineColor: ' + module.settings.display.timelineColor + ';'">
											<div class="timeline-container" v-for="item in module.items" :key="item.uuid" :class="{ 'tl-left' : item.settings.display.orientation == 'left', 'tl-right' : item.settings.display.orientation == 'right' }" :style="'--elementColor: ' + module.settings.display.elementColor + ';'">
												<div class="timeline-content">
													<div v-if="editMode" class="editIcons" style="position: absolute; right: 10px">
														<b-button class="mr-1" size="sm" variant="light" v-b-tooltip.hover title="Einstellungen (Timeline Element)" @click="openObjectSettings(item)"><b-icon icon="gear-fill" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
														<b-button size="sm" variant="light" v-b-tooltip.hover title="Timeline Element löschen" @click.prevent="confirmDelete(item)"><b-icon icon="trash-fill" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
													</div>
													<!-- <h5>{{item.settings.display.heading}}</h5> -->
													<template v-if="item.settings.display.showHeading"><caption :is="item.settings.display.headingSize" :class="{ 'text-uppercase': item.settings.display.upperCase == true }">{{item.settings.display.heading}}</caption></template>
													<p v-if="item.settings.display.showText" :class="item.settings.display.textSize">{{item.settings.display.text}}</p>
												</div>
											</div>
										</div>
									</div>
								</template>
								
								<template v-if="module.identifier == 'testimonial_module'">
									<div class="container" align="center">
										<!--<h2 class="text-uppercase">Das sagen unsere Kunden</h2>-->
										<template v-if="module.settings.display.showHeading"><caption :is="module.settings.display.headingSize" :class="{ 'text-uppercase': module.settings.display.upperCase === true }">{{ module.settings.display.heading }}</caption></template>
										<div :id="module.uuuid" class="carousel slide mt-4" data-bs-touch="true" data-bs-ride="carousel" style="height: 280px">
											<div class="carousel-indicators">
												<button v-for="(item,index) in module.items" :key="item.uuid" type="button" :data-bs-target="module.uuuid" :data-bs-slide-to="index" :class="{ 'active' : index == module.transient.currentItemIndex }" :aria-current="index == 0 ? 'true' : 'false'" :aria-label="item.settings.display.authorName"></button>
											</div>
											<div class="carousel-inner">
												<div v-for="(item,index) in module.items" :key="item.uuid" class="carousel-item" :class="{ 'active': index == module.transient.currentItemIndex}">
													<div class="row align-items-center">
														<div class="col-md-1">
														</div>
														<div class="col-md-10">
															<p v-if="item.settings.display.showText" class="lh-sm mb-4" :class="item.settings.display.textSize">		
																{{item.settings.display.text}}	
															</p>
															<div v-if="item.settings.display.showAuthorInfo" style="width: 320px; mt-3" align="center">
																<div v-if="item.settings.display.showImage" style="width: 80px;">
																	<img width="50" height="50" :src="item.settings.display.imageSource" class="img-fluid rounded-circle">
																</div>
																<div v-if="item.settings.display.showAuthorName || item.settings.display.showAuthorPosition" class="text-muted">
																	<span v-if="item.settings.display.showAuthorName"><small><b>{{item.settings.display.authorName}}</b></small></span><br/>
																	<span v-if="item.settings.display.showAuthorPosition" class="text-custom-primary font-monospace"><small>{{item.settings.display.authorPosition}}</small></span>
																</div>
															</div>
														</div>
														<div class="col-md-1">
															<div v-if="editMode" class="editIcons">
																<b-button class="mb-1" size="sm" variant="light" v-b-tooltip.hover title="Einstellungen (Testimonial-Element)" @click="openObjectSettings(item)"><b-icon icon="gear-fill" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
																<b-button class="mb-1" size="sm" variant="light" v-b-tooltip.hover title="Testimonial-Element löschen" @click.prevent="confirmDelete(item)"><b-icon icon="trash-fill" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
																<b-button v-if="module.transient.currentItemIndex > 0" class="mb-1" size="sm" variant="light" v-b-tooltip.hover title="Vorheriges Element" @click.prevent="function(item) { if(showSettingsExplorer) { settingsObj = module.items[module.transient.currentItemIndex-1]; } module.transient.currentItemIndex--  }"><b-icon icon="arrow-left-square-fill" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
																<b-button v-if="module.items.length > 0 && module.transient.currentItemIndex < module.items.length-1"  class="mb-1" size="sm" variant="light" v-b-tooltip.hover title="Nächstes Element" @click.prevent="function(item) { if(showSettingsExplorer) { settingsObj = module.items[module.transient.currentItemIndex+1]; } module.transient.currentItemIndex++ }"><b-icon icon="arrow-right-square-fill" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
															</div>
														</div>
													</div>
												</div>
											</div>
											<!--
											<button class="carousel-control-prev" type="button" data-bs-target="#carouselCustomersFeedback" data-bs-slide="prev">
												<span class="carousel-control-prev-icon" aria-hidden="true"></span>
												<span class="visually-hidden">Previous</span>
											</button>
											<button class="carousel-control-next" type="button" data-bs-target="#carouselCustomersFeedback" data-bs-slide="next">
												<span class="carousel-control-next-icon" aria-hidden="true"></span>
												<span class="visually-hidden">Next</span>
											</button>
											-->
										</div>
									</div>
								</template>
								
								<template v-if="module.identifier == 'html_module'">
									<!--<div class="container">
										<template v-if="module.settings.display.showHeading"><caption :is="module.settings.display.headingSize" :class="{ 'text-uppercase': module.settings.display.upperCase === true }">{{ module.settings.display.heading }}</caption></template>
										<div class="card mt-3 mb-3" :class="{'shadow': module.settings.display.showShadow, 'borderNone' : !module.settings.display.showBorder }" :style="'background-color: ' + module.settings.display.bgColor + ' !important;'">
											<div class="card-body">
												<p class="card-text" v-html="module.settings.display.htmlCode"></p>
											</div>
										</div>
									</div>-->
									<template v-if="module.editor.editText">
										<b-form-textarea rows="8" id="htmlcode" placeholder="Hier kann beliebiger HTML-Code eingegeben werden." type="text" size="sm" v-model="module.settings.display.htmlCode"></b-form-textarea>
									</template>
									<template v-else>
										<p class="card-text" v-html="module.settings.display.htmlCode"></p>
									</template>
								</template>
								
								<template v-if="module.identifier == 'spacer_module'">
									<div class="container" :class="'mb-' + module.settings.display.spacing">
										<i class="" v-if="editMode" ><small>Spacer mit Spacing: <b>{{module.settings.display.spacing}}</b> (Dieser Hinweis wird nur im Bearbeitungsmodus angezeigt)</small></i>
									</div>
								</template>
								
								<template v-if="module.identifier == 'footer_module'">
									<nav class="navbar" :style="'background-color: ' + module.settings.display.bgColor + ';'">
										<div v-if="editMode" class="container-fluid justify-content-center">
											<div class="text-center" v-for="item in module.items" :key="item.uuid">
												<a class="nav-link" :class="item.settings.display.textSize" :style="{'color': getColor(module.settings.display.textColor)}" :href="item.settings.display.link">{{item.settings.display.text}}</a>
												<div v-if="editMode" class="editIcons">
													<b-button class="mr-1" size="sm" variant="light" v-b-tooltip.hover title="Einstellungen (Footer-Element)" @click="openObjectSettings(item)"><b-icon icon="gear-fill" aria-hidden="true" style="height: 12px;"></b-icon></b-button>
													<b-button size="sm" variant="light" v-b-tooltip.hover title="Footer-Element löschen" @click.prevent="confirmDelete(item)"><b-icon icon="trash-fill" aria-hidden="true" style="height: 12px;"></b-icon></b-button>
													<b-button class="mr-1" v-if="canMoveLeftOrUp(module, item, 'items')" size="sm" variant="light" v-b-tooltip.hover title="Nach links verschieben" @click="editAction(page, module, item, null, 'moveItemUp')" ><b-icon icon="box-arrow-left" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
													<b-button class="mr-1" v-if="canMoveRightOrDown(module, item, 'items')" size="sm" variant="light" v-b-tooltip.hover title="Nach rechts verschieben" @click="editAction(page, module, item, null, 'moveItemDown')"><b-icon icon="box-arrow-right" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
															
												</div>
											</div>
										</div>
										<div v-else class="container-fluid justify-content-center">
											<a v-for="item in module.items" :key="item.uuid" class="nav-link" :class="item.settings.display.textSize" :style="{'color': getColor(module.settings.display.textColor)}" :href="item.settings.display.link">{{item.settings.display.text}}</a>	
										</div>
									</nav>
								</template>
								
							  </b-card>
							  <!--</drag>
							</drop> -->
						  </b-card>
						</drop>
					  </b-card-text>
					  <b-card-footer v-if="showPageEditorFooter" align="right" class="d-none d-lg-block d-xl-block">
						<b-button variant="secondary" size="sm" class="mr-2" @click="editMode = !editMode" v-b-tooltip.hover :title="editMode ? 'Bearbeitungsmodus beenden' : 'Bearbeitungsmodus'">
						  <b-icon :icon="editMode ? 'pencil-fill' : 'pencil'" aria-hidden="true"></b-icon> Bearbeitungsmodus
						</b-button>
						<!--
						<b-button v-b-tooltip.hover title="Modul hinzufügen"  variant="success" size="sm" class="mr-2" @click.prevent="addModule(page)">
						  <b-icon icon="plus-square" aria-hidden="true"></b-icon> Modul hinzufügen
						</b-button>
						-->
						<b-button v-b-tooltip.hover title="Seiteneinstellungen" variant="novolytics" size="sm" class="mr-2" @click.prevent="openPageSettings(page)">
						  <b-icon icon="gear-fill" aria-hidden="true"></b-icon> Seiteneinstellungen
						</b-button>
					  </b-card-footer>
					  <b-card-footer align="right" class="d-block d-lg-none d-xl-none">
						<b-button variant="secondary" size="sm" class="mr-2" @click="editMode = !editMode" v-b-tooltip.hover :title="editMode ? 'Bearbeitungsmodus beenden' : 'Bearbeitungsmodus'">
						  <b-icon :icon="editMode ? 'pencil-fill' : 'pencil'" aria-hidden="true"></b-icon>
						</b-button>
						<!--
						<b-button v-b-tooltip.hover title="Modul hinzufügen"  variant="success" size="sm" class="mr-2" @click.prevent="addModule(page)">
						  <b-icon icon="plus-square" aria-hidden="true"></b-icon>
						</b-button>
						-->
						<b-button variant="danger" size="sm" class="mr-2" @click.prevent="confirmDelete(page)" v-b-tooltip.hover :title="currentLayout.pages.length == 1 ? 'Es muss mindestens eine Seite vorhanden sein' : 'Seite löschen'">
						  <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
						</b-button>
						<b-button v-b-tooltip.hover title="Seiteneinstellungen" variant="novolytics" size="sm" class="mr-2" @click.prevent="openPageSettings(page)">
						  <b-icon icon="gear-fill" aria-hidden="true"></b-icon>
						</b-button>
					  </b-card-footer>
					  </b-tab>
					  <!--
						<template #tabs-end>
						<b-nav-item role="presentation" @click.prevent="addAndSwitchPage" href="#" v-b-tooltip.hover title="Seite hinzufügen"><b-icon icon="file-earmark-plus"></b-icon></b-nav-item>
						</template>
						-->
					</b-tabs>
				</b-card>
			</template>
		  </div>
		</b-col>
	  </b-row>
	</template>
</b-container>
<b-modal
  id="projectsettings"
  ref="projectsettingsmodal"
  size="lg"
  title="Projekteigenschaften"
  @ok="saveProjectSettings"
  :ok-only=true :no-close-on-esc="!this.project.initialized" :no-close-on-backdrop="!this.project.initialized" :hide-header-close="!this.project.initialized">
   <b-tabs card>
    <b-tab title="Allgemein" :active="projectsettingsTab === null || projectsettingsTab === 'general'">
      <b-card-text>
		<b-form-group
          class="font-weight-bold"
          label="Version (Projekt)"
          label-for="version"
          label-size="sm"
          label-cols="6">
          <b-form-input
            id="version"
			:readonly="true"
            v-model="project.version">
			</b-form-input>
        </b-form-group>
		<b-form-group
          class="font-weight-bold"
          label="Autor"
          label-for="author"
          label-size="sm"
          label-cols="6">
          <b-form-input
            id="author"
			:readonly="true"
            v-model="project.author">
			</b-form-input>
        </b-form-group>
        <b-form-group
          class="font-weight-bold"
          label="Projekt-ID"
          label-for="projectid"
          label-size="sm"
          label-cols="6"
          invalid-feedback="ID ist erforderlich">
          <b-form-input
            id="projectid"
            v-model="project.id"
            required></b-form-input>
        </b-form-group>
        <b-form-group
          class="font-weight-bold"
          label="Bezeichnung/Seitentitel"
          label-for="projecttitle"
          label-size="sm"
          label-cols="6"
          invalid-feedback="Bezeichnung ist erforderlich">
          <b-form-input
            id="projecttitle"
            v-model="project.title"
            required></b-form-input>
        </b-form-group>
		<b-form-group 
			class="font-weight-bold"
			label="Vorlage"
			label-for="projecttemplate"
			label-size="sm"
			label-cols="6"
			invalid-feedback="Bitte Vorlage auswählen">
			<b-form-select id="projecttemplate" size="sm" v-model="project.template" class="mb-3">
				<b-form-select-option value="empty">Leer</b-form-select-option>
				<b-form-select-option value="neukunden">Neukunden</b-form-select-option>
				<b-form-select-option value="mitarbeiter">Neue Mitarbeiter</b-form-select-option>
			</b-form-select>
		</b-form-group>
		
		<b-form-group
			label="Confirmation Page aktivieren"
			label-cols="6"
			class="font-weight-bold "
			label-for="enableConfirmationPage">
			<b-form-checkbox
				size="lg"
				id="enableConfirmationPage"
				v-model="project.enableConfirmationPage"
				name="enableConfirmationPage"
				:value="true"
				:unchecked-value="false">
			</b-form-checkbox>
		</b-form-group>		
				
		<b-form-group
			label="Leads speichern"
			label-cols="6"
			class="font-weight-bold "
			label-for="saveLeads">
			<b-form-checkbox
				size="lg"
				id="saveLeads"
				v-model="project.saveLeads"
				name="saveLeads"
				:value="true"
				:unchecked-value="false">
			</b-form-checkbox>
		</b-form-group>	
		
       </b-card-text>
    </b-tab>
	<b-tab title="Layout" :active="projectsettingsTab === 'layout'">
		<b-tabs card v-if="settingsConfig && settingsConfig.project">
			<b-tab title="Farben" active>
				<b-form-group v-for="(setting,key) in settingsConfig.project.layout.colors.settings" :key="key"
					:label="setting.displayText"
					label-cols="3"
					class="font-weight-bold p-1"
					:label-for="setting.displayText">
					<!--
					<b-form-select size="sm" v-model="project.settings.layout.colors[key]" class="mb-3">
						<b-form-select-option v-for="allowedValue in getAllowedValues(setting.allowedValues)" :key="allowedValue.key" :value="allowedValue.key">{{allowedValue.value}}</b-form-select-option>
					</b-form-select>
					-->
					<b-input-group>
						<b-form-input type="text" @input="function(inputValue) { }" size="sm" v-model="project.settings.layout.colors[key]"></b-form-input>
							<template #append>
								<div :style="'background-color:' + project.settings.layout.colors[key] + '; height: 30px; width: 30px; border: 2px solid #000000; margin-left:10px;'"></div>
							</template>
					</b-input-group>	
				</b-form-group>
			</b-tab>
		</b-tabs>
	</b-tab>
	<b-tab title="Metadaten" :active="projectsettingsTab === 'metadata'">
		<small>Hier können einzelne Meta-Tags definiert werden. Alternativ können die Metadaten auch als Markup-Code komplett im Reiter HTML-Header eingefügt werden.</small>
		<b-table
        striped
        ref="refMetadata"
        selectable
        :items="project.metadata"
        :fields="[{ key: 'property', label: 'property' },{ key: 'name', label: 'name' },{ key: 'content', label: 'content' },{ key: 'actions', label: 'Aktion' }]"
        caption-top>
        <template #table-caption>
			<b-button v-b-tooltip.hover title="Meta-Tag hinzufügen" class="mr-2" size="sm" variant="success" @click="addMetaTag(project)"><b-icon style="height: 14px;" icon="plus-square" aria-hidden="true"></b-icon> Meta-Tag hinzufügen</b-button>
		</template>
        <template #cell(property)="data">
          <template v-if="refMetaTagEditItem !== null && refMetaTagEditItem.rowindex == data.index">
              <b-form-input v-model="project.metadata[data.index].property" placeholder="property"></b-form-input>
          </template>
          <template v-else>
             {{ data.value }}
          </template>
        </template>
		<template #cell(name)="data">
          <template v-if="refMetaTagEditItem !== null && refMetaTagEditItem.rowindex == data.index">
              <b-form-input v-model="project.metadata[data.index].name" placeholder="name"></b-form-input>
          </template>
          <template v-else>
             {{ data.value }}
          </template>
        </template>
		<template #cell(content)="data">
          <template v-if="refMetaTagEditItem !== null && refMetaTagEditItem.rowindex == data.index">
              <b-form-input v-model="project.metadata[data.index].content" placeholder="content"></b-form-input>
          </template>
          <template v-else>
             {{ data.value }}
          </template>
        </template>
        <template #cell(actions)="row">
          <b-button v-if="refMetaTagEditItem === null" v-b-tooltip.hover title="Bearbeiten" variant="novolytics" size="sm" @click="editMetaTag(row.item, row.index, $event.target)" class="mr-1"><b-icon style="height: 14px;" icon="pencil" aria-hidden="true"></b-icon></b-button>
          <b-button v-if="refMetaTagEditItem !== null && refMetaTagEditItem.rowindex == row.index" v-b-tooltip.hover title="Bearbeitung beenden" variant="success" size="sm" @click="refMetaTagEditItem = null" class="mr-1"><b-icon style="height: 14px;" icon="check-square" aria-hidden="true"></b-icon></b-button>
          <b-button v-if="refMetaTagEditItem === null" v-b-tooltip.hover title="Meta-Tag löschen" variant="danger" size="sm" @click="confirmDelete(row.item, project)"><b-icon style="height: 14px;" icon="trash-fill" aria-hidden="true"></b-icon></b-button>
        </template>
      </b-table>
	</b-tab>
	<b-tab title="HTML-Header" :active="projectsettingsTab === 'htmlheader'">		
		<b-form-group
			label="Header Markup"
			label-cols="3"
			class="font-weight-bold p-1"
			label-for="htmlHeaderMarkup">
			<b-input-group>
				<b-form-textarea rows="8" id="htmlHeaderMarkup" placeholder="Hier kann zusätzlicher Code für den HTML-Header der Seite eingefügt werden. Dabei kann es sich um Meta-Tags, Styles oder Script Snippets handeln." type="text" size="sm" v-model="project.htmlheader"></b-form-textarea>
			</b-input-group>	
		</b-form-group>
	</b-tab>
	<b-tab v-if="project && project.tracking" title="Tracking" :active="projectsettingsTab === 'tracking'">
		<b-tabs card>
			<b-tab v-if="project && project.tracking && project.tracking.google" title="Google" active>
				<b-form-group
					label="Tracking Code"
					label-cols="3"
					class="font-weight-bold p-1"
					label-for="googleTrackingCode">
					<b-input-group>
						<b-form-textarea rows="8" id="googleTrackingCode" placeholder="Hier kann der Facebook Tracking Code eingefügt werden" type="text" size="sm" v-model="project.tracking.google.code"></b-form-textarea>
					</b-input-group>	
				</b-form-group>
				
				<b-form-group
					label="auf Landing Page"
					label-cols="3"
					class="font-weight-bold p-1"
					label-for="includeGoogleTrackingOnLandingPage">
					<b-form-checkbox
						size="lg"
						id="includeGoogleTrackingOnLandingPage"
						v-model="project.tracking.google.includeOnLandingPage"
						name="includeGoogleTrackingOnLandingPage"
						:value="true"
						:unchecked-value="false">
					</b-form-checkbox>
				</b-form-group>
				
				<b-form-group
					label="auf Confirmation Page"
					label-cols="3"
					class="font-weight-bold p-1"
					label-for="includeGoogleTrackingOnConfirmationPage">
					<b-form-checkbox
						size="lg"
						id="includeGoogleTrackingOnConfirmationPage"
						v-model="project.tracking.google.includeOnConfirmationPage"
						name="includeGoogleTrackingOnConfirmationPage"
						:value="true"
						:unchecked-value="false">
					</b-form-checkbox>
				</b-form-group>
				
				<b-form-group
					label="Opt-In erforderlich"
					label-cols="3"
					class="font-weight-bold p-1"
					label-for="googleTrackingRequiresOptIn">
					<b-form-checkbox
						size="lg"
						id="googleTrackingRequiresOptIn"
						v-model="project.tracking.google.requiresOptIn"
						name="googleTrackingRequiresOptIn"
						:value="true"
						:unchecked-value="false">
					</b-form-checkbox>
				</b-form-group>
			</b-tab>
			<b-tab title="Facebook" v-if="project && project.tracking && project.tracking.facebook" active>
				<b-form-group
					label="Tracking Code"
					label-cols="3"
					class="font-weight-bold p-1"
					label-for="facebookTrackingCode">
					<b-input-group>
						<b-form-textarea rows="8" id="facebookTrackingCode" placeholder="Hier kann der Facebook Tracking Code eingefügt werden" type="text" size="sm" v-model="project.tracking.facebook.code"></b-form-textarea>
					</b-input-group>	
				</b-form-group>
				
				<b-form-group
					label="auf Landing Page"
					label-cols="3"
					class="font-weight-bold p-1"
					label-for="includeFacebookTrackingOnLandingPage">
					<b-form-checkbox
						size="lg"
						id="includeFacebookTrackingOnLandingPage"
						v-model="project.tracking.facebook.includeOnLandingPage"
						name="includeFacebookTrackingOnLandingPage"
						:value="true"
						:unchecked-value="false">
					</b-form-checkbox>
				</b-form-group>
				
				<b-form-group
					label="auf Confirmation Page"
					label-cols="3"
					class="font-weight-bold p-1"
					label-for="includeGoogleTrackingOnConfirmationPage">
					<b-form-checkbox
						size="lg"
						id="includeFacebookTrackingOnConfirmationPage"
						v-model="project.tracking.facebook.includeOnConfirmationPage"
						name="includeFacebookTrackingOnConfirmationPage"
						:value="true"
						:unchecked-value="false">
					</b-form-checkbox>
				</b-form-group>
				
				<b-form-group
					label="Opt-In erforderlich"
					label-cols="3"
					class="font-weight-bold p-1"
					label-for="facebookTrackingRequiresOptIn">
					<b-form-checkbox
						size="lg"
						id="facebookTrackingRequiresOptIn"
						v-model="project.tracking.facebook.requiresOptIn"
						name="facebookTrackingRequiresOptIn"
						:value="true"
						:unchecked-value="false">
					</b-form-checkbox>
				</b-form-group>
			</b-tab>
		</b-tabs>
	</b-tab>
	<b-tab v-if="project && project.cookieconsent" title="Cookies" :active="projectsettingsTab === 'cookies'">
		<b-form-group
			label="Cookie Consent (Opt-In) aktivieren"
			label-cols="5"
			class="font-weight-bold p-1"
			label-for="enableCookieConsent">
			<b-form-checkbox
				size="lg"
				id="enableCookieConsent"
				v-model="project.cookieconsent.enabled"
				name="enableCookieConsent"
				:value="true"
				:unchecked-value="false">
			</b-form-checkbox>
		</b-form-group>
		
		<b-form-group
			label="Hinweistext"
			label-cols="5"
			class="font-weight-bold p-1"
			label-for="cookieConsentMessage">
			<b-input-group>
				<b-form-textarea rows="4" id="cookieConsentMessage" placeholder="Hinweistext für Cookie Banner" type="text" size="sm" v-model="project.cookieconsent.message"></b-form-textarea>
			</b-input-group>	
		</b-form-group>
		
		<b-form-group
			label="Button-Text (Ablehnen)"
			label-cols="5"
			class="font-weight-bold p-1"
			label-for="cookieConsentDeny">
			<b-input-group>
				<b-form-input id="cookieConsentDeny" placeholder="Text für Ablehnen-Button" type="text" size="sm" v-model="project.cookieconsent.deny"></b-form-input>
			</b-input-group>	
		</b-form-group>
		
		<b-form-group
			label="Button-Text (Akzeptieren)"
			label-cols="5"
			class="font-weight-bold p-1"
			label-for="cookieConsentAccept">
			<b-input-group>
				<b-form-input id="cookieConsentAccept" placeholder="Text für Akzeptieren-Button" type="text" size="sm" v-model="project.cookieconsent.accept"></b-form-input>
			</b-input-group>	
		</b-form-group>
		
		<b-form-group
			label="Linktext"
			label-cols="5"
			class="font-weight-bold p-1"
			label-for="cookieConsentLinktext">
			<b-input-group>
				<b-form-input id="cookieConsentLinktext" placeholder="Linktext" type="text" size="sm" v-model="project.cookieconsent.linktext"></b-form-input>
			</b-input-group>	
		</b-form-group>
		
		<b-form-group
			label="Link"
			label-cols="5"
			class="font-weight-bold p-1"
			label-for="cookieConsentLink">
			<b-input-group>
				<b-form-input id="cookieConsentLink" placeholder="Link" type="text" size="sm" v-model="project.cookieconsent.link"></b-form-input>
			</b-input-group>	
		</b-form-group>
		
		<b-form-group
			label="Einstellungen (Text)"
			label-cols="5"
			class="font-weight-bold p-1"
			label-for="cookieConsentPolicy">
			<b-input-group>
				<b-form-input id="cookieConsentPolicy" placeholder="Einstellungen (Text)" type="text" size="sm" v-model="project.cookieconsent.policy"></b-form-input>
			</b-input-group>	
		</b-form-group>
		
	</b-tab>
	<b-tab v-if="project && project.attributes" title="Attribute" :active="projectsettingsTab === 'attributes'">
		<b-table
        striped
        ref="refAttributes"
        selectable
        :items="project.attributes"
        :fields="[{ key: 'id', label: 'ID' },{ key: 'value', label: 'Wert' },{ key: 'actions', label: 'Aktion' }]"
        caption-top>
        <template #table-caption>
			<b-button v-b-tooltip.hover title="Attribut hinzufügen" class="mr-2" size="sm" variant="success" @click="addAttribute(project)"><b-icon style="height: 14px;" icon="plus-square" aria-hidden="true"></b-icon> Attribut hinzufügen</b-button>
		</template>
        <template #cell(id)="data">
          <template v-if="refAttributeEditItem !== null && refAttributeEditItem.rowindex == data.index">
              <b-form-input v-model="project.attributes[data.index].id" placeholder="ID"></b-form-input>
          </template>
          <template v-else>
             {{ data.value }}
          </template>
        </template>
		<template #cell(value)="data">
          <template v-if="refAttributeEditItem !== null && refAttributeEditItem.rowindex == data.index">
              <b-form-input v-model="project.attributes[data.index].value" placeholder="Wert"></b-form-input>
          </template>
          <template v-else>
             {{ data.value }}
          </template>
        </template>
        <template #cell(actions)="row">
          <b-button v-if="refAttributeEditItem === null" v-b-tooltip.hover title="Bearbeiten" variant="novolytics" size="sm" @click="editAttribute(row.item, row.index, $event.target)" class="mr-1"><b-icon style="height: 14px;" icon="pencil" aria-hidden="true"></b-icon></b-button>
          <b-button v-if="refAttributeEditItem !== null && refAttributeEditItem.rowindex == row.index" v-b-tooltip.hover title="Bearbeitung beenden" variant="success" size="sm" @click="refAttributeEditItem = null" class="mr-1"><b-icon style="height: 14px;" icon="check-square" aria-hidden="true"></b-icon></b-button>
          <b-button v-if="refAttributeEditItem === null" v-b-tooltip.hover title="Attribut löschen" variant="danger" size="sm" @click="confirmDelete(row.item, project)"><b-icon style="height: 14px;" icon="trash-fill" aria-hidden="true"></b-icon></b-button>
        </template>
      </b-table>
	</b-tab>
  </b-tabs>
</b-modal>
<b-modal
  id="toolsettings"
  ref="toolsettingsmodal"
  title="Einstellungen"
  @ok="saveToolSettings"
  :ok-only=true>
  <b-tabs card>
    <b-tab title="Allgemein">
      <b-card-text>
      </b-card-text>
    </b-tab>
    <b-tab title="Layout" active>
      <b-card-text>
        <form ref="form">
          <b-form-group
            label="Maximale Spalten pro Zeile"
            label-for="maxColsPerRow"
            invalid-feedback="Ein Wert ist erforderlich">
            <b-form-input
              id="maxColsPerRow"
              v-model="toolsettings.maxColsPerRow"
              type="number"
              required>
            </b-form-input>
          </b-form-group>
        </form>
      </b-card-text>
    </b-tab>
  </b-tabs>
</b-modal>
<b-modal
  size="lg"
  id="importproject"
  ref="importprojectmodal"
  title="Projekt aus Datei importieren"
  @ok="$refs['importprojectmodal'].hide()">
  <form ref="import_project" @submit.stop.prevent="$refs['importprojectmodal'].hide()">
    <label class="text-reader">
      <input type="file" @change="loadTextFromFile">
    </label>
  </form>
</b-modal>
<b-modal
  size="lg"
  id="loadproject"
  ref="loadprojectmodal"
  title="Projekt laden"
  @ok="$refs['loadprojectmodal'].hide()">
  <b-tabs card>
    <b-tab title="Projekte" active>
      <b-card-text>
      <template v-if="savedProjects.length == 0">
        <span class="text-danger">Es sind noch keine Projekte gespeichert</span>
      </template>
      <b-table v-if="savedProjects.length > 0"
        striped
        hover
        ref="loadProjectsTable"
        :items="savedProjects"
        :fields="[{ key: 'name', label: 'Name' }, { key: 'id', label: 'ID' }, { key: 'title', label: 'Titel' }, { key: 'storagelocation', label: 'Speicherort', class: 'text-center' }, { key: 'actions', label: 'Aktion' }]">
        <template #cell(title)="data">
          {{ data.value }}
        </template>
        <template #cell(storagelocation)="row">
          <template v-if="row.item.storagelocation">
			<b-icon scale="1.5" icon="server" aria-hidden="true"></b-icon>
          </template>
          <template v-else>
            <b-icon scale="1.5" icon="laptop" aria-hidden="true"></b-icon>
          </template>
        </template>
        <template #cell(actions)="row">
          <b-button-group>
            <b-button v-b-tooltip.hover title="Projekt laden" variant="novolytics" size="sm" @click="loadProject(row.item, row.index, $event.target)" class="mr-1"><b-icon style="height: 14px;" icon="folder2-open" aria-hidden="true"></b-icon></b-button>
            <b-button :disabled="row.item.storagelocation == 'server1'" v-b-tooltip.hover title="Projekt löschen" variant="danger" size="sm" @click="deleteProject(row.item, row.index, $event.target)" class="mr-1"><b-icon style="height: 14px;" icon="trash-fill" aria-hidden="true"></b-icon></b-button>
          </b-button-group>
        </template>
      </b-table>
      </b-card-text>
    </b-tab>
  </b-tabs>
</b-modal>
<b-modal
  id="saveprojectdialog"
  ref="saveprojectdialogmodal"
  title="Projekt speichern..."
  @show="projectnameState = null"
  @hidden="projectnameState = null"
  @ok="saveProjectAs">
  <form ref="save_project" @submit.stop.prevent="handleSubmit">
    <b-form-group
      label="Name"
      label-for="projectname"
      invalid-feedback="Name ist erforderlich"
      :state="projectnameState">
      <b-form-input
        id="projectname"
        v-model="project.name"
        :state="projectnameState"
        required>
      </b-form-input>
    </b-form-group>
	<b-form-group
      label="Projekt-ID"
      label-for="projectid">
      <b-form-input
        id="projectid"
        v-model="project.id"
		:disabled="true"
        required>
      </b-form-input>
    </b-form-group>
	<b-form-checkbox
		id="saveProjectOnServer"
		v-model="saveProjectOnServer"
		name="saveProjectOnServer"
		:value="true"
		:unchecked-value="false">
		Auf Server speichern
	</b-form-checkbox>
	<b-form-checkbox
		id="overwriteExistingProject"
		v-model="overwriteExistingProject"
		name="overwriteExistingProject"
		:value="true"
		:unchecked-value="false">
		Vorhandenes Projekt überschreiben
	</b-form-checkbox>
  </form>
</b-modal>
<b-modal
  id="migrateprojectdialog"
  ref="migrateprojectdialogmodal"
  title="Projekt migrieren..."
  @ok="migrateProject">
  <p>
	Das Projekt <b>{{tempProject.projectName}}</b> wurde mit einer &auml;lteren Version (<b>{{tempProject.version}}</b>) des Anfragetools erstellt und muss auf die aktuelle Software-Version (<b>{{editorConfig.version}}</b>) migriert werden damit neue
	Features verwendet werden k&ouml;nnen. <br/><br/>Soll die Migration jetzt automatisch durchgef&uuml;hrt werden?
  </p>
</b-modal>
</div>
</template>

<script>
import EventBus from '../EventBus.js'
import { v4 as uuidv4 } from 'uuid'
import ModuleExplorer from '../components/ModuleExplorer.vue'
// import MediaExplorer from '../components/MediaExplorer.vue'
import Navigation from '../components/Navigation.vue'
import QuickActions from '../components/QuickActions.vue'
//import Preview from '../components/Preview.vue'
import APIExplorer from '../components/APIExplorer.vue'
import Embeddings from '../components/Embeddings.vue'
import MediaLibrary from '../components/MediaLibrary.vue'
// FormEditorConfig is only for local testing where editorConfig is included from a local js file
//import FormEditorConfig from '../config/editorConfig.js'
import SampleData from '../config/sampleData.js'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
//import noUiSlider from 'nouislider';
import veeno from 'veeno'
// for default styles
import 'nouislider/dist/nouislider.css';  
import HTMLEditor from '../components/HTMLEditor.vue'

/* global LIP */
/* eslint-disable no-unused-vars */
export default {
	name: 'Main',
	components: {
		'module-explorer': ModuleExplorer,
		// 'media-explorer': MediaExplorer,
		'top-level-navigation': Navigation,
		'quick-actions': QuickActions,
		//'project-preview': Preview,
		'api-explorer': APIExplorer,
		'view-embeddings': Embeddings,
		'media-library': MediaLibrary,
		vueDropzone: vue2Dropzone,
		veeno,
		'html-editor': HTMLEditor
	},
	props: [
	],
	data: () => ({
		requiredHost: "anfragetool.com",
		attachmentStore: {
			attachments: {},
			dropzoneOptions: {
				url: 'https://httpbin.org/post',
				thumbnailWidth: 100,
				maxFilesize: 0.5,
				addRemoveLinks: false,
				dictDefaultMessage: "Drag & Drop für Upload",
				/*acceptedFiles: 'image/*',*/
				autoProcessQueue: false,
				autoQueue: false
			}
		},
		answers: [],
		showTechnicalNames: false,
		mode: 'editor',
		imageCache: {},
		mediaLibrary: null,
		connected: false,
		connectionStatus: [],
		savedProjects: [],
		editorConfig: {},
		embeddings: [],
		//idCache: {},
		compressed: {},
		unsavedChanges: true,
		saveProjectOnServer: true,
		overwriteExistingProject: false,
		//projectname: null,
		projectnameState: null,
		settingsObj: {},
		refConditionEditItem: null,
		refAttachmentEditItem: null,
		refAttributeEditItem: null,
		refMetaTagEditItem: null,
		editing: null,
		click: undefined,
		projectsettingsTab: 'general',
		itemConfig: {},
		settingsConfig: {},
		settingsExplorerAlign: 'left',
		showSettingsExplorer: false,
		settingsTabIndex: 1,
		hovering: {},
		enableHovering: false,
		enableAttributes: false,
		idCounter: {
			page: 0,
			module: 0,
			item: 0,
			subitem: 0
		},
		uuidCache: {},
		datasetCache: {},
		modelCache: {},
		editMode: true,
		dragObj: null,
		pageIndex: null,
		currentPage: null,
		currentLayout: null,
		over: null,
		showRefLines: true,
		showModuleExplorer: true,
		showHelpTexts: false,
		showObjectIDs: false,
		showPageEditorHeader: true,
		showPageEditorFooter: true,
		tempProject: {},
		project: { initialized: false },
		toolsettings: {
			maxColsPerRow: 4
		}
	}),
  computed: {
	/*primaryColor: function() {
		return 'text-' + this.project.settings.layout.colors.primaryColor
	},
	secondaryColor: function() {
		return 'text-' + this.project.settings.layout.colors.secondaryColor
	},
	tertiaryColor: function() {
		return 'text-' + this.project.settings.layout.colors.tertiaryColor
	},
	bgColor: function() {
		return 'text-' + this.project.settings.layout.colors.bgColor
	},*/
    filter () {
      return this.caseSensitive ? (item, search, textKey) => item[textKey].indexOf(search) > -1 : undefined
    },
    colSize () {
      return this.showModuleExplorer === true ? 9 : 12
    }
  },
  methods: {
	deleteProjectOnServer: function(obj) {
		console.log("Entering deleteProjectOnServer for project with ID " + obj.id + " at index " + obj.index);
		let that = this
		let payload = { action: "deleteProject", payload: "", projectid: obj.id }
		this.invokeAction('deleteProject',payload,true,
			function(data) { 
				that.savedProjects.splice(obj.index,1);
				let toastMsg = { caption: 'Fehler', msg: data.MESSAGE, msgDetail: '', append: false, variant: 'success', solid: true }
				EventBus.$emit('makeToast', toastMsg)
			},function() {
				//console.log('Entering onError callback for migrateProject...')
			}
		);
	},
	migrateProject: function() {
		console.log("Entering migrateProject for project " + this.tempProject.id);
		let that = this
		let payload = { action: "migrateProject", payload: "", projectid: this.tempProject.id }
		this.invokeAction('migrateProject',payload,true,
			function(data) { 
				console.log("Tool-Version: " + that.editorConfig.version + ", Projekt-Version nach Migration: " + data.DATA.version)
				if(that.editorConfig.version != data.DATA.version) {
					let toastMsg = { caption: 'Fehler', msg: data.MESSAGE, msgDetail: '', append: false, variant: 'danger', solid: true }
					EventBus.$emit('makeToast', toastMsg)
				} else {
					that.project = Object.assign({}, data.DATA)
					that.tempProject = {}
					that.unsavedChanges = false
					that.resetCaches()
					let toastMsg = { caption: 'Migration erfolgreich', msg: data.MESSAGE, msgDetail: '', append: false, variant: 'success', solid: true }
					EventBus.$emit('makeToast', toastMsg)
				}
			},function() {
				//console.log('Entering onError callback for migrateProject...')
			}
		);
	},
	getColor: function(color) {
		// console.log("Entering getColor for color: " + color + ": " + this.project.settings.layout.colors[color]);
		return (this.project.settings.layout.colors[color] + " !important");
	},
	invokeAction: function(action,data,showErrorMessage,onSuccess,onError) {
		//console.log("Invoke action " + action + ", with data " + JSON.stringify(data))
			
		let requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data)
		};
		//console.log("isLoggedIn: " + this.$store.getters.isLoggedIn)
		
		// Add Bearer token to request to authenticate requests
		if(this.$store.getters.isLoggedIn) {
			requestOptions.headers["Authorization"] = "Bearer " + this.$store.getters.getToken
		}
		fetch('/api/',requestOptions).then(response => response.json()).then(resp => { 
			if(resp.SUCCESS == true) {
				if(typeof onSuccess != 'undefined' && typeof onSuccess == 'function') {
					onSuccess(resp)
				}
			} else {
				if(showErrorMessage) {
					let toastMsg = { caption: 'Fehler', msg: resp.MESSAGE, msgDetail: '', append: false, variant: 'danger', solid: true }
					EventBus.$emit('makeToast', toastMsg)
				}
				
				if(typeof onError != 'undefined' && typeof onError == 'function') {
					onError(resp)
				}
			}
		})
	},
	switchPage(pageIndex) {
		this.pageIndex = pageIndex
		this.currentPage = this.currentLayout.pages[pageIndex]
	},
	getPageModules (page) {
      let headerModules = []
      let bodyModules = []
      let footerModules = []
      headerModules = page.modules.filter(module => {
        return module.settings.display.placement === 'header'
      })
      bodyModules = page.modules.filter(module => {
        return module.settings.display.placement === 'body'
      })
      footerModules = page.modules.filter(module => {
        return module.settings.display.placement === 'footer'
      })
      return headerModules.concat(bodyModules).concat(footerModules)
    },
	/*toggleConfirmationPage() {
		//console.log("Entering toggleConfirmationPage, enable: " + this.project.enableConfirmationPage)
		if(this.project.enableConfirmationPage && this.currentLayout.pages.length == 1) {
			//console.log('Adding confirmation page...')
			const newPage = { type: 'page', parentUuid: this.currentLayout.uuid, uuid: uuidv4(), id: 'C', title: 'C', modules: [], attributes:[], settings: { } }
			this.currentLayout.pages.push(newPage)
			this.addToCache(newPage)
		} else {
			if(this.currentLayout.pages.length == 2) {
				//console.log('Deleting confirmation page...')
				this.deleteObject (this.currentLayout.pages[1]) 
			}
		}
		//console.log("Leaving toggleConfirmationPage...")
	},*/
	waitForCheck(module,item,delayInMillis,index) {
		if(index == module.transient.currentItemIndex && !this.editMode) {
			//console.log("Processing waitForCheck, delaying for " + delayInMillis + " ms, index: " + index + ", current item index: " + module.transient.currentItemIndex)
			setTimeout(() => { 
				//console.log("Proceed to next step");
				module.transient.currentItemIndex++
			}, delayInMillis);
		}
	},
	/*initSlider() {
		//console.log("Initializing noUI-Slider...");
		noUiSlider.create(this.$refs.slider, {
			start: 500,
			connect: [true, false],
			direction: 'ltr',
			step: 50,
			tooltips: true,
			orientation: 'horizontal',
			range: {
				min: 300,
				max: 2000
			}/*,
			ariaFormat: wNumb({
				decimals: 0
			}),
			format: wNumb({
				decimals: 0,
				prefix: '',
				postfix: ' €',
				thousand: '.'
			})*/
		/*}); 
	},*/
	processAnswer(module, step, question, answer, confirm) {
		//console.log("Entering processAnswer, current step " + step + ", question: " + question + ", answer: " + answer + ", confirm: " + confirm);
		
		//this.step = currentStep+1;
		var qa = { step: step, question: question, answer: answer };
		//newObj[step] = value;
		this.answers.push(qa);
		
		//console.log("Answers: " + JSON.stringify(this.answers));
		
		if(confirm) {
			if(this.project.enableConfirmationPage) {
				// show confirmation page
				this.switchPage(1)
			} else {
				this.makeToast('Fehler', 'Confirmation Page ist in den Projekteinstellungen nicht aktiviert!!', false, 'danger', true)
			}
		} else {
			module.transient.currentItemIndex++;
		}
		
		/*if(this.step == 6) {
			let that = this;
			 setTimeout(() => { 
				//console.log("Collect formdata now...");
				that.processStep(6,'STEP6','OK');
			 }, 6000);
		}*/
	},
	getDisplayType(key) {
		let value
		for(var displayType of this.settingsConfig.item.display.settings.displayType.allowedValues) {
			//console.log("Processing display type " + displayType.key)
			if(key == displayType.key) {
				value = displayType.value
				break;
			}
		}
		return value
	},
	getConfigObj(target,settingsObj) {
		//console.log('Entering getConfigObj for target ' + target + ' and settingsObj ' + JSON.stringify(settingsObj))
		if(typeof this.settingsConfig !== 'undefined') {
			if((this.settingsConfig[settingsObj.type] && this.settingsConfig[settingsObj.type][target])  || (this.settingsConfig[settingsObj.identifier] && this.settingsConfig[settingsObj.identifier][target])) {
				if(this.settingsConfig[settingsObj.type] && this.settingsConfig[settingsObj.type][target]) {
					return this.settingsConfig[settingsObj.type][target]
				} else if(this.settingsConfig[settingsObj.identifier] && this.settingsConfig[settingsObj.identifier][target]) {
					return this.settingsConfig[settingsObj.identifier][target]
				}
			} else {
				return
			}
		} else {
			return
		}
	},
	/* methods for attachment store */
	onFileAdded(uploadControl, file) {
		//console.log('Entering onFileAdded for uploadControl ' + JSON.stringify(uploadControl) + ' for file ' + JSON.stringify(file))
		const that = this
		setTimeout(() => {
			that.$refs[uploadControl.uuid + '-attachment-dropzone'][0].removeFile(file)
		}, 2000)
	},
	onThumbnailGenerated (file, dataUrl) {
		//console.log('Entering onThumbnailGenerated for file ' + JSON.stringify(file) + ', dataURL: ' + dataUrl)
		const payload = { uuid: uuidv4(), filename: file.upload.filename, filesize: file.upload.total, dataURL: dataUrl }
		//console.log('Invoke control function with payload  ' + JSON.stringify(payload))
	},
	/* end of methods for attachment store */
	getAllowedValues(allowedValues) {
		if(allowedValues === '@conditions') {
			const values = []
			for(let i=0; i<this.project.conditions.length; i++) {
				let condition = this.project.conditions[i]
				values.push({key: condition.name, value: condition.name})
			}
			return values
		} else {
			return allowedValues
		}
	},
	/* target may be a project, layout, page, module, dataset, element or control */
	addAttribute(target) {
		target.attributes.push({ id: 'newAttribute', value: 'newValue', type: 'attribute' })
	},
	addMetaTag(target) {
		target.metadata.push({ property: 'og:newMeta', name: 'newMeta', content: 'new content', type: 'metatag' })
	},
	addCondition() {
		this.project.conditions.push({ name: 'newCondition', type: 'condition' })
	},
	/*getColorClass(variant) {
		// console.log('Entering getColorClass for variant ' + variant)
		return this[variant]
	},*/
	invokeControlFunction (controlFunction, payload, successCallback, errorCallback, fallbackCallback) {
		if (typeof LIP !== 'undefined' && LIP.ffw !== 'undefined') {
			LIP.ffw.invokeControlFunction(this.$root.icPath, controlFunction, payload, {
				success: function (responseText) {
					if(typeof successCallback !== 'undefined' && typeof successCallback === 'function') {
						successCallback(responseText)
					}
				},
				error: function (statusCode, responseText) {
					if(typeof errorCallback !== 'undefined' && typeof errorCallback === 'function') {
						errorCallback(statusCode, responseText)
					}
				}
			})
		} else {
			if(typeof fallbackCallback !== 'undefined' && typeof fallbackCallback === 'function') {
				fallbackCallback()
			}
		}
	},
	initConfiguration (onConfigLoaded) {
		const that = this
		
		// when there is no connection load configuration from local JS file
		/*setTimeout(() => {
			that.editorConfig = FormEditorConfig.getEditorConfig()
			that.settingsConfig = that.editorConfig.settingsConfig
			that.itemConfig = that.editorConfig.itemConfig
			that.project.version = that.editorConfig.version
		}, 500)*/
		
		let payload = { action: "getConfig", payload: "" }
		
		this.invokeAction('getConfig',payload,true,
			function(data) { 
				//console.log('Entering onSuccess callback for getConfig...')
				that.editorConfig = data.DATA
				that.settingsConfig = that.editorConfig.settingsConfig
				that.itemConfig = that.editorConfig.itemConfig
				if(typeof onConfigLoaded != 'undefined' && typeof onConfigLoaded == 'function') {
					onConfigLoaded();
				}
			},function() {
				//console.log('Entering onError callback...')
			}
		)
	},
	initMediaLibrary () {
		const that = this
		that.mediaLibrary = SampleData.getMediaLibrary()
		that.imageCache = that.mediaLibrary.images || {}	
	},
	resolveValue(obj,funcString) {
		// console.log('Entering resolveValue for object: ' + JSON.stringify(obj) + ' and function string ' + funcString)
		var func = funcString.split('fnc:')
		if(func.length === 2) {
			// console.log("function string resolved to " + func[1] + ', type: ' +  (typeof this[func[1]]))
			if(typeof this[func[1]] !== 'undefined' && typeof this[func[1]] === 'function') {
				// console.log('Execute resolved function ' + func[1])
				this[func[1]](obj)
			} else {
				//console.log(func[1] + ' is not a function, failed to resolve function string!')
			}
		}
	},
    toggleMediaLibrary () {
      if (this.mode === 'editor') {
        this.mode = 'media'
        this.showHelpTexts = false
        this.showRefLines = false
        this.showModuleExplorer = false
        this.showObjectIDs = false
        this.showPageEditorHeader = false
        this.showPageEditorFooter = false
        this.showSettingsExplorer = false
      } else {
        this.mode = 'editor'
        this.showModuleExplorer = true
        this.showRefLines = true
        this.showPageEditorHeader = true
        this.showPageEditorFooter = true
      }
    },
    checkConnection (onConnected) {
		//console.log('checkConnection...')
		const that = this
		
		let payload = { action: "getConnectionStatus", payload: "" }
		
		this.invokeAction('getConnectionStatus',payload,true,
			function(data) { 
				//console.log('Entering onSuccess callback for getConnectionStatus...')
				that.connected = true
				that.connectionStatus = data.DATA
				
				if(typeof onConnected != 'undefined' && typeof onConnected == 'function') {
					onConnected()
				}
			},function() {
				//console.log('Entering onError callback...')
			}
		)
    },
	getSavedProjects () {
		//console.log('Entering getSavedProjects()...')
		let projArr = []
		if (localStorage.getItem('Projekte') != null) {
			const projects = JSON.parse(localStorage.getItem('Projekte'))
			for (var project in projects) {
				//console.log('Processing project ' + project)
				projArr.push({ name: project, id: projects[project].id, title: projects[project].title, type: 'savedproject', uuid: projects[project].uuid })
			}
		}

		let payload = { action: "getProjects", payload: "", projectid: "" }
		// Load saved projects from server
		this.invokeAction('getProjects',payload,true,
			function(data) { 
				//console.log('Entering onSuccess callback for getProjects...')
				let projects = data.DATA
				for (let project of Object.values(projects)) {
					//console.log('Processing project ' + project.id)
					projArr.push({ name: project.name, id: project.id, title: project.title, type: project.type, storagelocation: project.storagelocation, uuid: project.uuid })
				}
			},function() {
				//console.log('Entering onError callback for getProjects...')
			}
		)
		this.savedProjects = projArr
	},
    triggerDownload (props) {
      // console.log("Entering triggerDownload with properties " + JSON.stringify(props));
      if (props.mode === 'popup') {
        // console.log("Try to open popup...");
        // Popup Window öffnen
        var childWindow = window.open(props.URL, props.popupOptions.name, 'width=' + props.popupOptions.width + ',height=' + props.popupOptions.height + ',resizable=yes,dependent=yes,hotkeys=no,locationbar=no,toolbar=no' + ',status=no,scrollbars=yes,menubar=no,directories=no')
        childWindow.opener = self
      } else if (props.mode === 'embedding') {
        this.embeddings = props.embeddings
      } else if (props.mode === 'forcedownload') {
        // console.log("Try to trigger direct download via iFrame...");
        var iframe = document.createElement('iframe')
        iframe.src = props.URL
        iframe.style.display = 'none'
        document.body.appendChild(iframe)
      }
    },
    loadTextFromFile (ev) {
      const file = ev.target.files[0]
      const reader = new FileReader()
      reader.onload = e => this.$emit('load', e.target.result)
      reader.readAsText(file)
    },
    getDatasetElements (control) {
      // console.log('Load dataset elements for ' + control.type + ' with uuid ' + control.uuid)
      const dataset = this.getFromCache(control.settings.binding.dataset)
      return dataset.elements
    },
    resolveFromUuid (uuid, key) {
      const obj = this.getFromCache(uuid)
      return obj[key]
    },
	initProject() {
		console.log("Entering initProject...")
						
		this.project = Object.assign({}, {
			id: 'project',
			name: null,
			version: this.editorConfig.version,
			title: 'Projekt',
			template: 'neukunden',
			author: this.$store.getters.getUser.username,
			uuid: uuidv4(),
			initialized: false,
			conditions: [],
			attachments: [],
			attributes: [],
			enableConfirmationPage: true,
			saveLeads: false,
			metadata: [],
			htmlheader: '',
			cookieconsent: {
				enabled: true,
				message: 'Wir verwenden Cookies auf unserer Website, um Ihnen die bestmöglichste Erfahrung zu bieten. Durch das Klicken auf Alle Akzeptieren stimmen Sie der Verwendung aller Cookies zu.',
				accept: 'Alle akzeptieren',
				deny: 'Alle ablehnen',
				link: 'https://novolytics.de/datenschutz/',
				linktext: 'Mehr Informationen',
				policy: 'Cookie Einstellungen'
				
			},
			tracking: {
				google: {
					includeOnLandingPage: false,
					includeOnConfirmationPage: false,
					code: '',
					requiresOptIn: false
				},
				facebook: {
					includeOnLandingPage: false,
					includeOnConfirmationPage: false,
					code: '',
					requiresOptIn: false
				}
			},
			settings: { 
				layout: {
					colors: {
						primaryColor: (this.settingsConfig && this.settingsConfig.project && this.settingsConfig.project.layout && this.settingsConfig.project.layout.colors && this.settingsConfig.project.layout.colors.settings && this.settingsConfig.project.layout.colors.settings.primaryColor && this.settingsConfig.project.layout.colors.settings.primaryColor.defaultValue) ? this.settingsConfig.project.layout.colors.settings.primaryColor.defaultValue : '#3da5d9',
						secondaryColor: (this.settingsConfig && this.settingsConfig.project && this.settingsConfig.project.layout && this.settingsConfig.project.layout.colors && this.settingsConfig.project.layout.colors.settings && this.settingsConfig.project.layout.colors.settings.secondaryColor && this.settingsConfig.project.layout.colors.settings.secondaryColor.defaultValue) ? this.settingsConfig.project.layout.colors.settings.secondaryColor.defaultValue : '#000000',
						customColor1: (this.settingsConfig && this.settingsConfig.project && this.settingsConfig.project.layout && this.settingsConfig.project.layout.colors && this.settingsConfig.project.layout.colors.settings && this.settingsConfig.project.layout.colors.settings.customColor1 && this.settingsConfig.project.layout.colors.settings.customColor1.defaultValue) ? this.settingsConfig.project.layout.colors.settings.customColor1.defaultValue : '#666666',
						customColor2: (this.settingsConfig && this.settingsConfig.project && this.settingsConfig.project.layout && this.settingsConfig.project.layout.colors && this.settingsConfig.project.layout.colors.settings && this.settingsConfig.project.layout.colors.settings.customColor2 && this.settingsConfig.project.layout.colors.settings.customColor2.defaultValue) ? this.settingsConfig.project.layout.colors.settings.customColor2.defaultValue : '#FF0000',
						bgColor: (this.settingsConfig && this.settingsConfig.project && this.settingsConfig.project.layout && this.settingsConfig.project.layout.colors && this.settingsConfig.project.layout.colors.settings && this.settingsConfig.project.layout.colors.settings.bgColor && this.settingsConfig.project.layout.colors.settings.bgColor.defaultValue) ? this.settingsConfig.project.layout.colors.settings.bgColor.defaultValue : '#FFFFFF'
					}
				}
			},
			layouts: [],
		})
	},
    newProject () {
		this.initProject()
		
		this.idCounter = {
			page: 0,
			module: 0,
			item: 0,
			subitem: 0
		}
		
		this.uuidCache = {}
		this.datasetCache = {}
		this.modelCache = {}
		this.pageIndex = null
		this.currentPage = null
		this.currentLayout = null
		this.openProjectSettings('general')
    },
    saveProjectAs (bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()

      const valid = this.$refs.save_project.checkValidity()
      this.projectnameState = valid

      if (!valid) {
        return
      }

		// Hide the modal manually
		const that = this
		this.saveProject(function() {
			// only close dialog if saving was successful
			that.$bvModal.hide('saveprojectdialog')
		})
    },
    saveProject (onSaved) {
		let name = this.project.id
		if(this.saveProjectOnServer) {
			//console.log('Saving project on server...')
			let that = this
			let payload = { action: "createProject", payload: JSON.stringify(this.project), projectid: this.project.id, overwrite: this.overwriteExistingProject }
			this.invokeAction('createProject',payload,true,
				function(data) { 
					that.unsavedChanges = false
					that.makeToast('Projekt gespeichert', 'Das Projekt wurde unter dem Namen "' + name + '" gespeichert', false, 'success', true)
					if(typeof onSaved != 'undefined' && typeof onSaved == 'function') {
						onSaved()
					}
				},function() {
					//console.log('Entering onError callback for getProjects...')
				}
			)
		} else {
			const projects = JSON.parse(localStorage.getItem('Projekte'))
			if (this.project.name !== null) { name = this.project.name }
			if (projects == null) {
				const projects = {}
				projects[name] = this.project
				localStorage.setItem('Projekte', JSON.stringify(projects))
			} else {
				if (typeof projects[name] !== 'undefined') {
					if (projects[name].uuid !== this.project.uuid) {
						this.makeToast('Name bereits vorhanden', 'Ein anderes Projekt ist bereits unter dem Namen "' + name + '" gespeichert!', false, 'danger', true)
						return false
					}
				} else {
					projects[name] = this.project
				}
				localStorage.setItem('Projekte', JSON.stringify(projects))
			}
		}
		return true
    },
    initCaches () {
      for (var layoutindex in this.project.layouts) {
        const layout = this.project.layouts[layoutindex]
        // console.log('Processing layout: ' + layout.uuid)
        this.addToCache(layout)
        for (var pageindex in layout.pages) {
          var page = layout.pages[pageindex]
          // console.log('Processing page: ' + page.uuid)
          this.addToCache(page)
          for (var moduleindex in page.modules) {
            var module = page.modules[moduleindex]
            // console.log('Processing module: ' + module.uuid)
            this.addToCache(module)
          }
        }
      }
    },
    deleteProject (rowItem, rowIndex, ev) {
		console.log('Delete item ' + JSON.stringify(rowItem) + ' at index ' + rowIndex)
		rowItem.index = rowIndex
		this.confirmDelete(rowItem)
    },
	resetCaches() {
		this.idCounter = {
			page: 0,
			module: 0,
			item: 0,
			subitem: 0
		}
		this.uuidCache = {}
		this.datasetCache = {}
		this.modelCache = {}
		this.pageIndex = 0
		this.currentPage = this.project.layouts[0].pages[0]
		this.currentLayout = this.project.layouts[0]
		this.initCaches()
		this.project.initialized = true
	},
    loadProject (rowItem/*, rowIndex, ev*/) {
		if(rowItem.storagelocation == 'server') {
			//console.log('Loading project ' + rowItem.id + ' from server...')
			let that = this
			let payload = { action: "loadProject", payload: "", projectid: rowItem.id }
			this.invokeAction('loadProject',payload,true,
			function(data) { 
				console.log("Tool-Version: " + that.editorConfig.version + ", Project-Version: " + data.DATA.version)
				if(that.editorConfig.version != data.DATA.version) {
					that.tempProject = Object.assign({}, data.DATA)
					that.$bvModal.show('migrateprojectdialog')
				} else {
					that.project = Object.assign({}, data.DATA)
					that.unsavedChanges = false
					that.resetCaches()
				}
			},function() {
				//console.log('Entering onError callback for getProjects...')
			}
		)
		
		} else {
			const projects = JSON.parse(localStorage.getItem('Projekte'))
			if (projects !== null) {
				// console.log('Loading project ' + rowItem.name)
				projects[rowItem.name].initialized = false
				// console.log('project: ' + JSON.stringify(projects[rowItem.name]))
				this.project = Object.assign({}, projects[rowItem.name])
				this.unsavedChanges = false
				this.resetCaches();
			}
		}
    },
    hideSettings () {
		//console.log('Entering hideSettings...')
      this.settingsObj = {}
    },
	editAttribute(rowItem, rowIndex/*, event*/) {
		this.refAttributeEditItem = { item: rowItem, rowindex: rowIndex }
	},
	editMetaTag(rowItem, rowIndex/*, event*/) {
		this.refMetaTagEditItem = { item: rowItem, rowindex: rowIndex }
	},
    clicked (obj, prop) {
      return new Promise((resolve/*, reject*/) => {
        if (this.click) {
          this.editing = { property: prop, uuid: obj.uuid }
          clearTimeout(this.click)
          resolve(obj.uuid)
        }
        this.click = setTimeout(() => {
          this.click = undefined
          resolve(null)
        }, 200)
      })
    },
    pageTabClicked (page) {
      //console.log('Page Tab clicked ' + page.title)
    },
    formatId (value) {
      return value.toUpperCase().replaceAll(' ', '_')
    },
	/*updateFormData(item,subitem,value) {
		//console.log("Entering updateFormData, field id: " + subitem.settings.display.fieldid + ", value: " + value)
		//console.log("Form-Data: " + JSON.stringify(item.transient.formdata))
		this.$set(item.transient.formdata, subitem.settings.display.fieldid, value)
		//console.log("Form-Data after change: " + JSON.stringify(item.transient.formdata))
	},*/
	inputChanged (inputValue, obj, property) {
		this.unsavedChanges = true
		// console.log('Entering inputChanged with value ' + inputValue + ' for object ' + JSON.stringify(obj) + ' and property ' + property)
		/*if ((obj.type === 'page' || obj.type === 'overviewpage')) {
			if(property === 'title') {
				obj.id = this.formatId(inputValue)
			} 
		}*/
    },
    alignSettingsExplorer (alignment) {
      this.settingsExplorerAlign = alignment
    },
    openObjectSettings (obj) {
		// console.log('Entering openObjectSettings, obj: ' + JSON.stringify(obj))
		this.settingsObj = obj
		this.showSettingsExplorer = true
		/*this.$nextTick(function () {
			this.settingsTabIndex = 1
		})*/
    },
	openMediaLibrarySettings () {
		this.openObjectSettings(this.editorConfig.mediaExplorerConfig)
	},
    openPageSettings (page) {
      this.openObjectSettings(page)
    },
    canMoveLeftOrUp (parent, child, childrenkey) {
		let flag = false
		let childindex = parent[childrenkey].indexOf(child)
		if(child.type == 'module' && child.settings.display.placement == 'body') {
			if(parent[childrenkey].length > 1 && childindex > 0 && parent[childrenkey][childindex-1].settings.display.placement == 'body') {
				flag = true
			}
		} else if(child.type != 'module') {
			if(parent[childrenkey].length > 1 && childindex > 0) {
				flag = true
			}
		}
		return flag
    },
    canMoveRightOrDown (parent, child, childrenkey) {
		let flag = false
		let childindex = parent[childrenkey].indexOf(child)
		if(child.type == 'module' && child.settings.display.placement == 'body') {
			if(parent[childrenkey].length > 1 && (childindex < parent[childrenkey].length - 1) && parent[childrenkey][childindex+1].settings.display.placement == 'body') {
				flag = true
			}
		} else if(child.type != 'module') {
			if(parent[childrenkey].length > 1 && (childindex < parent[childrenkey].length - 1)) {
				flag = true
			}
		}
		return flag
    },
    handleHover (hovered, obj) {
      if (hovered) {
        this.hovering = obj.uuid
        // console.log('Hovering over object ' + obj.type + ' with uuid ' + obj.uuid + ', hovering: ' + this.hovering)
      } else {
        // console.log('Hovering out of object ' + obj.type + ' with uuid ' + obj.uuid)
        this.hovering = null
      }
    },
    getNextIdCount (parent, childType) {
      this.idCounter[childType] += 1
      return this.idCounter[childType]
    },
    getParent (obj) {
      if (obj.type === 'layout' || obj.type === 'dataset') {
        return this.project
      } else {
        return this.getFromCache(obj.parentUuid)
      }
    },
    getFromUuidCache (uuid) {
      return this.uuidCache[uuid]
    },
    addToUuidCache (obj) {
      this.uuidCache[obj.uuid] = obj
    },
    removeFromUuidCache (uuid) {
      delete this.uuidCache[uuid]
    },
    getFromCache (uuid) {
      return this.getFromUuidCache(uuid)
    },
    addToCache (obj) {
      this.addToUuidCache(obj)
    },
    removeFromCache (uuid) {
      this.removeFromUuidCache(uuid)
    },
    addAndSwitchPage () {
      this.currentPage = this.addPage(this.currentLayout)
    },
    saveToolSettings () {
    },
    saveProjectSettings () {
      if (this.project.initialized === false) {
        this.currentLayout = this.addLayout('input')
        let mainPage = this.addPage(this.currentLayout,'Landing Page','landingpage')
        let confirmationPage = this.addPage(this.currentLayout,'Confirmation','confirmation')
		this.currentPage = mainPage
        this.project.initialized = true
		this.pageIndex = 0
      }
    },
    openProjectSettings (tab) {
      this.projectsettingsTab = tab
      this.$bvModal.show('projectsettings')
    },
    openToolSettings () {
      this.$bvModal.show('toolsettings')
    },
    handleDragOver (obj, data, event) {
      // console.log('Entering handleDragOver with with data ' + JSON.stringify(data) + ' on target ' + JSON.stringify(obj))
      // console.log('obj type: ' + obj.type + ', data obj type: ' + data.obj.type)
      if (obj.type !== data.obj.droptarget && obj.type !== data.obj.type) {
        event.dataTransfer.dropEffect = 'none'
        event.stopPropagation()
      } else {
        this.over = obj.uuid
        event.stopPropagation()
        // event.dataTransfer.dropEffect = 'copy'
      }
    },
	movePageLeft(page, index) {
		this.moveArrayItem(this.currentLayout.pages, page, 'left')
	},
	movePageRight(page, index) {
		this.moveArrayItem(this.currentLayout.pages, page, 'right')
	},
    moveArrayItem (arr, item, direction) {
      this.unsavedChanges = true
      if (direction === 'left') {
        const idx = arr.indexOf(item)
        if (idx !== -1 && idx > 0) {
          const spliced = arr.splice(idx, 1)
          if (typeof spliced !== 'undefined') {
            arr.splice(idx - 1, 0, spliced[0])
          }
        }
      } else if (direction === 'right') {
        const idx = arr.indexOf(item)
        if (idx !== -1 && idx < (arr.length - 1)) {
          const spliced = arr.splice(idx, 1)
          if (typeof spliced !== 'undefined') {
            arr.splice(idx + 1, 0, spliced[0])
          }
        }
      }
    },
    editAction (page, module, item, subitem, action) {
      // console.log('Entering editAction ' + action)
		if (action === 'moveSubItemUp') {
			this.moveArrayItem(item.subitems, subitem, 'left')
		} else if (action === 'moveSubItemDown') {
			this.moveArrayItem(item.subitems, subitem, 'right')
		} if (action === 'moveItemUp') {
			this.moveArrayItem(module.items, item, 'left')
		} else if (action === 'moveItemDown') {
			this.moveArrayItem(module.items, item, 'right')
		} if (action === 'moveModuleUp') {
			// const page = this.getParent(module)
			this.moveArrayItem(this.currentPage.modules, module, 'left')
		} else if (action === 'moveModuleDown') {
			// const page = this.getParent(module)
			this.moveArrayItem(this.currentPage.modules, module, 'right')
		} else if (action === 'deleteModule') {
			this.confirmDelete(module)
		} else if (action == 'addItem') {
			let orientation = 'left'
			let displaySettings = {}
			let identifier = ''
			let itemIdentifier = module.identifier.replace("_module","_item"); // testimonial_module becomes testimonial_item
			if(module.identifier == 'timeline_module') {
				if(module.items.length > 0) {
					let lastItem = module.items[module.items.length-1]
					orientation = lastItem.settings.display.orientation == 'left' ? 'right' : 'left'
					displaySettings.orientation = orientation
				}
				// identifier = 'timeline_item'
			}
			this.addItem (itemIdentifier, displaySettings, module)
	} else if (action == 'addSubItem') {
		//console.log("Adding sub item to item " + JSON.stringify(item))
		let displaySettings = {}
		let subItemIdentifier = ''
		//let subItemIdentifier = module.identifier.replace("_item","_subitem"); // question_iten becomes testimonial_item
		if(item.settings.display.displayType == 'answerButtons') {
			subItemIdentifier = 'button_subitem'
			// ToDo: Load default settings for subitem from config
			displaySettings.buttonsize = 'w-75'
		} else if(item.settings.display.displayType == 'contactForm') {
			subItemIdentifier = 'text_subitem'
		}
		// console.log("sub item identifier: " + subItemIdentifier)
		this.addSubItem (subItemIdentifier, displaySettings, item)
	}
    },
    pageSelected (pageIndex) {
      // console.log('Switch to page at index ' + pageIndex)
      this.currentPage = this.currentLayout.pages[pageIndex]
      if (this.showSettingsExplorer) { this.settingsObj = this.currentPage }
    },
    handleDragstart (data, event) {
      // console.log('Start drag of object ' + JSON.stringify(data.obj))
      this.dragObj = data.obj.type
      event.stopPropagation()
    },
    handleDropOnPage (data, target) {
      // console.log('Entering handleDropOnPage')
      this.handleDrop(data.source, data.obj, target)
    },
    handleDropOnModule (data, target) {
      // console.log('Entering handleDropOnModule')
      if (data.obj.type === 'module' && (data.obj.uuid !== target.uuid)) {
        this.moveObject(data.source, data.obj, target)
      } else {
        this.handleDrop(data.source, data.obj, target)
      }
    },
    handleDrop (source, obj, target) {
      //console.log('Entering drop of obj ' + JSON.stringify(obj) + ' on target of type ' + target.type)
      if (target.type === obj.droptarget || target.type === obj.type) {
        this.unsavedChanges = true
        //console.log('Add ' + obj.type + ' with identifier ' + obj.identifier + ' to ' + target.type + ' with uuid ' + target.uuid)
        if (obj.type == 'module') {
			// console.log(JSON.stringify(obj))
			let children = this.getChildrenByIdentifier(target.modules, obj.identifier)
			//console.log("children: " + JSON.stringify(children))
			if(obj.single && children.length > 0) {
				//console.log('Modules with identifier ' + obj.identifier + ' allowed only once!')
				this.makeToast('Fehler', 'Dieses Modul kann nur einmal zur Seite hinzugefügt werden!!', false, 'danger', true)
			} else {
				this.addModule(obj, {}, target)
			}
        } 
      }
      this.dragObj = null
      this.over = null
    },
	getChildrenByIdentifier (children, childidentifier) {
		let child_arr = children.filter(child => {
			return child.identifier == childidentifier
		})
		return child_arr
	},
    toggleRefLines () {
      this.showRefLines = !this.showRefLines
    },
    toggleHelpTexts () {
      this.showHelpTexts = !this.showHelpTexts
    },
    toggleModuleExplorer () {
      this.showModuleExplorer = !this.showModuleExplorer
    },
    toggleObjectIDs () {
      this.showObjectIDs = !this.showObjectIDs
    },
    /*togglePreview () {
      if (this.mode === 'editor') {
        this.mode = 'preview'
        this.showHelpTexts = false
        this.showRefLines = false
        this.showModuleExplorer = false
        this.showObjectIDs = false
        this.showPageEditorHeader = false
        this.showPageEditorFooter = false
        this.showSettingsExplorer = false
      } else {
        this.mode = 'editor'
        this.showModuleExplorer = true
        this.showRefLines = true
        this.showPageEditorHeader = true
        this.showPageEditorFooter = true
      }
    },*/
	toggleAPIExplorer () {
		if (this.mode === 'editor') {
			this.mode = 'apiexplorer'
			this.showHelpTexts = false
			this.showRefLines = false
			this.showModuleExplorer = false
			this.showObjectIDs = false
			this.showPageEditorHeader = false
			this.showPageEditorFooter = false
			this.showSettingsExplorer = false
		} else {
			this.mode = 'editor'
			this.showModuleExplorer = true
			this.showRefLines = true
			this.showPageEditorHeader = true
			this.showPageEditorFooter = true
		}
    },
    addLayout (id) {
      const newLayout = { type: 'layout', parentUuid: this.project.uuid, uuid: uuidv4(), id: 'input', title: id, pages: [], attributes:[], settings: {} }
      this.project.layouts.push(newLayout)
      this.addToCache(newLayout)
      return newLayout
    },
    addPage (layout, title, id) {
		const nextPageIdCount = this.getNextIdCount(layout, 'page')
		const pageTitle = typeof title != 'undefined' ? title : 'Seite ' + nextPageIdCount
		const pageId = typeof id != 'undefined' ? id : 'Pag' + nextPageIdCount;
		const newPage = { type: 'page', parentUuid: layout.uuid, uuid: uuidv4(), id: pageId, title: pageTitle, modules: [], attributes:[], settings: { } }
		layout.pages.push(newPage)
		this.addToCache(newPage)
		return newPage
    },
	/*addPage (layout) {
      const nextPageIdCount = this.getNextIdCount(layout, 'page')
      const pageTitle = 'Seite ' + nextPageIdCount
      const newPage = { type: 'page', parentUuid: layout.uuid, uuid: uuidv4(), id: 'Pag' + nextPageIdCount, title: pageTitle, segments: [], attributes:[], settings: {} }
      layout.pages.push(newPage)
      this.addToCache(newPage)
      return newPage
    },*/
    getParentLayout (obj) {
      if (obj.type === 'page' || obj.type === 'overviewpage') {
        return this.getParent(obj)
      } else if (obj.type === 'module') {
        const page = this.getParent(obj)
        return this.getParent(page)
      } 
    },
    getParentPage (obj) {
      if (obj.type === 'module') {
        return this.getParent(obj)
      } 
    },
    addModule (data, displaySettings, page) {
		const nextModuleIdCount = this.getNextIdCount(page, 'module')
		const newModule = { type: 'module', identifier: data.identifier, parentUuid: page.uuid, uuid: uuidv4(), id: 'Module' + nextModuleIdCount, items: [], attributes:[], settings: { }, enableTextEditor: data.enableTextEditor, supportsItems: data.supportsItems, transient: { currentItemIndex: 0 } }
		newModule.settings.display = {
		}
		let configObj = this.getConfigObj('display',newModule)
		let settingsConfig = configObj && configObj.settings ? configObj.settings : {}
		
		for (const setting in settingsConfig) {
			if((!settingsConfig[setting].hiddenInTargets && !settingsConfig[setting].visibleInTargets) || (settingsConfig[setting].hiddenInTargets && (!settingsConfig[setting].hiddenInTargets[data.identifier] || settingsConfig[setting].hiddenInTargets[data.identifier] == false )) || (settingsConfig[setting].visibleInTargets && settingsConfig[setting].visibleInTargets[data.identifier] == true)) {
				//console.log("defaultValue type: " + typeof settingsConfig[setting].defaultValue)
				let defaultValue = settingsConfig[setting].defaultValue ? settingsConfig[setting].defaultValue : ''
				if(settingsConfig[setting].specificDefaultValue && typeof settingsConfig[setting].specificDefaultValue == "object" && settingsConfig[setting].specificDefaultValue[data.identifier]) {
					defaultValue = settingsConfig[setting].specificDefaultValue[data.identifier]
				}
				newModule.settings.display[setting] = (displaySettings && displaySettings[setting]) ? displaySettings[setting] : defaultValue
			} 
		}
		
		newModule.editor = {
			editText: false
		}
		
		if(data.supportsItems == true) {
			let itemIdentifier = data.identifier.replace("_module","_item"); // testimonial_module becomes testimonial_item
			if(data.items && data.items.length > 0) {
				for(let i=0; i<data.items.length; i++) {
					this.addItem(itemIdentifier,{}, newModule, data.items[i]);
				}																																								
			} else {
				if(itemIdentifier == 'question_item') {
					this.addItem(itemIdentifier,{ displayType: 'introduction'}, newModule);
				} else {
					this.addItem(itemIdentifier,{}, newModule);
				}
			}
		}
		
		// add header at the beginning
		if(newModule.settings.display.placement == 'header') {
			page.modules.unshift(newModule)
		} else if(page.modules.length == 0) { // if there are no modules yet we can just add the module to the end of our modules
			page.modules.push(newModule)
		} else {
			// add footer at the end or add body module to the end if no footer is present yet
			let lastItem = page.modules[page.modules.length-1]
			if(newModule.settings.display.placement == 'footer' || lastItem.settings.display.placement != 'footer') {
				page.modules.push(newModule)
			} else {
				// last item is the footer module so we need to place the newly added module before the footer
				page.modules.splice(page.modules.length-1, 0, newModule)
			}
		}
		this.addToCache(newModule)
		
		return newModule
    },
	addItem (identifier, displaySettings, target, item) {
		//console.log("XXX Adding item with identifier " + identifier + " to target with identifier " + target.identifier + ", displaySettings: " + JSON.stringify(displaySettings) + ", item: " + JSON.stringify(item))
		const nextItemIdCount = this.getNextIdCount(target, 'item')
		let newItem
		if(item) {
			newItem = item
			newItem.id = 'Item' + nextItemIdCount
			newItem.identifier = identifier
			newItem.parentUuid = target.uuid 
			newItem.uuid = uuidv4()
		} else {
			newItem = { type: 'item', id: 'Item' + nextItemIdCount, identifier: identifier, parentUuid: target.uuid, uuid: uuidv4(), attributes: [], settings: { }  }
			
			newItem.settings.display = {}
			
			let configObj = this.getConfigObj('display',newItem)
			let settingsConfig = configObj && configObj.settings ? configObj.settings : {}
			
			//console.log("XXX Settings config: " + JSON.stringify(settingsConfig))
			
			for (const setting in settingsConfig) {
				//console.log("XXX Processing setting: " + setting + ", details: " + JSON.stringify(settingsConfig[setting]))
				if((!settingsConfig[setting].hiddenInTargets && !settingsConfig[setting].visibleInTargets) || (settingsConfig[setting].hiddenInTargets && (!settingsConfig[setting].hiddenInTargets[identifier] || settingsConfig[setting].hiddenInTargets[identifier] == false )) || (settingsConfig[setting].visibleInTargets && settingsConfig[setting].visibleInTargets[identifier] == true)) {
					let defaultValue = settingsConfig[setting].defaultValue ? settingsConfig[setting].defaultValue : ''
					if(settingsConfig[setting].specificDefaultValue && typeof settingsConfig[setting].specificDefaultValue == "object" && settingsConfig[setting].specificDefaultValue[identifier]) {
						defaultValue = settingsConfig[setting].specificDefaultValue[identifier]
					}
					newItem.settings.display[setting] = (displaySettings && displaySettings[setting]) ? displaySettings[setting] : defaultValue
				} 
			}
		}
		
		if(this.itemConfig.items[newItem.identifier].supportsSubItems) {
			newItem.transient = { currentSubItemIndex: 0 }
			if(newItem.settings.display.displayType == 'contactForm') {
				newItem.transient.formdata = {}
			}
			if(newItem.subitems && newItem.subitems.length > 0) {
				//console.log("XXX Processing subitems " + JSON.stringify(newItem.subitems))
				for(let x=0; x<newItem.subitems.length; x++) {
					//console.log("XXX Processing subitem " + JSON.stringify(newItem.subitems[x]))
					// Update subitem info with missing properties
					let nextSubItemIdCount = this.getNextIdCount(newItem, 'subitem')
					newItem.subitems[x].id = 'SubItem' + nextSubItemIdCount
					newItem.subitems[x].parentUuid = newItem.uuid 
					newItem.subitems[x].uuid = uuidv4()
				}																																							
			} else {
				newItem.subitems = []
			}
		}
		
		// console.log("XXX Adding item with identifier " + identifier + " to target " + target.type + ", display settings: " + JSON.stringify(newItem))
		
		target.items.push(newItem)
		this.addToCache(newItem)
		return newItem
    },
	addSubItem (identifier, displaySettings, target, subitem) {
		//console.log('XXX Adding subitem with identifier ' + identifier);
		const nextSubItemIdCount = this.getNextIdCount(target, 'subitem')
		let newSubItem
		if(subitem) {
			newSubItem = subitem
			newSubItem.id = 'SubItem' + nextSubItemIdCount
			newSubItem.identifier = identifier
			newSubItem.parentUuid = target.uuid 
			newSubItem.uuid = uuidv4()
		} else {
			newSubItem = { type: 'subitem', identifier: identifier, parentUuid: target.uuid, uuid: uuidv4(), attributes: [], settings: { }  }
			
			newSubItem.settings.display = {}
			
			let configObj = this.getConfigObj('display',newSubItem)
			let settingsConfig = configObj && configObj.settings ? configObj.settings : {}
			
			for (const setting in settingsConfig) {
				if((!settingsConfig[setting].hiddenInTargets && !settingsConfig[setting].visibleInTargets) || (settingsConfig[setting].hiddenInTargets && (!settingsConfig[setting].hiddenInTargets[identifier] || settingsConfig[setting].hiddenInTargets[identifier] == false )) || (settingsConfig[setting].visibleInTargets && settingsConfig[setting].visibleInTargets[identifier] == true)) {
					let defaultValue = settingsConfig[setting].defaultValue ? settingsConfig[setting].defaultValue : ''
					if(settingsConfig[setting].specificDefaultValue && typeof settingsConfig[setting].specificDefaultValue == "object" && settingsConfig[setting].specificDefaultValue[identifier]) {
						defaultValue = settingsConfig[setting].specificDefaultValue[identifier]
					}
					newSubItem.settings.display[setting] = (displaySettings && displaySettings[setting]) ? displaySettings[setting] : defaultValue
				} 
			}
		}
		
		//console.log("Adding subitem with identifier " + identifier + " to target " + target.type + ", display settings: " + JSON.stringify(newSubItem))
		
		target.subitems.push(newSubItem)
		this.addToCache(newSubItem)
		return newSubItem
    },
    moveObject (source, obj, target) {
      const arr = this.getFromCache(source.uuid)
      const item = this.getFromCache(obj.uuid)
      const targetParent = this.getFromCache(target.parentUuid)
      // console.log('Source: ' + JSON.stringify(arr))
      // console.log('Obj: ' + JSON.stringify(item))
      const childrenKey = obj.type + 's' // e.g. columns, rows, modules
      const idx = arr[childrenKey].indexOf(item)
      if (idx !== -1) {
        // console.log('Moving obj ' + obj.uuid + ', children key: ' + childrenKey)
        const spliced = arr[childrenKey].splice(idx, 1)
        if (typeof spliced !== 'undefined') {
          const targetIdx = targetParent[childrenKey].indexOf(target)
          if (targetIdx !== -1) {
            // console.log('Index: ' + idx + ', targetIdx: ' + targetIdx)
            if (item.parentUuid === target.parentUuid) {
              if (idx > targetIdx) {
                // console.log('Moving ' + obj.type + '/' + obj.uuid + ' within same parent ' + source.type + '/' + source.uuid + ', target uuid is ' + target.uuid + ', moving to target index ' + targetIdx)
                targetParent[childrenKey].splice(targetIdx, 0, spliced[0])
              } else {
                // console.log('Moving ' + obj.type + '/' + obj.uuid + ' within same parent ' + source.type + '/' + source.uuid + ', target uuid is ' + target.uuid + ', moving to target index ' + (targetIdx + 1))
                targetParent[childrenKey].splice(targetIdx + 1, 0, spliced[0])
              }
            } else {
              // console.log('Moving ' + obj.type + '/' + obj.uuid + ' to other parent ' + source.type + '/' + source.uuid + ', target uuid is ' + target.uuid + ', moving to target index ' + (targetIdx + 1))
              targetParent[childrenKey].splice(targetIdx, 0, spliced[0])
              spliced[0].parentUuid = targetParent.uuid
            }
          }
        }
      }
    },
    confirmDelete (obj, sourceObj) {
      const msgTitle = '"' + (obj.title || obj.name || obj.type) + '" löschen?'
      const msgTxt = 'Soll "' + (obj.title || obj.name || obj.type) + '" wirklich gelöscht werden?'
      this.$bvModal.msgBoxConfirm(msgTxt, {
        title: msgTitle,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Ja',
        cancelTitle: 'Nein',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: true
      }).then(value => {
        // console.log('confirmation returned ' + value)
        if (value === true) { 
			if(obj.type == 'savedproject') {
				if(obj.storagelocation == 'server') {
					this.deleteProjectOnServer(obj);
				} else {
					console.log('Deleting from local storage...');
				}
			} else {
				this.deleteObject(obj, sourceObj) 
			}
		}
      }).catch(err => {
        //console.log('An error occured ' + err)
      })
    },
	deleteObject (obj, sourceObj) {
		//console.log('Entering deleteObject for object ' + JSON.stringify(obj))
		const uuid = obj.uuid
		let deleted = false
		if(obj.type == 'metatag') {
			const idx = sourceObj.metadata.indexOf(obj)
			if (idx !== -1) {
				sourceObj.metadata.splice(idx, 1)
				deleted = true
			}
		} else if(obj.type == 'attribute') {
			const idx = sourceObj.attributes.indexOf(obj)
			if (idx !== -1) {
				sourceObj.attributes.splice(idx, 1)
				deleted = true
			}
		} else if (obj.type == 'layout') {
			const idx = this.project.layouts.indexOf(obj)
			if (idx !== -1) {
				this.project.layouts.splice(idx, 1)
				deleted = true
			}
		} else if (obj.type == 'page') {
			const layout = this.getParent(obj)
			const idx = layout.pages.indexOf(obj)
			if (idx !== -1) {
				layout.pages.splice(idx, 1)
				deleted = true
			}
		} else if (obj.type == 'module') {
			const page = this.getParent(obj)
			const idx = page.modules.indexOf(obj)
			if (idx !== -1) {
				page.modules.splice(idx, 1)
				deleted = true
			}
		} else if (obj.type == 'item') {
			const module = this.getParent(obj)
			const idx = module.items.indexOf(obj)
			if (idx !== -1) {
				module.items.splice(idx, 1)
				deleted = true
			}
		} else if (obj.type == 'subitem') {
			const item = this.getParent(obj)
			//console.log("Parent of subitem: " + JSON.stringify(item))
			const idx = item.subitems.indexOf(obj)
			if (idx !== -1) {
				item.subitems.splice(idx, 1)
				deleted = true
			}
		} else if (obj.type == 'savedproject') {
			if (localStorage.getItem('Projekte') != null) {
				const projects = JSON.parse(localStorage.getItem('Projekte'))
				const projArr = []
				for (var project in projects) {
					//console.log('Processing project ' + project + ' object to be deleted: ' + obj.name)
					if (project === obj.name) {
						delete projects[project]
					} else {
						projArr.push({ name: project, id: projects[project].id, title: projects[project].title, type: 'savedproject', uuid: projects[project].uuid })
					}
				}
				//console.log(JSON.stringify(projects))
				localStorage.setItem('Projekte', JSON.stringify(projects))
				this.savedProjects = projArr
			}
		}
		if (deleted) {
			if (obj.type !== 'savedproject') {
				this.unsavedChanges = true
				this.removeFromCache(uuid)
			}
			this.makeToast('Objekt gelöscht', '"' + (obj.title) + '" wurde gelöscht.', false, 'success', true)
		}
    },
	makeToast (title = '', txt = '', append = false, variant = null, solid = true) {
		let toastMsg = { caption: title, msg: txt, msgDetail: '', append: append, variant: variant, solid: solid }
		EventBus.$emit('makeToast', toastMsg)
	},
	registerEvents() {
		EventBus.$on('updateHTML', (markup, targetObj) => {
				// console.log('Entering event updateHTML with markup ' + markup + " for target object " + JSON.stringify(targetObj))
				targetObj.settings.display.text = markup
		})
		EventBus.$on('dismissEmbedding', (index, type) => {
			// console.log('Entering event dismissEmbedding for embedding of type ' + type + ' at index ' + index)
			if (type === 'top') {
			this.embeddings.splice(index, 1)
		} /* else if(type === 'list') {
			this.list_embeddings.splice(index, 1)
		} */
		})
		EventBus.$on('switchPage', (pageIndex) => {
			//console.log('Entering event switchPage, swithcing to page at index ' + pageIndex)
			this.switchPage(pageIndex)
		})
		EventBus.$on('objectAction', (menuItem, obj) => {
			// console.log('Entering event objectAction for menuItem ' + JSON.stringify(menuItem) + ', and object ' + JSON.stringify(obj))
			if (menuItem.code === 'DELETE') {
			this.confirmDelete(this.getFromCache(obj.uuid))
			} else if (menuItem.code === 'SETTINGS') {
				this.$nextTick(() => {
					this.openObjectSettings(this.getFromCache(obj.uuid))
				})
			}
		})
		EventBus.$on('notifyDragStart', (data) => {
			// console.log('Entering event notifyDragStart with data ' + JSON.stringify(data))
			this.dragObj = data
		})
		EventBus.$on('mediaLibraryEvent', (data) => {
			if (data.event === 'openObjectSettings') {
				this.openObjectSettings(data.obj)
			} else if (data.event === 'openMediaLibrarySettings') {
				this.openMediaLibrarySettings()
			} 
		})
		EventBus.$on('navbarEvent', (ev) => {
			// console.log('Entering event navbarEvent with event ' + ev)
			if (ev === 'openToolSettings') {
				this.openToolSettings()
			} else if (ev === 'save') {
				/*if (this.project.name != null) {
					this.saveProject()
				} else {
					this.$bvModal.show('saveprojectdialog')
				}*/
				this.projectnameState = null
				this.$bvModal.show('saveprojectdialog')
			} else if (ev === 'saveAs') {
				this.$bvModal.show('saveprojectdialog')
			} else if (ev === 'load') {
				this.getSavedProjects()
				this.$bvModal.show('loadproject')
			} else if (ev === 'newProject') {
				this.newProject()
			} else if (ev === 'import') {
				this.$bvModal.show('importproject')
			} else if (ev === 'deploy') {
				this.makeToast('Deployment', 'Deployment des Projektes!', false, 'success', true)
				/*var props = {
					URL: 'https://www.adobe.com/support/products/enterprise/knowledgecenter/media/c4611_sample_explain.pdf',
					mode: 'embedding',
					popupOptions: {
						name: 'Download',
						width: 640,
						height: 480
					},
					embeddings: [{
						id: 'embedding',
						url: 'http://www.google.de',
						text: 'Hier geht es zu Google:',
						variant: 'primary',
						linktext: 'www.google.de'
					}]
				}
				this.triggerDownload(props)*/
			} else if(ev == 'logout') {
				//console.log("Logging out...");
				let payload = { action: "logout", payload: "" }
				let that = this
				this.invokeAction('logout',payload,true,function(data) { 
					//console.log('Entering onSuccess callback, logging out...')
					that.$store.dispatch('logout', {})
					that.$router.push('/Login');
				},function() {
					//console.log('Entering onError callback...')
				})
			}
		})
		EventBus.$on('quickActionEvent', (ev) => {
			// console.log('Entering event quickActionEvent with event ' + ev)
			if (ev === 'openProjectSettings') {
				this.openProjectSettings('general')
			} else if (ev === 'deploy') {
				//console.log('Start deploy...')
			} /*else if (ev === 'preview') {
				this.togglePreview()
			} */else if(ev === 'apiexplorer') {
				this.toggleAPIExplorer();
			} else if (ev === 'showHelp') {
				//console.log('Start help...')
			} else if (ev === 'toggleHelpTexts') {
				this.toggleHelpTexts()
			} else if (ev === 'toggleRefLines') {
				this.toggleRefLines()
			} else if (ev === 'toggleModuleExplorer') {
				this.toggleModuleExplorer()
			} else if (ev === 'toggleObjectIDs') {
				this.toggleObjectIDs()
			} else if (ev === 'showMediaLibrary') {
				this.toggleMediaLibrary()
			}
		})
	}
  },
	async created () {
		//console.log('Entering created in Main.vue...')
		// Check if user is logged in, otherwise redirect to Login Page
		if (!this.$store.getters.isLoggedIn) {
			if(window.location.hostname.endsWith(this.requiredHost)) {
				//console.log("OK, " + window.location.hostname + ", is a registered domain, we may procceed!")
				//console.log("Not yet logged in, redirecting to Login Page...")
				this.$router.push('/Login')
			} else {
				//console.log("The requested domain " + window.location.hostname + " is not licensed!!")
				this.$router.push('/InvalidDomain')
			}
		} else {
			//console.log('Logged in as user: ' + this.$store.getters.getUser.username)
			const that = this
			this.checkConnection(function() {
				console.log('We are connected, go on...')
				//setInterval(function () { that.checkConnection() }, 60000)
				that.initConfiguration(function() {
					console.log('Configuration has been loaded, procceed...')
					that.registerEvents()
					that.getSavedProjects()
					that.initMediaLibrary()
					console.log("Project: " + JSON.stringify(that.project))
					if (that.project.initialized === false) {
						console.log("Project not yet initialized, initialize now...");
						that.initProject()
						//console.log('Project not yet initialized, open project settings...')
						that.openProjectSettings('general')
					}
				})
			})
		}
	},
	mounted () {
    // console.log('Entering mounted...')
    //if (this.project.initialized === false) {
      // console.log('Project not yet initialized, open project settings...')
      //this.openProjectSettings('general')
    //}
  }
}
</script>

<style>
	.mandatory {
		background-color: #FFEEEE;
	}

	/* should resolve to secondaryColor */
	.navbar a, .navbar a:hover, .navbar a:active, .navbar a:visited {
		color: inherit !important; 
	}
	
	/* Farbe des Primary Buttons übersteuern */
	.text-custom-primary {
		color: var(--primaryColor) !important;
	}
	
	.text-custom-secondary {
		color: var(--secondaryColor) !important;
	}
	
	.btn-custom {
		color: #fff !important;
		background-color: var(--primaryColor) !important;
		border-color: var(--primaryColor) !important;
	}

	.borderNone {
			border: none !important;
	}
	
	.drop.over > div {
		background-color: #EFEFEF !important;
	}
	.btn-xs {
		padding: 0.15rem 0.25rem;
		font-size: 0.5rem;
		border-radius: 0.2rem;
	}
	.editAction:hover {
		cursor: pointer;
	}
	.column, .row {
		min-height: 50px;
	}
	.selected {
		border: 2px dotted red;
	}
	.row {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
	.card {
		background-color: transparent !important;
	}
	.objectId {
		cursor: pointer;
	}
	/* tree style, applies to object explorer, media explorer etc. */
	.tree-node {
		margin-left: 15px;
	}
	div.tree-node-label:hover {
		background-color: #EBECEE;
		cursor: pointer;
	}
	div.tree-node-label.active {
		background-color: #999999;
		color: #FFFFFF;
	}
	
	/* Responsive Background image */
	.bg-image {
		position: relative; 
		height: 550px; 
		/*display: flex;
		align-items: center;
		justify-content: center;*/
	}

	.bg-image::before {    
		content: "";
		background-image: var(--bg-image); 
		background-position: center center; 
		background-repeat: no-repeat; 
		background-size: cover;
		position: absolute;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		opacity: 0.25;
	}
	
	/* Vertical Timeline */
	/* The actual timeline (the vertical ruler) */
	.timeline {
		position: relative;
		max-width: 1200px;
		margin: 0 auto;
	}

	/* The actual timeline (the vertical ruler) */
	.timeline::after {
		content: '';
		position: absolute;
		width: 6px;
		background-color: var(--timelineColor);
		top: 0;
		bottom: 0;
		left: 50%;
		margin-left: -3px;
	}

	/* timeline-container around content */
	.timeline-container {
		padding: 10px 40px;
		position: relative;
		background-color: inherit;
		width: 50%;
	}

	/* The circles on the timeline */
	.timeline-container::after {
		content: '';
		position: absolute;
		width: 35px;
		height: 35px;
		right: -17px;
		background-color: var(--primaryColor);
		border: 6px solid var(--timelineColor);
		top: 15px;
		border-radius: 50%;
		z-index: 1;
	}

	/* Place the timeline-container to the left */
	.tl-left {
		left: 0;
	}

	/* Place the timeline-container to the right */
	.tl-right {
		left: 50%;
	}

	/* Add arrows to the left timeline-container (pointing right) */
	.tl-left::before {
		content: " ";
		height: 0;
		position: absolute;
		top: 22px;
		width: 0;
		z-index: 1;
		right: 30px;
		border: medium solid var(--elementColor);
		border-width: 10px 0 10px 10px;
		border-color: transparent transparent transparent var(--elementColor);
	}

	/* Add arrows to the right timeline-container (pointing left) */
	.tl-right::before {
		content: " ";
		height: 0;
		position: absolute;
		top: 22px;
		width: 0;
		z-index: 1;
		left: 30px;
		border: medium solid var(--elementColor);
		border-width: 10px 10px 10px 0;
		border-color: transparent var(--elementColor) transparent transparent;
	}

	/* Fix the circle for timeline-containers on the right side */
	.tl-right::after {
		left: -16px;
	}

	/* The actual content */
	.timeline-content {
		padding: 10px 30px;
		background-color: var(--elementColor);
		position: relative;
		border-radius: 6px;
	}

	/* Media queries - Responsive timeline on screens less than 600px wide */
	@media screen and (max-width: 600px) {
		/* Place the timelime to the left */
		.timeline::after {
			left: 31px;
		}

		/* Full-width timeline-containers */
		.timeline-container {
			width: 100%;
			padding-left: 70px;
			padding-right: 25px;
		}

		/* Make sure that all arrows are pointing leftwards */
		.timeline-container::before {
			left: 60px;
			border: medium solid  var(--timelineColor);
			border-width: 10px 10px 10px 0;
			border-color: transparent  var(--timelineColor) transparent transparent;
		}

		/* Make sure all circles are at the same spot */
		.tl-left::after, .tl-right::after {
			left: 15px;
		}

		/* Make all right timeline-containers behave like the left ones */
		.tl-right {
			left: 0%;
		}
	}

	.fs-1 {
		font-size: 2.5rem!important;
	}
	
	.fs-2 {
		font-size: 2rem!important;
	}
	
	.fs-3 {
		font-size: 1.75rem!important;
	}
	
	.fs-4 {
		font-size: 1.5rem!important;
	}
	
	.fs-5 {
		font-size: 1.25rem!important;
	}
	
	.fs-6 {
		font-size: 1rem!important;
	}
	
	/* Range Slider */
	.noUi-connect {
	background: var(--primaryColor);
	box-shadow: none;
	border-radius: 20px;
	}

	.noUi-target {
	box-shadow: none;
	border: none;
	border-radius: 30px;
	background: #f0f0f0;
	margin-top: 70px;
	}

	.noUi-handle.noUi-handle-lower {
	border: 9px solid #fff;
	height: 40px;
	width: 40px;
	border-radius: 50%;
	top: -12px;
	cursor: pointer;
	box-shadow: 0 0 2px #bbb;
	}

	.noUi-handle.noUi-handle-lower:after {
	display: none;
	}

	.noUi-handle.noUi-handle-lower:before {
	height: 22px;
	width: 22px;
	border-radius: 50%;
	left: 0;
	top: 0;
	background: var(--primaryColor);
	}

	.noUi-horizontal .noUi-tooltip {
	bottom: 200%;
	border: 1px solid var(--primaryColor);
	color: var(--primaryColor);
	}

	.noUi-horizontal .noUi-tooltip:before {
	position: absolute;
	content: "";
	height: 10px;
	width: 10px;
	background: #fff;
	bottom: -10px;
	transform: rotate(45deg) translateX(-50%);
	left: 48%;
	border-bottom: 1px solid var(--primaryColor);
	border-right: 1px solid var(--primaryColor);
	}

	.noUi-horizontal .noUi-tooltip {
	width: 72px;
	}

	.range_op {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 13px;
	margin-top: 10px;
	}

	.slider-container {
	width: 300px;
	padding: 50px;
	}

	/* Pro Boxes */
	.probox-wrapper {
	width: 150px; /* Set the size of the progress bar */
	height: 150px;
	position: absolute; /* Enable clipping */
	clip: rect(0px, 150px, 150px, 75px); /* Hide half of the progress bar */
	}
	/* Set the sizes of the elements that make up the progress bar */
	.probox-circle {
	width: 150px;
	height: 150px;
	border: 4px solid var(--primaryColor);
	border-radius: 50%;
	position: absolute;
	clip: rect(0px, 75px, 150px, 0px);
	}

	/* Using the data attributes for the animation selectors. */
	/* Base settings for all animated elements */
	div[data-anim~=base1] {
	-webkit-animation-iteration-count: 1;  /* Only run once */
	-webkit-animation-fill-mode: forwards; /* Hold the last keyframe */
	-webkit-animation-timing-function:linear; /* Linear animation */
	}

	.probox-wrapper[data-anim~=wrapper1] {
	-webkit-animation-duration: 0.01s; /* Complete keyframes asap */
	-webkit-animation-delay: 1s; /* Wait half of the animation */
	-webkit-animation-name: close-wrapper1; /* Keyframes name */
	}

	.probox-circle[data-anim~=left1] {
	-webkit-animation-duration: 2s; /* Full animation time */
	-webkit-animation-name: left1-spin;
	}

	.probox-circle[data-anim~=right1] {
	-webkit-animation-duration: 1s; /* Half animation time */
	-webkit-animation-name: right1-spin;
	}

	/* Rotate the right side of the progress bar from 0 to 180 degrees */
	@-webkit-keyframes right1-spin {
	from {
	-webkit-transform: rotate(0deg);
	}
	to {
	-webkit-transform: rotate(180deg);
	}
	}
	/* Rotate the left side of the progress bar from 0 to 360 degrees */
	@-webkit-keyframes left1-spin {
	from {
	-webkit-transform: rotate(0deg);
	}
	to {
	-webkit-transform: rotate(360deg);
	}
	}
	/* Set the wrapper clip to auto, effectively removing the clip */
	@-webkit-keyframes close-wrapper1 {
	to {
	clip: rect(auto, auto, auto, auto);
	}
	}

	div[data-anim~=base2] {
	-webkit-animation-delay: 2s;
	-webkit-animation-iteration-count: 1;  /* Only run once */
	-webkit-animation-fill-mode: forwards; /* Hold the last keyframe */
	-webkit-animation-timing-function:linear; /* Linear animation */
	}

	.probox-wrapper[data-anim~=wrapper2] {
	-webkit-animation-duration: 0.01s; /* Complete keyframes asap */
	-webkit-animation-delay: 3s; /* Wait half of the animation */
	-webkit-animation-name: close-wrapper2; /* Keyframes name */
	}

	.probox-circle[data-anim~=left2] {
	-webkit-animation-duration: 2s; /* Full animation time */
	-webkit-animation-name: left2-spin;
	}

	.probox-circle[data-anim~=right2] {
	-webkit-animation-duration: 1s; /* Half animation time */
	-webkit-animation-name: right2-spin;
	}
	/* Rotate the right side of the progress bar from 0 to 180 degrees */
	@-webkit-keyframes right3-spin {
	from {
	-webkit-transform: rotate(0deg);
	}
	to {
	-webkit-transform: rotate(180deg);
	}
	}
	/* Rotate the left side of the progress bar from 0 to 360 degrees */
	@-webkit-keyframes left3-spin {
	from {
	-webkit-transform: rotate(0deg);
	}
	to {
	-webkit-transform: rotate(360deg);
	}
	}
	/* Set the wrapper clip to auto, effectively removing the clip */
	@-webkit-keyframes close-wrapper3 {
	to {
	clip: rect(auto, auto, auto, auto);
	}
	}

	div[data-anim~=base3] {
	-webkit-animation-delay: 4s;
	-webkit-animation-iteration-count: 1;  /* Only run once */
	-webkit-animation-fill-mode: forwards; /* Hold the last keyframe */
	-webkit-animation-timing-function:linear; /* Linear animation */
	}

	.probox-wrapper[data-anim~=wrapper3] {
	-webkit-animation-duration: 0.01s; /* Complete keyframes asap */
	-webkit-animation-delay: 5s; /* Wait half of the animation */
	-webkit-animation-name: close-wrapper3; /* Keyframes name */
	}

	.probox-circle[data-anim~=left3] {
	-webkit-animation-duration: 2s; /* Full animation time */
	-webkit-animation-name: left3-spin;
	}

	.probox-circle[data-anim~=right3] {
	-webkit-animation-duration: 1s; /* Half animation time */
	-webkit-animation-name: right3-spin;
	}
	/* Rotate the right side of the progress bar from 0 to 180 degrees */
	@-webkit-keyframes right2-spin {
	from {
	-webkit-transform: rotate(0deg);
	}
	to {
	-webkit-transform: rotate(180deg);
	}
	}
	/* Rotate the left side of the progress bar from 0 to 360 degrees */
	@-webkit-keyframes left2-spin {
	from {
	-webkit-transform: rotate(0deg);
	}
	to {
	-webkit-transform: rotate(360deg);
	}
	}
	/* Set the wrapper clip to auto, effectively removing the clip */
	@-webkit-keyframes close-wrapper2 {
	to {
	clip: rect(auto, auto, auto, auto);
	}
	}

	.pro_boxes .pro1, .pro_boxes .pro2, .pro_boxes .pro3 {
	height: 150px;
	width: 150px;
	position: relative;
	background: rgba(192,192,192,0.5);
	border-radius: 50%;
	flex-shrink: 0;
	margin-right:15px;
	}

	.pro_boxes {
	display: flex;
	flex-direction: row;
	/*justify-content: space-between;*/
	justify-content: center;
	width: 100%;
	margin: 0 auto;
	}

	.pro_boxes .pro1 img, .pro_boxes .pro2 img, .pro_boxes .pro3 img {
	width: 60px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	}

	.pro_boxes .pro1 p, .pro_boxes .pro2 p, .pro_boxes .pro3 p {
	font-size: 16px;
	position: absolute;
	bottom: -40px;
	margin: 0;
	left: 50%;
	transform: translateX(-50%);
	}
	
	.pro-icon {
		top: 50%;
		left: 50%;
		position: absolute;
		transform: translate(-50%, -50%);
		font-size: 3.5em;
	}

	@media only screen and (max-width: 767px){
		.pro_boxes{width: 100% !important;}
		.pro_boxes .pro1 img, .pro_boxes .pro2 img, .pro_boxes .pro3 img {width: 30px !important;}
		.pro_boxes .pro1, .pro_boxes .pro2, .pro_boxes .pro3 { height: 80px !important; width: 80px !important;}
		.probox-wrapper {height: 80px !important; width: 80px !important; clip: rect(0px, 80px, 80px, 40px);}
		.probox-circle {width: 80px !important; height: 80px !important; clip: rect(0px, 40px, 80px, 0px) !important;}
		.pro-icon { font-size: 2.0em !important; }
		
		.advantage-icon {
			font-size: 1.5em;
		}
	}
</style>
