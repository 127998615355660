<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
	<div>
		<!--<vue-context-menu id="medialibrary-context-menu" ref="ctxMenuMediaLibrary">
		  <li @click="menuItemSelected(item)" class="ctx-item" v-for="item in contextMenuItems" :key="item.code">{{ item.label }}</li>
		</vue-context-menu>-->
		<b-row v-if="mediaExplorerConfig">
			<b-col v-if="mediaExplorerConfig.enableExplorer" cols="3">
				<div class="accordion" role="tablist">
					<b-card no-body class="mb-1">
						<template v-if="mediaLibrary && mediaLibrary.tree">
							<tree-view @menuItemSelected="menuItemSelected" :selectedNode="selectedNode" treetype="media" :imageCache="imageCache"  :tree="mediaLibrary.tree"></tree-view>
						</template>
					</b-card>
				</div>
			</b-col>
			<b-col :cols="colSize">
				<b-card type="medialibrary" class="shadow p-3 mb-5 rounded">
					<b-card-header align="right">
						<b-container fluid>
							<b-row align="center">
								<b-col v-if="this.images && this.images.length > 0" align="left" cols="4" class="d-none d-sm-block">
									<b>Seite {{ page }} / {{ maxPages }}</b> <small class="d-none d-md-block">({{ startIndex+1 }} - {{ endIndex+1 }} von {{ images.length }} Bildern)</small>
								</b-col>
								<b-col v-if="this.images && this.images.length > 0" align="left" cols="4" class="font-weight-bold d-none d-md-block">
									<b-form-group
										label-cols="5"
										label-size="sm"
										label="Bilder pro Seite"
										label-for="imagesPerPage">
										<b-form-select @input="changeImagesPerPage" id="imagesPerPage" size="sm" v-model="mediaExplorerConfig.maxImagesPerPage">
											<!-- <b-form-select-option v-for="num in maxImagesPerPage" :key="num" :value="num">{{ num === images.length ? 'all (' + images.length + ')' : num  }} -->
											<b-form-select-option v-for="num in maxImagesPerPage" :key="num" :value="num">{{ num }}
											</b-form-select-option>
										</b-form-select>
									</b-form-group>
								</b-col>
								<b-col align="right">
									<b-button class="mr-1" size="sm" :variant="mediaExplorerConfig.defaultLibraryLayout === 'grid' ? 'primary' : 'light'" v-b-tooltip.hover title="Kachelansicht" @click.prevent="mediaExplorerConfig.defaultLibraryLayout = 'grid'"><b-icon icon="grid-3x3-gap-fill" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
									<b-button class="mr-1" size="sm" :variant="mediaExplorerConfig.defaultLibraryLayout === 'list' ? 'primary' : 'light'" v-b-tooltip.hover title="Listenansicht" @click.prevent="mediaExplorerConfig.defaultLibraryLayout = 'list'"><b-icon icon="card-list" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
									<!-- <b-button class="mr-1" size="sm" variant="light" v-b-tooltip.hover title="Bearbeitungsmodus" @click.prevent="enterEditMode()"><b-icon icon="pencil" aria-hidden="true"  style="height: 14px;"></b-icon></b-button> -->
									<b-button v-if="mediaExplorerConfig.enableUpload" class="mr-1" size="sm" :variant="showUpload ? 'primary' : 'light'" v-b-tooltip.hover :title="showUpload ? 'Dropzone für Upload ausblenden' : 'Dropzone für Upload einblenden'" @click.prevent="showUpload = !showUpload"><b-icon icon="cloud-upload" aria-hidden="true"  style="height: 14px;"></b-icon></b-button>
									<b-button class="mr-1" size="sm" variant="light" v-b-tooltip.hover title="Einstellungen" @click.prevent="openMediaLibrarySettings()"><b-icon icon="gear-fill" aria-hidden="true"  style="height: 14px;"></b-icon></b-button>
								</b-col>
							</b-row>
						</b-container>
					</b-card-header>
					<b-card-text>
						<b-breadcrumb v-if="mediaExplorerConfig.enableBreadCrumb && selectedNode">
							<b-breadcrumb-item :active="item.uuid === selectedNode.uuid" v-for="item in breadCrumbItems" :key="item.uuid" @click.prevent="item.uuid !== selectedNode.uuid ? selectNode(item) : {}">{{item.name}}</b-breadcrumb-item>
						</b-breadcrumb>
						<b-container v-if="mediaExplorerConfig.enableUpload && showUpload" fluid class="mb-3 p-0">
							<b-card align="center" class="p-4">
								<b-card-text>
									<vue-dropzone @vdropzone-thumbnail="onThumbnailGenerated" @vdropzone-success="onUploadSucceeded" @vdropzone-sending="beforeSend" ref="mediaLibraryDropzone" id="mediaLibraryDropzone" :options="mediaExplorerConfig.dropzoneOptions"></vue-dropzone>
								</b-card-text>
							</b-card>
						</b-container>
						<template v-if="images && images.length > 0">
							<b-container fluid class="p-4 bg-dark">
								<!-- <b-row v-for="n in 1" :key="n" class="mb-2"> -->
								<b-row cols="1" cols-sm="2" cols-md="3" :cols-lg="mediaExplorerConfig.maxImagesPerRow">
									<b-col v-for="(image, index) in browsedImages" :key="image.uuid" class="mb-3">
										<b-card
											:index="index"
											overlay
											:img-src="image.src"
											:img-alt="image.title"
											img-fluid
											text-variant="white"
											title=""
											sub-title=""
											align="left"
											v-b-hover="function(hovered) { handleHover(hovered, image) }"
											style="width: 125px ; height: 125px; border: 3px solid #FFFFFF;">
											<b-card-text v-if="hovering === image.uuid">
												<b-button size="sm" v-b-tooltip.hover title="Objekt löschen" variant="light" @click.prevent="confirmDelete(image)" ><b-icon icon="trash-fill" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
												<b-button size="sm" v-b-tooltip.hover title="Einstellungen (Objekt)" variant="light" @click.prevent="openObjectSettings(image)" ><b-icon icon="gear-fill" aria-hidden="true" style="height: 14px;"></b-icon></b-button>
											</b-card-text>
										</b-card>
									</b-col>
								</b-row>
							</b-container>
						</template>
						<template v-else>
							<span class="text-danger">Dieser Ordner enthält keine Bilder</span>
						</template>
						<b-container fluid class="p-0" v-if="images && images.length > 0">
							<b-card align="right" class="">
								<b-card-text>
									<b-button v-if="startIndex > (mediaExplorerConfig.maxImagesPerPage - 1)" class="float-left" size="sm" v-b-tooltip.hover title="Vorherige Seite" variant="secondary" @click.prevent="previousPage()">
										<b-icon class="mr-3" icon="arrow-left-square" aria-hidden="true"></b-icon> Previous Page
									</b-button>
									<b-button v-if="endIndex < (images.length - 1)" class="float-right" size="sm" v-b-tooltip.hover title="Nächste Seite" variant="secondary" @click.prevent="nextPage()">
										<b-icon class="mr-3" icon="arrow-right-square" aria-hidden="true"></b-icon>Next Page
									</b-button>
								</b-card-text>
							</b-card>
						</b-container>
					</b-card-text>
				</b-card>
			</b-col>
		</b-row> 
		
		<b-modal
			id="addFolderModal"
			ref="addFolderModal"
			title="Neuen Ordner anlegen"
			@ok="saveNewFolder"
			:ok-only=false
			size="lg">
			<b-container fluid>
				<b-row>
					<b-col class="mb-2">	
						<b-form-group
							class="font-weight-bold"
							label="Ordnername"
							label-for="newFolder"
							label-size="sm">
							<b-form-input autofocus id="newFolder" placeholder="Bitte Ordnernamen ohne Leerzeichen angeben" size="sm" type="text" v-model="newFolder.name"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
			</b-container>
		</b-modal>
		
		<b-modal
			id="renameFolderModal"
			ref="renameFolderModal"
			title="Ordner umbenennen"
			@ok="renameFolder"
			:ok-only=false
			size="lg">
			<b-container fluid>
				<b-row>
					<b-col class="mb-2">	
						<b-form-group
							class="font-weight-bold"
							label="Ordnername"
							label-for="folder"
							label-size="sm">
							<b-form-input autofocus id="folder" placeholder="Bitte neuen Ordnernamen ohne Leerzeichen angeben" size="sm" type="text" v-model="selectedNode.name"></b-form-input>
						</b-form-group>
					</b-col>
				</b-row>
			</b-container>
		</b-modal>
	</div>
</template>

<script>
import EventBus from '../EventBus.js'
// import VueContextMenu from 'vue-context-menu'
import { v4 as uuidv4 } from 'uuid'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import TreeView from './TreeView.vue'

/* global LIP */
/* eslint-disable no-mixed-spaces-and-tabs */

export default {
  name: 'MediaLibrary',
  data: () => ({
	contextMenuItems: [{ code: 'DELETE', label: 'Ordner löschen' }, { code: 'RENAME', label: 'Ordner umbenennen' }, { code: 'NEW_SUBFOLDER', label: 'Neuer Unterordner' }],
	selectedNode: null,
	// imageCache: {},
	treeCache: {},
	pathCache: {},
    hovering: null,
    page: 1,
    startIndex: 0,
    endIndex: 0,
	showUpload: false,
	// mediaLibrary: null,
	newFolder: {},
	images: [],
    /* sampleimages: [
		// { id: 'image58', uuid: 'image58', title: 'image58', src: 'https://picsum.photos/125/125/?image=58', type: 'media' },
		{ id: 'image10', uuid: 'image10', title: 'image10', src: '/media/10.jpg', type: 'media' },
		{ id: 'image11', uuid: 'image11', title: 'image11', src: '/media/11.jpg', type: 'media' },
		{ id: 'image12', uuid: 'image12', title: 'image12', src: '/media/12.jpg', type: 'media' },
		{ id: 'image13', uuid: 'image13', title: 'image13', src: '/media/13.jpg', type: 'media' },
		{ id: 'image14', uuid: 'image14', title: 'image14', src: '/media/14.jpg', type: 'media' },
		{ id: 'image15', uuid: 'image15', title: 'image15', src: '/media/15.jpg', type: 'media' },
		{ id: 'image16', uuid: 'image16', title: 'image16', src: '/media/16.jpg', type: 'media' },
		{ id: 'image17', uuid: 'image17', title: 'image17', src: '/media/17.jpg', type: 'media' },
		{ id: 'image18', uuid: 'image18', title: 'image18', src: '/media/18.jpg', type: 'media' },
		{ id: 'image19', uuid: 'image19', title: 'image19', src: '/media/19.jpg', type: 'media' },
		{ id: 'image20', uuid: 'image20', title: 'image20', src: '/media/20.jpg', type: 'media' },
		{ id: 'image21', uuid: 'image21', title: 'image21', src: '/media/21.jpg', type: 'media' },
		{ id: 'image22', uuid: 'image22', title: 'image22', src: '/media/22.jpg', type: 'media' },
		{ id: 'image23', uuid: 'image23', title: 'image23', src: '/media/23.jpg', type: 'media' },
		{ id: 'image24', uuid: 'image24', title: 'image24', src: '/media/24.jpg', type: 'media' },
		{ id: 'image25', uuid: 'image25', title: 'image25', src: '/media/25.jpg', type: 'media' },
		{ id: 'image26', uuid: 'image26', title: 'image26', src: '/media/26.jpg', type: 'media' },
		{ id: 'image27', uuid: 'image27', title: 'image27', src: '/media/27.jpg', type: 'media' },
		{ id: 'image28', uuid: 'image28', title: 'image28', src: '/media/28.jpg', type: 'media' },
		{ id: 'image29', uuid: 'image29', title: 'image29', src: '/media/29.jpg', type: 'media' },
		{ id: 'image30', uuid: 'image30', title: 'image30', src: '/media/30.jpg', type: 'media' },
		{ id: 'image31', uuid: 'image31', title: 'image31', src: '/media/31.jpg', type: 'media' },
		{ id: 'image32', uuid: 'image32', title: 'image32', src: '/media/32.jpg', type: 'media' },
		{ id: 'image33', uuid: 'image33', title: 'image33', src: '/media/33.jpg', type: 'media' },
		{ id: 'image34', uuid: 'image34', title: 'image34', src: '/media/34.jpg', type: 'media' },
		{ id: 'image35', uuid: 'image35', title: 'image35', src: '/media/35.jpg', type: 'media' },
		{ id: 'image36', uuid: 'image36', title: 'image36', src: '/media/36.jpg', type: 'media' },
		{ id: 'image37', uuid: 'image37', title: 'image37', src: '/media/37.jpg', type: 'media' },
		{ id: 'image38', uuid: 'image38', title: 'image38', src: '/media/38.jpg', type: 'media' },
		{ id: 'image39', uuid: 'image39', title: 'image39', src: '/media/39.jpg', type: 'media' },
		{ id: 'image40', uuid: 'image40', title: 'image40', src: '/media/40.jpg', type: 'media' },
		{ id: 'image41', uuid: 'image41', title: 'image41', src: '/media/41.jpg', type: 'media' },
		{ id: 'image42', uuid: 'image42', title: 'image42', src: '/media/42.jpg', type: 'media' },
		{ id: 'image43', uuid: 'image43', title: 'image43', src: '/media/43.jpg', type: 'media' },
		{ id: 'image44', uuid: 'image44', title: 'image44', src: '/media/44.jpg', type: 'media' },
		{ id: 'image45', uuid: 'image45', title: 'image45', src: '/media/45.jpg', type: 'media' },
		{ id: 'image46', uuid: 'image46', title: 'image46', src: '/media/46.jpg', type: 'media' },
		{ id: 'image47', uuid: 'image47', title: 'image47', src: '/media/47.jpg', type: 'media' },
		{ id: 'image48', uuid: 'image48', title: 'image48', src: '/media/48.jpg', type: 'media' },
		{ id: 'image49', uuid: 'image49', title: 'image49', src: '/media/49.jpg', type: 'media' },
		{ id: 'image50', uuid: 'image50', title: 'image50', src: '/media/50.jpg', type: 'media' },
		{ id: 'image51', uuid: 'image51', title: 'image51', src: '/media/51.jpg', type: 'media' },
		{ id: 'image52', uuid: 'image52', title: 'image52', src: '/media/52.jpg', type: 'media' },
		{ id: 'image53', uuid: 'image53', title: 'image53', src: '/media/53.jpg', type: 'media' }
    ] */
  }),
  props: [
    'mediaExplorerConfig',
	'imageCache',
	'mediaLibrary'
  ],
  components: {
	vueDropzone: vue2Dropzone,
	// VueContextMenu,
	'tree-view': TreeView
  },
  created () {
	this.startIndex = 0
	this.endIndex = 0
	// this.initMediaLibrary()
	this.selectNode(this.mediaLibrary.tree[0]) // pre select the root node of our media explorer
	this.initTreeCache()
	
	EventBus.$on('nodeSelected', (node) => {
		console.log('Received event nodeSelected for node ' + node.name)
		this.selectNode(node)
	})	
  },
  methods: {
	openObjectSettings(obj) {
		EventBus.$emit('mediaLibraryEvent',{ event: 'openMediaLibrarySettings', obj: obj})
	},
	openMediaLibrarySettings () {
      EventBus.$emit('mediaLibraryEvent',{event: 'openMediaLibrarySettings'})
    },
	initCaches() {
	},
	initTreeCache() {
		this.processTreeNodes(this.mediaLibrary.tree)
	},
	addToTreeCache(node) {
		this.treeCache[node.uuid] = node
	},
	getFromTreeCache(uuid) {
		return this.treeCache[uuid]
	},
	deleteFromTreeCache(node) {
		delete this.treeCache[node.uuid]
	},
	isInTreeCache(node) {
		return typeof this.treeCache[node.id] !== 'undefined'
	},
	processTreeNodes(nodes) {
		// console.log('Entering processTreeNodes with nodes ' + JSON.stringify(nodes))
		for(let i=0; i<nodes.length; i++) {
			// console.log('Processing node ' + nodes[i].name)
			this.addToTreeCache(nodes[i])
			this.processTreeNodes(nodes[i].children)
		}
	},
	invokeControlFunction (controlFunction, payload, successCallback, errorCallback, fallbackCallback) {
		console.log('Entering invokeControlFunction: controlFunction => ' + controlFunction + ', payload =>' + payload + ', successCallback => ' + typeof successCallback + ', errorCallback => ' + typeof errorCallback + ', fallbackCallback => ' + typeof fallbackCallback)
		if (typeof LIP !== 'undefined' && typeof LIP.ffw !== 'undefined') {
			LIP.ffw.invokeControlFunction(this.$root.icPath, controlFunction, payload, {
				success: function (responseText) {
					if(typeof successCallback !== 'undefined' && typeof successCallback === 'function') {
						successCallback(responseText)
					}
				},
				error: function (statusCode, responseText) {
					if(typeof errorCallback !== 'undefined' && typeof errorCallback === 'function') {
						errorCallback(statusCode, responseText)
					}
				}
			})
		} else {
			if(typeof fallbackCallback !== 'undefined' && typeof fallbackCallback === 'function') {
				fallbackCallback()
			}
		}
	},
	invokeActionOnFolder (action, payload, showSuccess, showError, successCallback, errorCallback, actionCallback) {
		console.log('Entering invokeActionOnFolder with action ' + action + ' and payload ' + JSON.stringify(payload))
		this.invokeControlFunction ('invokeActionOnFolder', payload, function(responseText) {
			if(showSuccess) {
				const sucessMsg = { caption: 'Aktion erfolgreich', msg: 'Die Aktion wurde erfolgreich ausgeführt.', msgDetail: responseText, append: false, variant: 'success', solid: true }
				EventBus.$emit('makeToast', sucessMsg)
			}
			if(typeof successCallback !== 'undefined' && typeof successCallback === 'function') {
				successCallback(responseText)
			}
		}, function(statusCode, responseText) {
			if(showError) {
				const data = { caption: 'Aktion fehlgeschlagen ' + statusCode, msg: 'Bei der Aktion ist ein Fehler aufgetreten!', msgDetail: responseText, append: false, variant: 'danger', solid: true }
				EventBus.$emit('makeToast', data)
			}
			if(typeof errorCallback !== 'undefined' && typeof errorCallback === 'function') {
				errorCallback(statusCode, responseText)
			}
		}, function() {
			if(typeof actionCallback !== 'undefined' && typeof actionCallback === 'function') {
				actionCallback()
			}
		})
	},
	renameFolder () {
		this.invokeActionOnFolder('renameFolder',this.selectedNode, true, true)
	},
	saveNewFolder () {
		const that = this
		console.log('Entering saveNewFolder...')
		this.newFolder.path = this.newFolder.path + '/' + this.newFolder.name
		this.invokeActionOnFolder('saveNewFolder', this.newFolder, true, true, function() { 
			that.selectedNode.children.push(that.newFolder) 
		}, undefined, function() { 
			that.selectedNode.children.push(that.newFolder) 
		})
	},
	addSubfolder () {
		this.newFolder = { uuid: uuidv4(), name: null, path: this.selectedNode.path, children: [], parent: this.selectedNode.uuid },
		this.$bvModal.show('addFolderModal')
	},
	showContextMenu (obj) {
      console.log('Entering showContextMenu for object ' + JSON.stringify(obj))
      this.selectedNode = obj
      this.$refs.ctxMenuMediaLibrary.open()
    },
	confirmDelete (obj) {
      const msgTitle = '"' + obj.title + '" löschen?'
      const msgTxt = 'Soll "' + obj.title + '" wirklich gelöscht werden?'
      this.$bvModal.msgBoxConfirm(msgTxt, {
        title: msgTitle,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Ja',
        cancelTitle: 'Nein',
        footerClass: 'p-2',
        hideHeaderClose: true,
        centered: true
      }).then(value => {
        if (value === true) { this.deleteObject(obj) }
      }).catch(err => {
        console.log('An error occured ' + err)
      })
    },
    deleteObject (obj) {
		const that = this
		console.log('Deleting object with uuid: ' + obj.uuid)
		if(obj.type === 'folder') {
			if(obj.root === true) {
				const errorMsg = { caption: 'Löschen verboten', msg: 'Der Root-Ordner kann nicht gelöscht werden. Bitte löschen Sie einen Unterordner!', msgDetail: null, append: false, variant: 'danger', solid: true }
				EventBus.$emit('makeToast', errorMsg)
			} else {
				this.invokeActionOnFolder('removeFolder',this.selectedNode, true, true, function() { 
					that.removeFolder(obj) 
				}, undefined, function() { 
					that.removeFolder(obj) 
				})
			}
		} else {
			// Nothin to do yet
		}
	},
	removeFolder (folder) {
		console.log('Entering removeFolder for folder ' + folder.name)
		const parent = this.getFromTreeCache(folder.parent)
		const index = parent.children.indexOf(folder)
		console.log('Found folder ' + folder.name + ' at index ' + index + ' in parent ' + parent.name)
		this.deleteFromTreeCache(folder)
		delete this.imageCache[folder.path]
		parent.children.splice(index,1)
		if(parent.children.length > 0) {
			this.selectNode(parent.children[0])
		} else {
			this.selectNode(parent)
		}
	},
	menuItemSelected (menuItem) {
      console.log('Entering event menuItemSelected for menuItem ' + JSON.stringify(menuItem))
	  if (menuItem.code === 'DELETE') {
		this.confirmDelete(this.selectedNode,'Ordner löschen','Soll der Ordner "' + this.selectedNode.name + '" wirklich gelöscht werden? Der Vorgang kann nicht rückgängig gemacht werden und alle Inhalte werden ebenfalls unwiederruflich gelöscht!!')
      } else if (menuItem.code === 'NEW_SUBFOLDER') {
		this.addSubfolder()
	  } else if (menuItem.code === 'RENAME') {
		this.$bvModal.show('renameFolderModal')
	  } 
    },
	removeFromDropzone (file) {
		const that = this
		setTimeout(() => {
			that.$refs.mediaLibraryDropzone.removeFile(file)
		}, 2000)
	},
	addImage (file, obj, updateImages, updateIndexes, removeFromDZ) {
		const imgObj = { id: obj.uuid, uuid: obj.uuid, title: obj.filename, filename: obj.filename, src: obj.dataURL, path: obj.path, size: obj.filesize, type: 'media' }
		
		if(typeof this.imageCache[obj.path] === 'undefined') {
			this.imageCache[obj.path] = []
		}
		this.imageCache[obj.path].push(imgObj)
		
		if(updateImages) {
			this.images = this.imageCache[obj.path]
		}
		if(updateIndexes) {
			this.updatePageBrowserIndexes()
		}
		if(removeFromDZ) {
			this.removeFromDropzone(file)
		}
	},
	onThumbnailGenerated (file, dataUrl) {
		// console.log('Entering onThumbnailGenerated for file ' + JSON.stringify(file) + ', dataURL: ' + dataUrl)
		
		const payload = { uuid: uuidv4(), path: this.selectedNode.path, filename: file.upload.filename, filesize: file.upload.total, dataURL: dataUrl }
		console.log('Invoke control function with payload  ' + JSON.stringify(payload))
		
		const that = this
		this.invokeControlFunction ('uploadFile', payload, function(responseText) {
			const sucessMsg = { caption: 'Upload erfolgreich', msg: 'Die Datei ' +  file.upload.filename + ' wurde erfolgreich hochgeladen.', msgDetail: responseText, append: false, variant: 'success', solid: true }
			EventBus.$emit('makeToast', sucessMsg)
			that.addImage(file, payload, true, true, true)
		}, function(statusCode, responseText) {
			const data = { caption: 'Upload fehlgeschlagen ' + statusCode, msg: 'Beim Upload ist ein Fehler aufgetreten!', msgDetail: responseText, append: false, variant: 'danger', solid: true }
			EventBus.$emit('makeToast', data)
		}, function() {
			that.addImage(file, payload, true, true, true)
		})
	},
	/*onUploadSucceeded (file, response) {
		// console.log("Upload succeeded")
		console.log("File: " + JSON.stringify(file))
		console.log("Response: " + JSON.stringify(response))
		const imgObj = { id: response.form.uuid, uuid: response.form.uuid, title: file.upload.filename, src: file.dataURL, type: 'media' }
		// console.log('Adding image ' + JSON.stringify(imgObj) + 'to image cache for path ' + response.form.path)
		if(typeof this.imageCache[response.form.path] === 'undefined') {
			this.imageCache[response.form.path] = []
		}
		this.imageCache[response.form.path].push(imgObj)
		this.images = this.imageCache[response.form.path]
		const that = this
		// setTimeout(() => {
		that.$refs.mediaLibraryDropzone.removeFile(file)
        // }, 2000)
		this.endIndex++
	},
	beforeSend (file, xhr, formData) {
		formData.append('uuid', uuidv4())
		formData.append('path', this.selectedNode.path)
		formData.append('filename', file.upload.filename)
		formData.append('filesize', file.upload.total)
		console.log('Modified formData: ' + JSON.stringify(formData))
	},*/
	onUploadSucceeded (/*file, response*/) {
		console.log('Entering onUploadSucceeded...')
	},
	beforeSend (/*file, xhr, formData*/) {
		console.log('Entering beforeSend...')
	},
	/*initMediaLibrary () {
		const that = this
		this.invokeControlFunction ('initMediaLibrary', {}, function(responseText) {
			that.mediaLibrary = responseText
			that.imageCache = that.mediaLibrary.images || {}
			that.selectedNode = that.mediaLibrary.tree[0] // pre select the root node of our media explorer
			that.initTreeCache()
		}, function(statusCode, responseText) {
			const data = { caption: 'Ping fehlgeschlagen ' + statusCode, msg: 'Ping ist fehlgeschlagen. Der Server scheint nicht erreichbar zu sein oder es liegt ein anderes Problem vor!', msgDetail: responseText, append: false, variant: 'danger', solid: true }
			EventBus.$emit('makeToast', data)
		}, function() {
			that.mediaLibrary = SampleData.getMediaLibrary()
			that.imageCache = that.mediaLibrary.images || {}
			that.selectNode(that.mediaLibrary.tree[0])
			that.initTreeCache()
		})
	},*/
	selectNode (obj) {
		this.selectedNode = obj
		console.log('Selected node: ' + obj.name)
		this.loadImages(obj.path, false)
    },
	loadImagesFromServer(path, successCallback, errorCallback, fallbackCallback) {
		this.invokeControlFunction ('loadImages', { path: path }, function(responseText) {
			if(typeof successCallback !== 'undefined' && typeof successCallback === 'function') {
				successCallback(responseText)
			}
		}, function(statusCode, responseText) {
			const data = { caption: 'Fehler ' + statusCode, msg: 'Bilder aus Pfad ' + path + ' konnten nicht geladen werden!', msgDetail: responseText, append: false, variant: 'danger', solid: true }
			EventBus.$emit('makeToast', data)
			if(typeof errorCallback !== 'undefined' && typeof errorCallback === 'function') {
				errorCallback(statusCode, responseText)
			}
		}, fallbackCallback)
	},
	loadImages (path, forceLoadFromServer) {
		const that = this
		console.log('Entering loadImages...')
		if(forceLoadFromServer) {
			this.images = [] // load from server
			console.log('maxImagesPerPage: ' + this.mediaExplorerConfig.maxImagesPerPage + ', num images: ' + this.images.length)
			this.loadImagesFromServer(path, function(responseText) {
				that.images = responseText[path] || []
				that.updatePageBrowserIndexes()
			},undefined,function() {
				that.images = []
			})
		} else {
			const that = this
			// console.log('maxImagesPerPage: ' + this.mediaExplorerConfig.maxImagesPerPage + ', num images: ' + this.images.length)
			if(typeof this.imageCache[path] !== 'undefined' && this.imageCache[path].length > 0) {
				console.log('Load images for path ' + path + ' from cache...')
				this.images = this.imageCache[path] || []
				console.log(this.images.length + ' images loaded from cache.')
				that.updatePageBrowserIndexes()
			} else {
				console.log('No images found, try to load images from server...')
				this.loadImagesFromServer(path, function(responseText) {
					that.images = responseText[path] || []
					that.updatePageBrowserIndexes()
				},undefined,function() {
					console.log('Entering fallback callback...')
					that.images = []
				})
			}
		}
	},
	updatePageBrowserIndexes () {
		this.startIndex = (this.page * this.mediaExplorerConfig.maxImagesPerPage) - this.mediaExplorerConfig.maxImagesPerPage
		this.endIndex = this.images.length > this.mediaExplorerConfig.maxImagesPerPage ? this.mediaExplorerConfig.maxImagesPerPage-1 : this.images.length - 1
	},
	changeImagesPerPage(value) {
		// console.log('Changed num images to ' + value)
		if(value == this.images.length && this.page > 1) {
			this.page = 1
			this.startIndex = 0
			this.endIndex = value - 1
		} else {
			this.endIndex = ((this.startIndex + value - 1) > this.images.length - 1) ? this.images.length - 1 : (this.startIndex + value - 1)
		}
	},
    handleHover (hovered, image) {
      if (hovered) {
        this.hovering = image.uuid
      } else {
        this.hovering = null
      }
    },
	previousPage() {
		this.page = this.page - 1
		this.startIndex = (this.page * this.mediaExplorerConfig.maxImagesPerPage) - this.mediaExplorerConfig.maxImagesPerPage
		this.endIndex = (this.page * this.mediaExplorerConfig.maxImagesPerPage)-1
	},
	nextPage() {
		this.page = this.page + 1
		this.startIndex = (this.page * this.mediaExplorerConfig.maxImagesPerPage) - this.mediaExplorerConfig.maxImagesPerPage
		this.endIndex =	((this.endIndex + this.mediaExplorerConfig.maxImagesPerPage) > this.images.length) ? this.images.length - 1 : ((this.page * this.mediaExplorerConfig.maxImagesPerPage)-1)
	},
  },
  computed: {
	breadCrumbItems () {
		const bc = [] // [{ text: 'Images', href: '#' }, { text: 'Icons', href: '#' }, { text: 'Buttons', href: '#' }]
		/*const pathSplitted = this.selectedNode.path.split("/");
		for(let i in pathSplitted) {
			let splittedPath = pathSplitted[i]
			// console.log('Processing splitted path: ' + splittedPath)
			bc.push({ text: splittedPath })
		*/
		let currentNode = this.selectedNode
		bc.push(currentNode)
		// console.log('currentNode.parent: ' + currentNode.parent + ', ' + currentNode.name)
		while(typeof currentNode.parent !== 'undefined') {
			currentNode = this.treeCache[currentNode.parent]
			// console.log('currentNode.parent: ' + currentNode.parent + ', ' + currentNode.name)
			bc.push(currentNode)
		}
		return bc.reverse()
	},
	colSize () {
      return this.mediaExplorerConfig.enableExplorer ? 9 : 12
    },
	browsedImages: function() {
		return this.images.slice(this.startIndex,this.endIndex+1)
	},
	maxPages: function() {
		return parseInt(this.images.length / this.mediaExplorerConfig.maxImagesPerPage) + ((this.images.length % this.mediaExplorerConfig.maxImagesPerPage) === 0 ? 0 : 1)
	},
	maxImagesPerPage: function() {
		return [5,10,15,20,50]
		/*const nums = [5,10,15,20,30,50,75,100,150,200];
		const imagesPerPage = []
		for(var n in nums) {
			// console.log('Processing numImages: ' + nums[n])
			if(nums[n] < this.images.length) {
				imagesPerPage.push(nums[n])
			} else {
				break
			}
		}
		
		imagesPerPage.push(this.images.length)
				
		return imagesPerPage*/
	}
  }
}
</script>

<style>
.dz-progress {
	display: none !important;
}
</style>
