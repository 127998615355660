<template>
	<b-container fluid>
		<h2>API-Explorer</h2>
  
		<b-row class="mt-3">
			<h5>API-Endpoint-URL</h5>
			<!--<b-form-input v-model="formdata.api_endpoint" placeholder="API-Endoint-URL"></b-form-input>-->
			<b-form-select v-model="formdata.api_endpoint" class="mb-3">
				<!-- <b-form-select-option value="https://api.coindesk.com/v1/bpi/currentprice.json">Coinbase Currencies (https://api.coindesk.com/v1/bpi/currentprice.json)</b-form-select-option>
				<b-form-select-option value="http://dev.novolytics.de/api/">http://dev.novolytics.de/api/</b-form-select-option> -->
				<b-form-select-option value="/api/">/api/</b-form-select-option>
			</b-form-select>
		</b-row>
		
		<b-row class="mt-3">
			<h5>API-Function</h5>
			<b-form-select @input="function(value) { changeValue('api_action',value) }" v-model="formdata.api_action" class="mb-3">
				<b-form-select-option value="">Please select an option</b-form-select-option>
				<b-form-select-option value="getProjects">getProjects (Projekte laden)</b-form-select-option>
				<b-form-select-option value="loadProject">loadProject (Projekt öffnen)</b-form-select-option>
				<b-form-select-option value="createProject">createProject (Projekt anlegen)</b-form-select-option>
				<b-form-select-option value="deleteProject">deleteProject (Projekt löschen)</b-form-select-option>
				<b-form-select-option value="login">Login (Anmelden)</b-form-select-option>
				<b-form-select-option value="hashPassword">hashPassword (Passwort-Hash erzeugen)</b-form-select-option>
				<b-form-select-option value="verifyPassword">verifyPassword (Passwort überprüfen)</b-form-select-option>
				<b-form-select-option value="getUser">getUser (Suche Benutzer)</b-form-select-option>
				<b-form-select-option value="getUsers">getUsers (Benutzer laden)</b-form-select-option>
				<b-form-select-option value="addUser">addUser (Benutzer hinzufügen)</b-form-select-option>
				<b-form-select-option value="setPassword">setPassword (Passwort setzen)</b-form-select-option>
				<b-form-select-option value="deleteUser">deleteUser (Benutzer löschen)</b-form-select-option>
				<b-form-select-option value="logout">logout (Abmelden)</b-form-select-option>
				<b-form-select-option value="getSessionVariables">getSessionVariables (Session Variablen auslesen)</b-form-select-option>
				<b-form-select-option value="getConnectionStatus">getConnectionStatus (Connection Status ermitteln)</b-form-select-option>
				<b-form-select-option value="getConfig">getConfig (Konfiguration laden)</b-form-select-option>
				<b-form-select-option value="loadImages">loadImages (Bilder laden für Mediengallerie)</b-form-select-option>
				<b-form-select-option value="createFolder">createFolder (Ordner erstellen / Mediengallerie)</b-form-select-option>
				<b-form-select-option value="renameFolder">renameFolder (Ordner umbenennen / Mediengallerie)</b-form-select-option>
				<b-form-select-option value="deleteFolder">deleteFolder (Ordner löschen / Mediengallerie)</b-form-select-option>
				<b-form-select-option value="getLeads">getLeads (Leads zu einem Projekt laden)</b-form-select-option>
			</b-form-select>
		</b-row>
		
		<b-row class="mt-3">
			<h5>API-Request</h5>
			<b-form-textarea
				id="api_payload"
				v-model="formdata.api_payload"
				placeholder="API-Payload"
				rows="3"
				max-rows="6"></b-form-textarea>
		</b-row>
		
		<b-row class="mt-3">
		<h5>API-Response</h5>
		<b-form-textarea
			id="api_response"
			v-model="formdata.api_response"
			placeholder="API-Response"
			rows="3"
			max-rows="6"></b-form-textarea>
		</b-row>

		<b-row class="mt-3">
			<b-button :disabled="formdata.api_action == null || formdata.api_payload == null" @click.prevent="invokeAction()" variant="primary">Senden</b-button>
		</b-row>
		
		<!--
		<b-row class="mt-3">
			<h5>Debug</h5>
			{{formdata}}
		</b-row> -->
		
	</b-container>
</template>
<script>
import EventBus from '../EventBus.js'
export default {
  name: 'Preview',
  data: () => ({
	formdata: {
		api_action: "",
		api_payload: "",
		api_response: "",
		api_endpoint: "/api/"
	},
	defaults: {
		api_payload: { 
			login: {
				action: "login", payload: { user: "mtricari", password: "123456" }
			},
			getProjects: {
				action: "getProjects",
				payload: "",
				projectid: ""
			},
			loadProject: {
				action: "loadProject",
				payload: "",
				projectid: "werbeaktion"
			},
			createProject: {
				action: "createProject",
				payload: "",
				projectid: "projectid",
				overwrite: false
			},
			updateProject: {
				action: "updateProject",
				payload: "",
				projectid: "projectid"
			},
			deleteProject: {
				action: "deleteProject",
				payload: "",
				projectid: "projectid"
			},
			hashPassword: {
				action: "hashPassword",
				payload: { password: "123456" }
			},
			verifyPassword: {
				action: "verifyPassword",
				payload: { user: "mtricari", password: "123456" }
			},
			getUser: {
				action: "getUser",
				payload: { user: "mtricari" }
			},
			logout: {
				action: "logout",
				payload: ""
			},
			getSessionVariables: {
				action: "getSessionVariables",
				payload: ""
			},
			getConnectionStatus: {
				action: "getConnectionStatus",
				payload: ""
			},
			getConfig: {
				action: "getConfig",
				payload: ""
			},
			getUsers: {
				action: "getUsers",
				payload: ""
			},
			addUser: {
				action: "addUser",
				payload: { user: "mtricari", password: "123456" }
			},
			setPassword: {
				action: "setPassword",
				payload: { user: "mtricari", password: "123456" }
			},
			deleteUser: {
				action: "deleteUser",
				payload: { user: "mtricari" }
			},
			loadImages: {
				action: "loadImages",
				payload: { projectid: "werbeaktion" }
			},
			createFolder: {
				action: "createFolder",
				payload: { projectid: "werbeaktion", foldername: "new_folder" }
			},
			renameFolder: {
				action: "renameFolder",
				payload: { projectid: "werbeaktion", foldername: "new_folder", newname: "new_name" }
			},
			deleteFolder: {
				action: "deleteFolder",
				payload: { projectid: "werbeaktion", foldername: "new_folder" }
			},
			getLeads: {
				action: "getLeads",
				payload: "",
				projectid: "werbeaktion"
			}
		}
	}
  }),
  components: {
  },
  created () {
  },
  methods: {
	dispatchLogin: function(token, username) {
		const user = { username: username }
		this.$store.dispatch('login', { token, user })
	},
	dispatchLogout: function() {
		this.$store.dispatch('logout', {})
	},
	changeValue: function(field, value) {
		console.log("Changing value of field: " + field + " to " + value);
		if(field == 'api_action') {
			return this.formdata.api_payload = JSON.stringify(this.defaults.api_payload[value]); 
		}
	},
	invokeAction: function() {
		console.log("Invoke action " + this.formdata.api_action + ", sending request to " + this.formdata.api_endpoint)
			
		let msg = 'Die Aktion ' + this.formdata.api_action + ' wurde ausgeführt.'
		let variant = 'success'
		
		this.formdata.api_response = "";
		let requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: this.formdata.api_payload
		};
		//console.log("isLoggedIn: " + this.$store.getters.isLoggedIn)
		// Add Bearer token to request to authenticate requests
		if(this.$store.getters.isLoggedIn) {
			requestOptions.headers["Authorization"] = "Bearer " + this.$store.getters.getToken
		}
		fetch(this.formdata.api_endpoint,requestOptions).then(response => response.json()).then(resp => { 
			msg = resp.MESSAGE
			if(resp.SUCCESS == true) {
				variant = 'success'
			} else {
				variant = 'danger'
			}
			let toastMsg = { caption: 'Aktion erfolgreich', msg: msg, msgDetail: resp.MESSAGE, append: false, variant: variant, solid: true }
			EventBus.$emit('makeToast', toastMsg)
		
			if(this.formdata.api_action == "login") {
				if(resp.SUCCESS == true) {
					console.log("Login succeeded for user " + resp.DATA.user + ", received token " + resp.DATA.token)
					this.dispatchLogin(resp.DATA.token,resp.DATA.user)
				} else {
					console.log("Login failed, please try again!")
					this.dispatchLogout()
				}
			} else {
				if(this.formdata.api_action == "logout") {
					if(resp.SUCCESS == true) {
						this.dispatchLogout()
					} else {
						console.log("Logout failed, please try again!")
					}
				}
			}
			this.formdata.api_response = JSON.stringify(resp)
		})
	}
  },
  computed: {
  }
}
</script>

<style>
</style>
