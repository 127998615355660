<template>
	<div class="tree-node">
		<div @contextmenu.prevent="showContextMenu(node)" class="tree-node-label" :class="{ 'active': selectedNode !== null && node.uuid === selectedNode.uuid }" @click.stop="selectNode(node)">
			<b-icon @click.stop="expanded = !expanded" :icon="node.children && node.children.length > 0 ? (expanded ? 'folder-minus' : 'folder-plus') : 'folder'"  aria-hidden="true"></b-icon> {{ node.name }} <span v-if="treetype === 'media'">{{ imageCache[node.path] ? '(' + imageCache[node.path].length + ')' : '' }}</span>
		</div>
		<template v-if="expanded && node.children && Array.isArray(node.children)">
			<div v-for="child in node.children" :key="child.uuid" class="tree-node">
				<tree-node :selectedNode="selectedNode" :treetype="treetype" :imageCache="imageCache" :node="child"></tree-node>
			</div>
		</template>
	</div>
</template>

<script>
import EventBus from '../EventBus.js'
export default {
	name: 'TreeNode',
	data: () => ({
		expanded: false
	}),
	props: [
		'node',
		'imageCache',
		'selectedNode',
		'treetype'
	],
	components: {
	},
	created () {
	},
	methods: {
		showContextMenu(node) {
			EventBus.$emit('openNodeContextMenu', node)
			EventBus.$emit('nodeSelected', node)
		},
		selectNode(node) {
			EventBus.$emit('nodeSelected', node)
		}
	}
}
</script>

<style>
</style>
