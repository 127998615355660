<template>
  <div v-if="embeddings.length > 0" class="formeditor-view-embeddings">
    <div v-for="(embedding,index) in embeddings" :key="index">
      <b-alert show dismissible :variant="embedding.variant" :index="index" @dismissed="dismissEmbedding(index)">
        {{embedding.text}} <a :target="embedding.target || '_blank'" :href="embedding.url" class="alert-link">{{embedding.linktext}}</a>.
      </b-alert>
    </div>
  </div>
</template>

<script>
import EventBus from '../EventBus.js'

export default {
  name: 'view-embeddings',
  methods: {
    dismissEmbedding (index) {
      // We need the type so we can splice the corresponding array. currently top level embeddings and list embeddings are supported
      // the embeddings are kept in a separate variable. The array of the corresponding variable is spliced based on the type
      EventBus.$emit('dismissEmbedding', index, this.type) // Event handler defined in App.vue
    }
  },
  props: [
    'embeddings',
    'type'
  ],
  computed: {
  }
}
</script>

<style>
  .formeditor-view--embeddings {
    margin-top: 10px;
    background-color: white;
    font-size: 12px;
    color: #333;
  }
</style>
