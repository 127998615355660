<template>
  <b-navbar toggleable="lg" type="light" style="border-bottom: 4px solid #3da5d9 !important">
    <b-navbar-brand href="#"><template v-if="mode != 'preview'"><img alt="Vue logo" src="../assets/at6p.svg" class="img-fluid" width="220"/><small style="font-size: 50%">v{{version}}</small></template><template v-else-if="mode === 'media'">Medienbibliothek</template></b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <template v-if="mode === 'editor'">
        <b-nav-item-dropdown text="Datei" right>
          <b-dropdown-item href="#" @click.prevent="navBarEvent('newProject')">Neu</b-dropdown-item>
          <b-dropdown-item href="#" @click.prevent="navBarEvent('save')">Speichern</b-dropdown-item>
          <!--<b-dropdown-item href="#" @click.prevent="navBarEvent('saveAs')">Speichern unter...</b-dropdown-item>-->
          <b-dropdown-item href="#" @click.prevent="navBarEvent('load')">Laden</b-dropdown-item>
          <b-dropdown-item :href="downloadProject()" :download="project.id + '.json'">Download</b-dropdown-item>
          <b-dropdown-item href="#" @click.prevent="navBarEvent('import')">Importieren</b-dropdown-item>
          <!--<b-dropdown-item href="#" @click.prevent="navBarEvent('deploy')">Deploy</b-dropdown-item>-->
        </b-nav-item-dropdown>
        <b-nav-item-dropdown text="Bearbeiten" right>
          <b-dropdown-item href="#" @click.prevent="navBarEvent('openToolSettings')">Einstellungen</b-dropdown-item>
        </b-nav-item-dropdown>
        </template>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
		<b-nav-form>
			<b-button id="show-connection-status" v-b-tooltip.hover :title="connected ? 'Verbunden, Klick für Systeminformationen' : 'Nicht verbunden'" size="sm" :variant="connected ? 'success' : 'danger'"><b-icon scale="1.0" :icon="connected ? 'plug-fill' : 'plug'" aria-hidden="true"></b-icon></b-button>
			<b-popover v-if="connectionStatus.length > 0" target="show-connection-status" title="Verbindungsstatus / Systeminformationen" triggers="click">
			<b-table
				thead-class="d-none"
				striped
				bordered
				:items="connectionStatus"
				:fields="[{ key: 'prop', label: 'Eigenschaft' },{ key: 'propval', label: 'Wert' } ]">
				<!-- Custom formatted prop column -->
				<template #cell(prop)="data">
					<b class="text-dark">{{ data.value }}</b>
				</template>
				<template #cell(propval)="data">
					<span class="text-dark">{{ data.value }}</span>
				</template>
			</b-table>
			</b-popover>
			<b-button @click.prevent="doLogout()" id="logout" v-b-tooltip.hover title="Abmelden" size="sm" variant="danger" class="ml-1 mr-2"><b-icon  icon="unlock-fill" aria-hidden="true"></b-icon></b-button>
			<small style="line-height: 1.2">
			<b>Angemeldet als:</b><br/>
			<i>{{$store.getters.getUser.username}}</i></small>
		</b-nav-form>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import EventBus from '../EventBus.js'

export default {
	name: 'Navigation',
	data: () => ({
	}),
	mounted () {
	},
	props: [
		'version',
		'project',
		'connected',
		'connectionStatus',
		'mode'
	],
	components: {
	},
	created () {
	},
	methods: {
		downloadProject () {
			//console.log(JSON.stringify(this.project));
			return 'data:application/json;charset=UTF-8,' + encodeURIComponent(JSON.stringify(this.project))
		},
		navBarEvent (ev) {
			EventBus.$emit('navbarEvent', ev)
		},
		switchLanguage (lang) {
			EventBus.$emit('switchLanguage', lang)
		},	
		doLogout: function() {
			this.navBarEvent('logout')
		}
	}
}
</script>

<style>
  div.popover {
    max-width: initial !important;
  }
</style>
