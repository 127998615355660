<template>
	<section class="vh-100">
		<div class="container py-5 h-100">
			<div class="row d-flex justify-content-center align-items-center h-100">
				<div class="col col-xl-10">
					<div class="card" style="border-radius: 1rem;">
						<div class="row g-0">
							<div class="col-md-6 col-lg-5 d-none d-md-block align-middle" style="padding: 15px !important; background-color: #000000; border-radius: 1rem 0rem 0rem 1rem;">
								<img src="../assets/at3n.svg" alt="login form" class="img-fluid mt-5" />
							</div>
							<div class="col-md-6 col-lg-7 d-flex">
								<div class="card-body p-4 p-lg-5 text-black">
									<form>
										<div class="d-flex mb-3 pb-1 justify-content-center">
											<img src="../assets/at6p.svg" alt="login form" width="200" class="img-fluid" style="border-radius: 1rem 0 0 1rem;"/>
										</div>

										<h5 class="fw-normal mb-3 pb-3 font-weight-bold" style="letter-spacing: 1px;">Anmeldung</h5>
										<div class="form-outline mb-4 justify-content-left">
											<label for="username">Benutzername</label>
											<b-form-input type="text" autocomplete="off" v-model="username" :state="valid" id="username" class="form-control form-control-lg"></b-form-input>
											<b-form-invalid-feedback>Benutzername ist erforderlich!</b-form-invalid-feedback>
										</div>

										<div class="form-outline mb-4 justify-content-left">
											<label for="password">Passwort</label>
											<b-form-input type="password" autocomplete="off" v-model="password" :state="valid" id="password" class="form-control form-control-lg"></b-form-input>
											<b-form-invalid-feedback>Passwort ist erforderlich!</b-form-invalid-feedback>
										</div>

										<div class="pt-1 mb-4">
											<button @click.prevent="doLogin()" class="btn btn-primary btn-lg btn-block" type="button">Login</button>
										</div>
									</form>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import EventBus from '../EventBus.js'
export default {
	name: 'Login',
	data: () => ({
		username: null,
		password: null,
		incomplete: false
		
	}),
	props: [
	],
	components: {
	},
	created () {
	},
	computed: {
		valid() {
			return this.validate()
		}
	},
	methods: {
		validate() {
			if(!this.username  || !this.password) {
				if(this.incomplete) {
					return false;
				} else {
					return null;
				}
			} else if(this.username && this.username.length > 0 && this.password && this.password.length > 0) {
				return true;
			}
		},
		dispatchLogin: function(token, username) {
			const user = { username: username }
			this.$store.dispatch('login', { token, user })
			this.$router.push('/')
		},
		dispatchLogout: function() {
			this.$store.dispatch('logout', {})
		},
		doLogin: function() {
			if(this.validate()) {
				let requestOptions = {
					method: "POST",
					headers: { "Content-Type": "application/json" },
					body: JSON.stringify({action: "login", payload: { user: this.username, password: this.password }})
				};
				//console.log("isLoggedIn: " + this.$store.getters.isLoggedIn)
				if(this.$store.getters.isLoggedIn) {
					requestOptions.headers["Authorization"] = "Bearer " + this.$store.getters.getToken
				}
				fetch("/api/",requestOptions).then(response => response.json()).then(resp => { 
					if(resp.SUCCESS == true) {
						this.dispatchLogin(resp.DATA.token,resp.DATA.user)
					} else {
						let variant = 'danger'
						let toastMsg = { caption: 'Anmeldung fehlgeschlagen!', msg: 'Anmeldung fehlgeschlagen!', msgDetail: resp.MESSAGE, append: false, variant: variant, solid: true }
						EventBus.$emit('makeToast', toastMsg)
						this.dispatchLogout()
					}
				})
			} else { 
				this.incomplete = true
			}
		}
	}
}
</script>

<style>
</style>
