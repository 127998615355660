<template>
  <div>
  <template v-if="mode === 'editor'">
  <b-button :variant="showFormExplorer ? 'primary' : 'light'" size="sm" class="mr-2" @click.prevent="toggleModuleExplorer()" v-b-tooltip.hover :title="showFormExplorer ? 'Modul-Explorer ausblenden' : 'Formular-Explorer einblenden'">
    <b-icon icon="layout-sidebar-inset" aria-hidden="true"></b-icon>
  </b-button>
  <!--
  <b-button :variant="showObjectIDs ? 'primary' : 'light'" size="sm" class="mr-2" @click.prevent="toggleObjectIDs()" v-b-tooltip.hover :title="showObjectIDs ? 'Objekt-IDs ausblenden' : 'Objekt-IDs einblenden'">
    <b-icon icon="badge-ad" aria-hidden="true"></b-icon>
  </b-button>
  -->
  <!--
  <b-button variant="light" size="sm" class="mr-2" @click.prevent="showHelp()" v-b-tooltip.hover title="Hilfe">
    <b-icon icon="question-circle" aria-hidden="true"></b-icon>
  </b-button>
  -->
  <!--
  <b-button variant="light" size="sm" class="mr-2" @click.prevent="deploy()" v-b-tooltip.hover title="Deploy">
    <b-icon icon="box" aria-hidden="true"></b-icon>
  </b-button>
  -->
  <b-button variant="light" size="sm" class="mr-2" @click.prevent="openProjectSettings()" v-b-tooltip.hover title="Projekteigenschaften">
    <b-icon icon="gear" aria-hidden="true"></b-icon>
  </b-button>
  </template>
  <template  v-if="mode == 'editor' || mode == 'media'">
  <b-button :disabled="!connected" :variant="mode == 'media' ? 'primary' : 'light'" size="sm" class="mr-2" @click.prevent="showMediaLibrary()" v-b-tooltip.hover :title="mode === 'media' ? 'Mediengallerie beenden' : 'Mediengalerie öffnen'">
    <b-icon icon="images" aria-hidden="true"></b-icon>
  </b-button>
  </template>
  <!--
  <template  v-if="mode != 'media' && mode != 'apiexplorer'">
  <b-button :variant="mode == 'preview' ? 'primary' : 'light'" size="sm" class="mr-2" @click.prevent="preview()" v-b-tooltip.hover :title="mode === 'preview' ? 'Vorschau beenden' : 'Vorschau starten'">
    <b-icon icon="eye" aria-hidden="true"></b-icon>
  </b-button>
  </template>
  -->
  <template  v-if="mode == 'editor' || mode == 'apiexplorer'">
  <b-button :variant="mode == 'apiexplorer' ? 'primary' : 'light'" size="sm" class="mr-2" @click.prevent="apiexplorer()" v-b-tooltip.hover :title="mode == 'apiexplorer' ? 'API-Explorer beenden' : 'API-Explorer starten'">
    <b-icon icon="code" aria-hidden="true"></b-icon>
  </b-button>
  </template>
  </div>
</template>

<script>
import EventBus from '../EventBus.js'
export default {
  name: 'QuickActions',
  data: () => ({
  }),
  props: [
    'showHelpTexts',
    'showRefLines',
    'showFormExplorer',
    'showObjectIDs',
    'mode',
    'connected'
  ],
  components: {
  },
  created () {
  },
  methods: {
    deploy () {
      EventBus.$emit('quickActionEvent', 'deploy')
    },
	apiexplorer () {
      EventBus.$emit('quickActionEvent', 'apiexplorer')
    },
    preview () {
      EventBus.$emit('quickActionEvent', 'preview')
    },
    showHelp () {
      EventBus.$emit('quickActionEvent', 'showHelp')
    },
    toggleHelpTexts () {
      EventBus.$emit('quickActionEvent', 'toggleHelpTexts')
    },
    toggleRefLines () {
      EventBus.$emit('quickActionEvent', 'toggleRefLines')
    },
    toggleModuleExplorer () {
      EventBus.$emit('quickActionEvent', 'toggleModuleExplorer')
    },
    openProjectSettings () {
      EventBus.$emit('quickActionEvent', 'openProjectSettings')
    },
    toggleObjectIDs () {
      EventBus.$emit('quickActionEvent', 'toggleObjectIDs')
    },
    showMediaLibrary () {
      EventBus.$emit('quickActionEvent', 'showMediaLibrary')
    }
  }
}
</script>

<style>
</style>
