<template>
	<div v-if="editor">
		<template v-if="editable">
			<input class="mr-1" type="color" @input="editor.chain().focus().setColor($event.target.value).run()" :value="editor.getAttributes('textStyle').color">
			<b-button size="sm" @click="editor.chain().focus().toggleBold().run()" class="mr-1" :class="{ 'is-active': editor.isActive('bold') }">
				<font-awesome-icon icon="bold" aria-hidden="true" />
			</b-button>
			<b-button size="sm" @click="editor.chain().focus().toggleItalic().run()" class="mr-1" :class="{ 'is-active': editor.isActive('italic') }">
				<font-awesome-icon icon="italic" aria-hidden="true" />
			</b-button>
			<b-button size="sm" @click="editor.chain().focus().toggleUnderline().run()" class="mr-1" :class="{ 'is-active': editor.isActive('underline') }">
				<font-awesome-icon icon="underline" aria-hidden="true" />
			</b-button>
			<b-button size="sm" @click="editor.chain().focus().toggleBulletList().run()" class="mr-1" :class="{ 'is-active': editor.isActive('bulletList') }">
				<font-awesome-icon icon="list" aria-hidden="true" />
			</b-button>
			<b-button size="sm" @click="editor.chain().focus().toggleOrderedList().run()" class="mr-1" :class="{ 'is-active': editor.isActive('orderedList') }">
				<font-awesome-icon icon="list-ol" aria-hidden="true" />
			</b-button>
		</template>
		<editor-content :editor="editor" />
	</div>
</template>

<script>
import EventBus from '../EventBus.js'

import { Editor, EditorContent } from '@tiptap/vue-2'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'
import BulletList from '@tiptap/extension-bullet-list'
import Underline from '@tiptap/extension-underline'
import TextStyle from '@tiptap/extension-text-style'
import { Color } from '@tiptap/extension-color'

export default {
  components: {
    EditorContent,
  },

  props: [
	'targetObj',
    'value',
	'editable'
  ],

  data() {
    return {
		editor: null
    }
  },

  watch: {
	editable(val) {
		console.log('Changed editable to ' + val)
		//this.editor.setOptions({editable: false})
		this.editor.options.editable = val
	},
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
		return
      }

      this.editor.commands.setContent(value, false)
    },
  },

  mounted() {
    this.editor = new Editor({
		editable: this.editable,
		content: this.value,
		extensions: [
			Document,
			Paragraph,
			Text,
			Bold,
			Italic,
			OrderedList,
			ListItem,
			BulletList,
			Underline,
			TextStyle,
			Color
		],
		onUpdate: () => {
			//console.log("Updated HTML: " + this.editor.getHTML())
			// HTML
			//this.$emit('input', this.editor.getHTML())

			// JSON
			// this.$emit('input', this.editor.getJSON())
			
			EventBus.$emit('updateHTML', this.editor.getHTML(), this.targetObj)
		},
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>