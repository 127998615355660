import Vue from 'vue'
import App from './App.vue'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueDragDrop from 'vue-drag-drop'
// import BootstrapVueTreeview from 'bootstrap-vue-treeview'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
// import { faTwitter } from '@fortawesome/free-brands-svg-icons'
// import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
//import { faMapLocationDot } from '@fortawesome/free-solid-svg-icons'
//import { faCoffee } from '@fortawesome/free-solid-svg-icons'
/* import all solid icons */
import { fas } from '@fortawesome/free-solid-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* use single icons */
//library.add(faMapLocationDot, faCoffee)
/* use all icons */
library.add(fas)

import router from './router/router.js'
import store from './store/store.js'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueDragDrop)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Vue.use(BootstrapVueTreeview)

Vue.config.productionTip = false


// new Vue({
// render: h => h(App)
// }).$mount('#app')

/* eslint-disable no-unused-vars */
window.vue = new Vue({
  router,
  store,
  el: '#app',
  data: {},
  render (h) {
    return h(App, {
      props: {
      }
    })
  }
})
