<template>
	<div id="app">
		<!--<div id="nav">
			<router-link to="/">Main</router-link> |
			<router-link to="/Login">Login</router-link> |
			<router-link to="/about">About</router-link>
		</div>-->
		<router-view/>
	</div>
</template>

<script>
	import EventBus from './EventBus.js'
	
	export default {
		data() {
			return {
			};
		},
		methods: {
			makeToast (title = '', txt = '', append = false, variant = null, solid = true) {
				this.$bvToast.toast(txt, {
					title: title,
					variant: variant,
					autoHideDelay: 4000,
					appendToast: append,
					solid: solid
				})
			}
		},
		created () {
			EventBus.$on('makeToast', (data) => {
				// console.log('Entering event makeToast with data:' + JSON.stringify(data))
				// sample data: { caption: 'Ping fehlgeschlagen ' + statusCode, msg: 'Ping ist fehlgeschlagen. Der Server scheint nicht erreichbar zu sein oder es liegt ein anderes Problem vor!', msgDetail: responseText, append: false, variant: 'danger', solid: true }
				// this.makeToast('Deployment', 'Deploymen Projekt in die Lucom Interaction Platform!', false, 'success', true)
				this.makeToast(data.caption, data.msg, data.append, data.variant, data.solid)
			})
		}
	};
</script>
	
<style>
	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	
	.bg-novolytics, .btn-novolytics {
		background-color: #3da5d9 !important;
	}
	
	.btn-novolytics {
		color: #FFFFFF !important;
	}
</style>
