<template>
  <div v-if="moduleexplorerConfig" class="accordion walkwithme" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.modules variant="novolytics">Content-Module</b-button>
        </b-card-header>
        <b-collapse id="modules" visible accordion="module-explorer" role="tabpanel">
          <b-card-body>
            <b-card-text>
              <drag v-for="structel in moduleexplorerConfig.modules" :key="structel.id" :title="structel.title" @dragstart="handleDragstart" @dragend="handleDragend" class="drag" :transfer-data="{source: 'formexplorer', obj: structel}"><b-card no-body class="p-2 mb-2"><b-card-text>{{ structel.title }}</b-card-text></b-card></drag>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
      <!--b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle.formelements variant="info">Formularelemente</b-button>
        </b-card-header>
        <b-collapse id="formelements" accordion="form-explorer" role="tabpanel">
          <b-card-body>
            <b-card-text>
              <drag v-for="formel in moduleexplorerConfig.formelements" :key="formel.id" :title="formel.title" @dragstart="handleDragstart" @dragend="handleDragend" class="drag" :transfer-data="{source: 'formexplorer', obj: formel}"><b-card no-body class="p-2 mb-2"><b-card-text>{{ formel.title }}<b-badge pill variant="primary" class="float-right"><b-icon :icon="formel.icon" font-scale="1.5"></b-icon></b-badge></b-card-text></b-card></drag>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card-->
    </div>
</template>

<script>
import EventBus from '../EventBus.js'
export default {
  name: 'ModuleExplorer',
  data: () => ({
    dragObj: null
  }),
  props: [
    'moduleexplorerConfig'
  ],
  components: {
  },
  created () {
  },
  methods: {
    handleDragstart (data, event) {
      this.dragObj = data.obj.type
      event.stopPropagation()
      EventBus.$emit('notifyDragStart', this.dragObj)
    },
    handleDragend () {
      this.dragObj = null
    }
  }
}
</script>

<style>
.walkwithme {
	position: fixed;
    width: 23%;
}
</style>
