<template>
	<section class="vh-100" style="background-color: #EFEFEF">
		<div class="container py-5 h-100">
			<div class="row d-flex justify-content-center align-items-center h-100">
				<div class="col col-md-8 col-lg-6 col-xl-6">
					<div class="card" style="border-radius: 1rem; background-color: #FFFFFF !important; text-align: center">
						<div class="row g-0">
							<div class="col-md-12 col-lg-12 justify-content-center align-items-center">
								<b-icon style="width: 120px; height: 120px; color: #721c24;" icon="exclamation-triangle-fill" aria-hidden="true"></b-icon>
								<div class="alert alert-danger" role="alert">
									Die verwendete Domain ist nicht lizensiert!
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
//import EventBus from '../EventBus.js'
export default {
  name: 'InvalidDomain',
  data: () => ({
  }),
  props: [
  ],
  components: {
  },
  created () {
  },
  methods: {
  }
}
</script>

<style>
</style>
