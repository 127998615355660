import Vue from 'vue'
import Vuex from 'vuex'
//import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

const getDefaultState = () => {
  return {
    token: '',
    user: {}
  };
};
export default new Vuex.Store({
	strict: true,
	//plugins: [createPersistedState()],
	state: getDefaultState(),
	getters: {
		isLoggedIn: state => {
			return state.token != '' && typeof state.user != 'undefined' && typeof state.user.username != 'undefined';
		},
		getUser: state => {
			return state.user;
		},
		getToken: state => {
			return state.token;
		}
	},
	mutations: {
		setToken: (state, token) => {
			//console.log("Entering setToken...");
			state.token = token;
		},
		setUser: (state, user) => {
			//console.log("Entering setUser...");
			state.user = user;
		},
		reset: state => {
			Object.assign(state, getDefaultState());
		}
	},
	actions: {
		login: ({ commit }, { token, user }) => {
			//console.log("Entering login...");
			commit('setToken', token);
			commit('setUser', user);
			// set auth header
			//Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
		},
		logout: ({ commit }) => {
			commit('reset', '');
		}
	}
});