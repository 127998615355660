/* eslint-disable no-tabs */
/* eslint-disable indent */
/* eslint-disable no-mixed-spaces-and-tabs */
export default {
  getMediaLibrary () {
		return {
			tree: [
				{ uuid: 'f709a636-9383-4123-b313-dbc707ff25bc', root: true, type: 'folder', name: 'images', path: '/images', children: [ 
					{ uuid: 'f1aee630-43e7-47da-a45b-a68462c5afcc', type: 'folder', name: 'icons', path: '/images/icons', children: [], parent: 'f709a636-9383-4123-b313-dbc707ff25bc' },
					{ uuid: 'a76d8f96-80da-46c5-9401-e31205289e73', type: 'folder', name: 'business', path: '/images/business', children: [], parent: 'f709a636-9383-4123-b313-dbc707ff25bc' },
					{ uuid: 'c8066332-8e7d-46d2-80bd-ea545886ba51', type: 'folder', name: 'architecture', path: '/images/architecture', children: [], parent: 'f709a636-9383-4123-b313-dbc707ff25bc' }
				]}
			],
			images: {
				'/images/icons': [
					{ id: 'image10', uuid: 'image10', title: 'image10', src: '/media/10.jpg', type: 'media' },
					{ id: 'image11', uuid: 'image11', title: 'image11', src: '/media/11.jpg', type: 'media' },
					{ id: 'image12', uuid: 'image12', title: 'image12', src: '/media/12.jpg', type: 'media' },
					{ id: 'image13', uuid: 'image13', title: 'image13', src: '/media/13.jpg', type: 'media' },
					{ id: 'image14', uuid: 'image14', title: 'image14', src: '/media/14.jpg', type: 'media' },
					{ id: 'image15', uuid: 'image15', title: 'image15', src: '/media/15.jpg', type: 'media' },
					{ id: 'image16', uuid: 'image16', title: 'image16', src: '/media/16.jpg', type: 'media' },
					{ id: 'image17', uuid: 'image17', title: 'image17', src: '/media/17.jpg', type: 'media' },
					{ id: 'image18', uuid: 'image18', title: 'image18', src: '/media/18.jpg', type: 'media' },
					{ id: 'image19', uuid: 'image19', title: 'image19', src: '/media/19.jpg', type: 'media' }
				],
				'/images/business': [
					{ id: 'image20', uuid: 'image20', title: 'image20', src: '/media/20.jpg', type: 'media' },
					{ id: 'image21', uuid: 'image21', title: 'image21', src: '/media/21.jpg', type: 'media' },
					{ id: 'image22', uuid: 'image22', title: 'image22', src: '/media/22.jpg', type: 'media' },
					{ id: 'image23', uuid: 'image23', title: 'image23', src: '/media/23.jpg', type: 'media' },
					{ id: 'image24', uuid: 'image24', title: 'image24', src: '/media/24.jpg', type: 'media' },
					{ id: 'image25', uuid: 'image25', title: 'image25', src: '/media/25.jpg', type: 'media' }
				],
				'/images/architecture': [
					{ id: 'image26', uuid: 'image26', title: 'image26', src: '/media/26.jpg', type: 'media' },
					{ id: 'image27', uuid: 'image27', title: 'image27', src: '/media/27.jpg', type: 'media' },
					{ id: 'image28', uuid: 'image28', title: 'image28', src: '/media/28.jpg', type: 'media' },
					{ id: 'image29', uuid: 'image29', title: 'image29', src: '/media/29.jpg', type: 'media' },
					{ id: 'image30', uuid: 'image30', title: 'image30', src: '/media/30.jpg', type: 'media' },
					{ id: 'image31', uuid: 'image31', title: 'image31', src: '/media/31.jpg', type: 'media' },
					{ id: 'image32', uuid: 'image32', title: 'image32', src: '/media/32.jpg', type: 'media' }
				]
			}
		}
	}
}
